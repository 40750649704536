

<template>
    <div @click.self="closeScanner" class="h-screen w-screen fixed bg-black bg-opacity-50 z-50 inset-0">
        <div class="w-full h-full md:w-8/12 h-fit md:my-28 md:p-4 bg-white mx-auto my-auto ">
            <div class="h-full w-full">
                <div id="qr-code-full-region"></div>
            </div>
            <div class="w-full flex justify-center items-center py-4 md:pb-0">
                <button @click="closeScanner" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">Close</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import {Html5QrcodeScanner} from "html5-qrcode";
export default {
    emits:['barcodeUpdate'],
    data() {
        return {
            html5QrcodeScanner:null
        }
    },
    methods: {
        closeScanner() {
            this.$emit('barcodeUpdate','');
            this.html5QrcodeScanner.clear();
        },
        _onScanSuccess(qrCodeMessage) {
            console.log("*****",qrCodeMessage,"*****");
            this.$emit('barcodeUpdate',qrCodeMessage);
            this.html5QrcodeScanner.clear();
        },
        _onScanFailure(error) {
            console.log("_onScanFailure");
            console.log(error);
            //this.html5QrcodeScanner.clear();
        }
    },
    mounted: function() {
        let cw = document.getElementById("qr-code-full-region").offsetWidth; // Container Width
        let readerWidth = cw/3;
        let readerHeight = readerWidth*(2/3);
        var config = { fps: 10, qrbox: {width:readerWidth,height:readerHeight} };

        this.html5QrcodeScanner = new Html5QrcodeScanner("qr-code-full-region", config);
        this.html5QrcodeScanner.render(this._onScanSuccess);
    },
    unmounted: function() {
        this.html5QrcodeScanner.clear();
    }
}
</script>

<style>
.html5-qrcode-element[type="button"] {
    padding:2px 12px;
    color:#ffffff;border-radius: 20px;
}
#html5-qrcode-button-camera-stop {
    background-color:rgb(239 68 68);
}
#html5-qrcode-button-camera-start {
    background-color:rgb(55 48 163);
}
#html5-qrcode-button-camera-permission {
    background-color:rgb(55 48 163);
}
</style>