<template>
    <section class="max-w-screen-2xl mx-auto px-4">
        <!-- NAVIGATE BACK -->
        <div class="py-2">
            <div @click="navigateBack" class="px-2 w-fit flex items-center gap-1 select-none cursor-pointer text-bestbuy-800 dark:text-bestbuy-700">
                <font-awesome-icon class="text-xs" icon="fa-chevron-left" />
                <div class="text-sm font-medium">Back</div>
            </div>
        </div>

        <div class="py-4 pt-2 flex justify-between items-center ">
            <PageTitle :title="'Reservation Details'" @click="print(reservation)"></PageTitle>
            <button @click="editReservation" v-if="formState == 'view' " class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
                <font-awesome-icon class="mr-2" icon="fa-pen-to-square" />
                <span class="hidden sm:inline">Edit Reservation</span>
            </button>
            <button @click="editReservation" v-if="formState == 'view' " class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
                <font-awesome-icon class="text-black text-2xl absolute" icon="fa-pen-to-square" />
            </button>
        </div>
        <!-- HR -->
        <div class="border-b-2 border-gray-300 dark:border-gray-800"></div>
        <!-- DETAILS -->
        <div class="w-full mx-auto my-6">
            <div @click="toggleSection('details')" class="w-full border-b mb-2 cursor-pointer dark:border-gray-700">
                <SectionHeader :title="'Details'">
                    <template v-slot:icon>
                        <font-awesome-icon v-show="ui.sections.details.expanded" class="text-xs w-4" icon="fa-chevron-down" />
                        <font-awesome-icon v-show="!ui.sections.details.expanded" class="text-xs w-4" icon="fa-chevron-right" />
                    </template>
                    <template v-slot:right>
                        <div @click.stop="showVersionHistory" class="flex items-center cursor-pointer text-bestbuy-800 dark:text-bestbuy-700 hover:bg-gray-100 rounded py-1 px-2 transition duration-200 ease-in-out select-none">
                            <font-awesome-icon class="text-xs w-4" icon="fa-info-circle" />
                            <span class="mx-1 text-xs">Version History</span>
                        </div>
                    </template>
                </SectionHeader>
            </div>
            <div v-if="reservation && reservation != null" :class="ui.sections.details.expanded ? 'max-h-[999999px]' : 'max-h-0' " class="overflow-hidden px-4 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4">
                <!-- OWNER -->
                <div class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Owner</label>
                        <div class="mx-1 py-2 flex items-center overflow-hidden">
                            <div class="w-20 h-20 flex-shrink-0 mr-2 sm:mr-3">
                                <div :style="`background-image:url('${ reservation.owner.profileImg ? reservation.owner.profileImg : store.user.defaultUserImg }')`" name="userprofileimage" class="h-20 w-20 rounded-full bg-center bg-cover"></div>
                            </div>
                            <div class="text-gray-800 truncate dark:text-gray-100">{{ reservation.owner.name }}</div>
                        </div>
                    </div>
                </div>

                <!-- STATUS -->
                <div class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Status</label>
                        <ReservationStatusBadge :status="reservation.status"></ReservationStatusBadge>
                        <div class="h-1"></div>
                        <div v-if="reservation.reservationStarted && reservation.status !== 'not started'" class="text-gray-400 text-xs italic">
                            Started on {{ formatDate(reservation.reservationStarted.seconds * 1000,'MMMM D, YYYY') }}
                        </div>
                        <div v-if="reservation.reservationEnded && reservation.reservationStarted && reservation.status == 'complete'" class="text-gray-400 text-xs italic">
                            Ended on {{ formatDate(reservation.reservationEnded.seconds * 1000,'MMMM D, YYYY') }}
                        </div>
                        <div v-if="formState == 'view'" class="my-4">
                            <div>
                                <button v-if="reservation.status == '' || reservation.status == 'not started'" @click="updateReservationStatus('start')" class="inline-flex flex gap-2 items-center justify-center rounded-md border border-green-600 bg-green-600 px-2 py-1 font-medium text-white text-sm shadow-sm hover:bg-green-700 focus:outline-none ml-3 w-auto transition duration-200">
                                    <font-awesome-icon class="text-xs w-4" icon="fa-play" />
                                    <span>Start Checkout</span>
                                </button>
                            </div>
                            <div>
                                <button v-if="reservation.status == 'active'" @click="updateReservationStatus('stop')" class="inline-flex flex gap-2 items-center justify-center rounded-md border border-red-600 bg-red-600 px-2 py-1 font-medium text-white text-sm shadow-sm hover:bg-red-700 focus:outline-none ml-3 w-auto transition duration-200">
                                    <font-awesome-icon class="text-xs w-4" icon="fa-stop" />
                                    <span>End Checkout</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CREATED DATE -->
                <!--
                <div class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900">Created</label>
                        <div class="leading-8 mx-1 text-sm text-black flex flex-col h-full justify-center">{{ formatDate(reservation.createdDate.seconds * 1000,'MMMM D, YYYY') }}</div>
                    </div>
                </div>
                -->

                <!-- PROJECT NAME -->
                <div class="w-full" :class=" (formState == 'edit') ? 'md:col-span-2' : '' ">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Project Name</label>
                        <div v-if="formState == 'view'" class="leading-8 mx-1 text-black flex flex-col h-full justify-center dark:text-gray-100">{{ reservation.projectName }}</div>
                        <v-select v-else v-model="projects.selected" :options="store.projects.items" label="_projectIdName" class="" ></v-select>
                    </div>
                </div>

                <!-- PROJECT CODE -->
                <div class="w-full" v-if="formState == 'view'">
                    <div class="flex flex-col" >
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Project Code</label>
                        <div class="leading-8 mx-1 text-black flex flex-col h-full justify-center dark:text-gray-100">{{ reservation.project }}</div>
                    </div>
                </div>

                <!-- RESERVATION START -->
                <div class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Reservation Start</label>
                        <div v-if="formState == 'view'" class="leading-8 mx-1 text-black flex flex-col h-full justify-center dark:text-gray-100">{{ formatDate(reservation.start, 'M/DD/YYYY') }}</div>
                        <div v-else>
                            <input type="date" v-model="reservationInstance._fpstart" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <!-- <flat-pickr v-model="reservationInstance._fpstart" :config="flatpicker.startDate.config" placeholder="Start date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>
                </div>

                <!-- RESERVATION END -->
                <div class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-900 dark:text-gray-400">Reservation End</label>
                        <div v-if="formState == 'view'" class="leading-8 mx-1 text-black flex flex-col h-full justify-center dark:text-gray-100">{{ formatDate(reservation.end, 'M/DD/YYYY') }}</div>
                        <div v-else>
                            <input type="date" v-model="reservationInstance._fpend" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <!-- <flat-pickr v-model="reservationInstance._fpend" :config="flatpicker.endDate.config" placeholder="End date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- EQUIPMENT -->
        <div class="w-full mx-auto my-6">
            <div class="w-full col-span-1 md:col-span-2 mt-4">
                <div @click="toggleSection('gear')" class="w-full border-b mb-2 flex items-center gap-2 cursor-pointer dark:border-gray-700">

                    <SectionHeader :title="'Reserved Gear'">
                        <template v-slot:icon>
                            <font-awesome-icon v-show="ui.sections.gear.expanded" class="text-xs w-4" icon="fa-chevron-down" />
                            <font-awesome-icon v-show="!ui.sections.gear.expanded" class="text-xs w-4" icon="fa-chevron-right" />
                        </template>
                        <template v-slot:subtitle>
                            <span>{{ '(' + reservationEquipment.length + ( (reservationEquipment.length > 1 || reservationEquipment.length === 0) ? ' items)' : ' item)') }}</span>
                        </template>
                    </SectionHeader>


                </div>
                <div :class="ui.sections.gear.expanded ? 'max-h-[999999px]' : 'max-h-0' " class="px-4 flex flex-col">
                    <div v-if="formState == 'edit'" class="v-hide-selected">
                        <!-- <div v-if="ui.sections.gear.expanded" class="flex justify-end py-1">
                            <font-awesome-icon @click="toggleBarcodeScanner" class="text-bestbuy-800 text-lg cursor-pointer" icon="fa-camera" />
                        </div> -->
                        <!-- <v-select v-if="ui.sections.gear.expanded" v-model="equipment.selected" @update:modelValue="gearSelectUpdate" @keyup="gearSearchChange($event)" :item-text="'barcode'" :options="store.equipment.items" :selectable="(gear) => (isGearAvailable(gear) && !(isGearSelected(gear)) && isGearStatusAvailable(gear.status) )" label="name" placeholder="Search and add equipment" multiple class=""> -->
                        <!-- <QRScanner v-if="ui.showBarcodeScanner && ui.sections.gear.expanded" @barcodeUpdate="(bc) => barcodeScanUpdate(bc)"></QRScanner> -->
                        <v-select v-if="ui.sections.gear.expanded" v-model="equipment.selected" @update:modelValue="gearSelectUpdate" :filter="gearSearch" :options="filteredEquipment" :selectable="(gear) => (isGearAvailable(gear) && !(isGearSelected(gear)) && isGearStatusAvailable(gear.status) )" label="name" placeholder="Search and add equipment" multiple class="" id="gear-select">
                            <template v-slot:option="option">
                                <div v-if="isGearSelected(option)" class="w-full truncate font-semibold">{{ option.name }}<span class="text-xs italic"> (Selected)</span></div>
                                <div v-else-if="!isGearAvailable(option)" class="w-full truncate"><s>{{ option.name }}</s><span class="font-bold text-xs italic text-red-500"> Unavailable</span></div>
                                <div v-else-if="!isGearStatusAvailable(option.status)" class="w-full truncate"><s>{{ option.name }}</s><span class="px-2 font-bold text-xs italic text-red-500"> {{ option.status }} </span></div>
                                <!-- <div v-else class="w-full truncate">{{ option.name }}</div> -->
                                <div v-else class="flex justify-between w-full">
                                    <div class="truncate">
                                        {{ option.name }}
                                    </div>
                                    <div class="truncate">{{ option.barcode }}</div>
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="overflow-hidden mx-1 my-2 text-black flex flex-col h-full justify-center">
                        <div v-if="formState == 'view'">
                            <div v-for="gear in reservationEquipment" :key="gear._fsid" @click="selectGear(gear)" class="border-b dark:border-gray-800">
                                <div :class=" gear['_conflict'] ? 'border-l-red-300 bg-red-100  hover:bg-red-100' : 'border-l-slate-300 hover:bg-gray-100' " class="py-2 border-l-4 cursor-pointer flex items-center transition duration-200 ease-in-out select-none overflow-hidden">
                                    <div class="flex items-center gap-2">
                                        <div v-if="gear.images && gear.images.length>0" :style="`background-image:url('${ gear.images[0].url }')`" class="mx-2 w-8 h-8 bg-center bg-no-repeat bg-cover rounded"></div>
                                        <!-- <div class="select-none">{{ gear.name }}</div> -->
                                    </div>
                                    <div class="flex flex-col min-w-0">
                                        <div class="select-none truncate dark:text-gray-100">{{ gear.name }}</div>
                                        <div v-if="isGearStatusAvailable(gear.status)" v-show="gear['baseLocation']" class="pl-2 text-xs flex items-center gap-1 select-none text-bestbuy-500 truncate font-light">
                                            <font-awesome-icon icon="fa-location-dot" aria-hidden="true" class="" />
                                            <div>{{ gear['baseLocation'] }}</div>
                                        </div>
                                        <EquipmentStatusBadge v-if="!isGearStatusAvailable(gear.status)" :status="gear.status"></EquipmentStatusBadge>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="formState == 'edit'">
                            <div v-for="gear in equipment.selected" :key="gear._fsid" class="border-b">
                                <div :class=" gear['_conflict'] ? 'border-l-red-300 bg-red-100  hover:bg-red-100' : 'border-l-slate-300 hover:bg-gray-100' " class="py-2 border-l-4 cursor-pointer flex justify-between items-center">
                                    <div class="flex items-center gap-2">
                                        <div v-if="gear.images && gear.images.length>0" :style="`background-image:url('${ gear.images[0].url }')`" class="mx-2 w-8 h-8 bg-center bg-no-repeat bg-cover rounded"></div>
                                        <div>
                                            <div class="select-none dark:text-gray-100">{{ gear.name }}</div>
                                            <EquipmentStatusBadge v-if="!isGearStatusAvailable(gear.status)" :status="gear.status"></EquipmentStatusBadge>
                                        </div>
                                    </div>
                                    <div @click="removeGearItem(gear)" class="px-2 py-2 rounded w-fit flex items-center gap-1 select-none text-white bg-red-400">
                                        <font-awesome-icon icon="fa-x" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- RESERVATION COMMENTS -->
        <div class="w-full mx-auto my-6" v-if="formState == 'view'">
            <div class="w-full col-span-1 md:col-span-2 mt-4">
                <div @click="toggleSection('notes')" class="w-full border-b mb-2 flex items-center gap-2 cursor-pointer dark:border-gray-700">

                    <SectionHeader :title="'Comments'">
                        <template v-slot:icon>
                            <font-awesome-icon v-show="ui.sections.notes.expanded" class="text-xs w-4" icon="fa-chevron-down" />
                            <font-awesome-icon v-show="!ui.sections.notes.expanded" class="text-xs w-4" icon="fa-chevron-right" />
                        </template>
                        <template v-slot:subtitle>
                            <span>{{ '(' + store.reservations.notes.length + ( store.reservations.notes.length > 1 ? ')' : ')') }}</span>
                        </template>
                    </SectionHeader>

                </div>
                <div :class="ui.sections.notes.expanded ? 'max-h-[999999px]' : 'max-h-0' " class=" px-4 flex flex-col gap-4">
                    <!-- NOTE INPUT -->
                    <CommentBox @submit="(msg) => submitNote(msg)" :user="store.user"></CommentBox>
                    <!-- USER NOTES -->
                    <div class="flex flex-col gap-4">
                        <div v-for="note in notesWithLikes.filter(item => item.parent == '')" :key="note._fsid">
                            <UserNote :note="note" :user="store.user" @like="note => likeNote(note)" @reply="(note,reply) => replyNote(note,reply)" @delete="note => deleteNote(note)"></UserNote>
                            <div v-for="reply in notesWithLikes.filter(item => item.parent == note._fsid)" :key="reply._fsid" class="ml-16 mt-4">
                                <UserNote :note="reply" :user="store.user" @like="note => likeNote(note)" @delete="note => deleteNote(note)"></UserNote>
                            </div>
                        </div>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- VERSIONING INFO -->
        <Modal v-if="versions.show" @close="hideVersionHistory">
            <template v-slot:header>
                Reservation Version History
            </template>
            <template v-slot:body>
                <div>
                    <div v-for="(change,index) in versions.changes" :key="change.id" class="">
                        <div v-if="index > 0" class="text-center text-gray-300 py-4">
                            <font-awesome-icon icon="fa-arrow-up-long" />
                        </div>
                        <div class="flex justify-between text-sm border-b border-gray-200 pb-1">
                            <div class="flex gap-2 items-center">
                                <div :style="`background-image:url('${ change.editor.profileImg ? change.editor.profileImg : store.user.defaultUserImg }')`" class="h-6 w-6 rounded-full bg-center bg-cover"></div>
                                <div class="font-medium">{{ change.editor.name }}</div>
                            </div>
                            <div>{{ change.date }}</div>
                        </div>
                        <div class="pl-4 pt-2">
                            <div class="pl-2 border-l-4 border-bestbuy-200">
                                <div v-for="update in change.updates" :key="update.field" class="text-sm flex gap-2">
                                    <div class="font-medium whitespace-nowrap">{{ update.field }}: </div>
                                    <div v-html="update.value"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="hideVersionHistory" type="button" class="mx-auto inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none w-auto text-sm transition duration-200">Close</button>
            </template>
        </Modal>

        <!-- PAGE FOOTER -->
        <div v-if="formState == 'edit' " class="px-6 py-3 flex justify-center items-center mt-8 border-t-2">
            <button @click="deleteReservation" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto text-sm transition duration-200">Delete</button>
            <button @click="saveEditReservation" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200 disabled:opacity-50">Save</button>
            <button @click="cancelEditReservation" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200 ">Cancel</button>
        </div>


    </section>

</template>

<style scoped>
    .breadcrumb-item:not(:first-child):before {
        border-bottom: 4px solid #0000;
        border-left: 4px solid #000;
        border-top: 4px solid #0000;
        content: " ";
        display: inline-block;
        margin: 0 8px;
    }
</style>

<script>
    import {
        store
    } from '../store/store.js';
    import FullCalendar from '@fullcalendar/vue3';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import vSelect from "vue-select";
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import { Reservation, ReservationDoc } from '../utilities/utilities.js';
    import moment from 'moment';
    import UserNote from '../components/UserNote.vue';
    import CommentBox from '../components/CommentBox.vue';
    import Modal from '../components/Modal.vue';
    import ReservationStatusBadge from '../components/ReservationStatusBadge.vue';
    import PageTitle from '../components/PageTitle.vue';
    import SectionHeader from '../components/SectionHeader.vue';
    import { equipmentStatus } from '../store/store.js';
    import EquipmentStatusBadge from '../components/EquipmentStatusBadge.vue';

    export default {
        components: {
            FullCalendar,
            flatPickr,
            vSelect,
            UserNote,
            CommentBox,
            Modal,
            ReservationStatusBadge,
            PageTitle,
            SectionHeader,
            EquipmentStatusBadge
        },
        data() {
            return {
                store: store,
                formState: "view",
                reservationInstance: null,
                unavailableStatus: equipmentStatus.unvailableStatus,
                projects: {
                    selected:null
                },
                equipment: {
                    selected:[]
                },
                flatpicker: {
                    startDate: {
                        config: {
                            enableTime: true,
                            //minDate: new Date(),
                            dateFormat: "m/d/Y h:i K",
                            defaultHour: 0,
                            defaultMinute: 0,
                            onChange: (selectedDates, dateStr, instance) => {}
                        }
                    },
                    endDate: {
                        config: {
                            enableTime: true,
                            dateFormat: "m/d/Y h:i K",
                            //minDate: new Date(),
                            onChange: (selectedDates, dateStr, instance) => {}
                        }
                    }
                },
                ui: {
                    sections:{
                        details:{
                            expanded: true
                        },
                        gear:{
                            expanded: true
                        },
                        notes:{
                            expanded: true
                        }
                    }
                },
                versions: {
                    items: [],
                    show: false,
                    changes: []
                }
            }
        },
        methods: {
            print(val) {
                console.log(val);
            },
            selectGear(gear) {
                this.$router.push({
                    name:"equipmentdetails",
                    params: {
                        id:gear._fsid
                    }
                })
            },
            navigateBack() {
                this.$router.back()
            },
            editReservation() {
                this.reservationInstance = new Reservation(this.reservation);
                store.projects.getProjects(false).then(projects => {
                    this.projects.selected = store.projects.items.find(project => project._fsid == this.reservationInstance.projectId)
                    console.log(this.reservationInstance)
                });
                
                this.equipment.selected = store.equipment.items.filter(gear => {
                    return this.reservationInstance.equipment.includes(gear._fsid);
                })
                console.log("selected",this.equipment.selected)

                this.formState = "edit";
            },
            cancelEditReservation() {
                this.equipment.selected = [];
                this.formState = "view";
            },
            async saveEditReservation() {
                // Log Event
                store.analytics.logAppEvent({
                    uid: store.user.user.uid,
                    name: store.user.user.displayName,
                    email: store.user.user.email,
                    action: "Edit",
                    type: "Reservation",
                    target: this.reservation._fsid,
                    url: window.location.href,
                    datetime: new Date()
                });

                let event = {
                    editor:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    equipment: this.equipment.selected.map(_ => _._fsid),
                    modifiedDate: new Date(),
                    project: this.projects.selected.projectnumber,
                    projectName: this.projects.selected.projecttitle,
                    projectId: this.projects.selected._fsid,
                    reservationStart: new Date(this.reservationInstance._fpstart + "T00:00:00.000"),
                    reservationEnd: new Date(this.reservationInstance._fpend + "T00:00:00.000"),
                    status: this.reservationInstance.status
                }

                // Update owner's image
                if (this.reservationInstance.owner.id == store.user.user.uid) {
                    event['owner'] = {
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    }
                }

                let version = JSON.parse(JSON.stringify(new ReservationDoc(this.reservation)));
                version.editor = {
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                };
                let isChanged = this.isReservationUpdated(version,event);
                console.log(":: fx - isChanged ::",isChanged)

                // Detect if there has been an update
                if (isChanged) {
                    await store.reservations.addReservationVersion(this.reservation._fsid,version);     // Store previous version in versions subcollection
                    await store.reservations.updateReservation(event,this.reservation._fsid);           // Update current reservation
                }
                this.formState = "view";
            },
            isReservationUpdated(resA,resB) {
                if (resA == null || resA == undefined || resB == null || resB == undefined) {
                    return false;
                } else {
                    const changedArr = [
                        resA['project'] != resB['project'],
                        resA['projectName'] != resB['projectName'],
                        resA['projectId'] != resB['projectId'],
                        resA['status'] != resB['status'],
                        new Date(resA['reservationStart']).valueOf() != new Date(resB['reservationStart']).valueOf(),
                        new Date(resA['reservationEnd']).valueOf() != new Date(resB['reservationEnd']).valueOf(),
                        resA['equipment'].sort().join("") != resB['equipment'].sort().join("")
                    ];
                    if (changedArr.filter(Boolean).length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            deleteReservation() {
                const confirmation = confirm("Are you sure you want to delete this reservation?")
                if (confirmation) {
                    store.reservations.deleteReservation(this.reservation._fsid).then(() => {
                        this.navigateBack();
                    })
                }
            },
            removeGearItem(gear) {
                this.equipment.selected = this.equipment.selected.filter(selectedGear => {
                    return selectedGear._fsid != gear._fsid;
                })
            },
            formatDate(val,format) {
                return moment(val).format(format);
            },
            isGearAvailable(gear) {
                return !this.unavailableEquipment.includes(gear._fsid)
            },
            isGearSelected(gear) {
                return this.equipment.selected.map(_ => _._fsid).includes(gear._fsid);
            },
            isGearStatusAvailable(status) {
                return this.unavailableStatus.indexOf(status) === -1;
            },
            gearSelectUpdate(gear) {
                console.log("gear",gear)
                console.log("model",this.equipment.selected)
            },
            gearSelectFocusEvent(val) {
                console.log("gearSelectFocusEvent");
                console.log(val);
            },
            gearSearch(options,search) {
                search = search ? search.toLowerCase() : '';
                const fitems = options.filter(item => {
                    return search == '' ||
                    (item.name && item.name.toLowerCase().includes(search)) || 
                    (item.category && item.category.toLowerCase().includes(search)) ||
                    (item.subcategory && item.subcategory.toLowerCase().includes(search)) ||
                    (item.tags && item.tags.join(" ").toLowerCase().includes(search)) ||
                    (item.barcode && item.barcode.toLowerCase().includes(search))

                    // const barcode = eqp.barcode ? eqp.barcode.toLowerCase() : '';
                    // const name = eqp.name ? eqp.name.toLowerCase() : '';
                    // return (barcode.indexOf(search) > -1) || (name.indexOf(search) > -1);
                })
                return fitems;
            },
            toggleSection(section) {
                this.ui.sections[section].expanded = !this.ui.sections[section].expanded;
            },
            likeNote(note) {
                console.log(":: likeNote ",note,"::")
                if (note && store.user.user) {
                    let likes = note.likes.filter(Boolean);
                    if (likes.includes(store.user.user.uid)) {
                        // Already liked by user
                        likes = likes.filter((val) => val != store.user.user.uid);
                        store.utilities.updateRecord('reservationNotes',note._fsid,{likes:likes})

                    } else {
                        // Not yet liked by user
                        likes.push(store.user.user.uid);
                        store.utilities.updateRecord('reservationNotes',note._fsid,{likes:likes})
                    }
                    
                }
            },
            submitNote(msg) {
                //e.preventDefault();
                if (this.reservation._fsid && store.user.user.uid) {
                    const note = {
                        author: store.user.user.uid,
                        authorName: store.user.user.displayName,
                        created: new Date(),
                        likes: [],
                        note: msg,
                        parent: '',
                        reservation: this.reservation._fsid
                    }
                    store.utilities.addRecord('reservationNotes',note)
                    //this.noteMsg = "";
                }
            },
            replyNote(note,reply) {
                if (this.reservation._fsid && store.user.user.uid) {
                    const record = {
                        author: store.user.user.uid,
                        authorName: store.user.user.displayName,
                        created: new Date(),
                        likes: [],
                        note: reply,
                        parent: note._fsid,
                        reservation: this.reservation._fsid
                    }
                    store.utilities.addRecord('reservationNotes',record)
                    this.noteMsg = "";
                }
            },
            deleteNote(note) {
                console.log(":: deleteNote - ", note);
                store.utilities.deleteRecord('reservationNotes',note._fsid);
            },
            showVersionHistory() {
                // Log Event
                store.analytics.logAppEvent({
                    uid: store.user.user.uid,
                    name: store.user.user.displayName,
                    email: store.user.user.email,
                    action: "View",
                    type: "Reservation Version History",
                    target: this.reservation._fsid,
                    url: window.location.href,
                    datetime: new Date()
                });

                // Fetch version history and calculate field updates
                this.versions.items = [];
                this.versions.changes = [];
                this.versions.show = true;
                let reservationUpdates = [];
                store.reservations.getReservationVersions(this.reservation._fsid).then(vh => {
                    const currentReservation = JSON.parse(JSON.stringify(new ReservationDoc(this.reservation)));
                    if (vh.length > 0) {
                        // Add current version to the end of the version history array
                        vh.push(currentReservation);
                        console.log("currentReservation",currentReservation)
                        
                        // Log first version as created
                        reservationUpdates.push({
                            date: moment(vh[0].createdDate).format('MM/DD/YYYY, h:mm A'),
                            editor: vh[0].editor,
                            id: vh[0]._fsid,
                            updates: [
                                {
                                    field: 'Project',
                                    value: vh[0].project
                                },
                                {
                                    field: 'Project Name',
                                    value: vh[0].projectName
                                },
                                {
                                    field: 'Reservation Start',
                                    value: moment(vh[0].reservationStart).format('MM/DD/YYYY, h:mm A')
                                },
                                {
                                    field: 'Reservation End',
                                    value: moment(vh[0].reservationEnd).format('MM/DD/YYYY, h:mm A')
                                },
                                {
                                    field: 'Gear',
                                    value: vh[0].equipment.map(gear => this.lookupGearById(gear)).join('<br />')
                                }
                            ]
                        });

                        // Run compares
                        for (let i = 1;i < vh.length;i++) {
                            const changes = this.compareVersions(vh[i-1],vh[i])
                            
                            if (changes.length > 0) {
                                // Field changes detected between versions
                                reservationUpdates.push({
                                    date: moment(vh[i-1].modifiedDate).format('MM/DD/YYYY, h:mm A'),
                                    editor: vh[i-1].editor,
                                    id: vh[i-1]._fsid,
                                    updates: changes
                                });
                            }
                        }
                    }
                    if (vh.length == 0) {
                        // Log current version as created
                        reservationUpdates.push({
                                date: moment(currentReservation.createdDate).format('MM/DD/YYYY, h:mm A'),
                                editor: currentReservation.editor,
                                id: this.reservation._fsid,
                                updates: [
                                    {
                                        field: 'Project',
                                        value: currentReservation.project
                                    },
                                    {
                                        field: 'Project Name',
                                        value: currentReservation.projectName
                                    },
                                    {
                                        field: 'Reservation Start',
                                        value: moment(currentReservation.reservationStart).format('MM/DD/YYYY, h:mm A')
                                    },
                                    {
                                        field: 'Reservation End',
                                        value: moment(currentReservation.reservationEnd).format('MM/DD/YYYY, h:mm A')
                                    },
                                    {
                                        field: 'Gear',
                                        value: currentReservation.equipment.map(gear => this.lookupGearById(gear)).join('<br />')
                                    }
                                ]
                        })
                    }
                    
                    
                    this.versions.changes = reservationUpdates.reverse();

                }).catch(error => {
                    // Error fetching versions
                    console.log(error);
                });
            },
            hideVersionHistory() {
                this.versions.show = false;
            },
            compareVersions(v1,v2) {
                let changes = [];
                if (v1 != null && v2 != null) {
                    if (v1['project'] != v2['project']) {
                        changes.push({field:'Project',value: v2['project']})
                    }
                    if (v1['projectName'] != v2['projectName']) {
                        changes.push({field:'Project Name',value: v2['projectName']})
                    }
                    if (v1['reservationStart'] != v2['reservationStart']) {
                        changes.push({field:'Reservation Start',value: moment(v2['reservationStart']).format('MM/DD/YYYY, h:mm A')})
                    }
                    if (v1['reservationEnd'] != v2['reservationEnd']) {
                        changes.push({field:'Reservation End',value: moment(v2['reservationEnd']).format('MM/DD/YYYY, h:mm A')})
                    }
                    if (v1['equipment'].sort().join('') != v2['equipment'].sort().join('')) {
                        changes.push({field:'Gear',value: v2.equipment.map(gear => this.lookupGearById(gear)).join('<br />')})
                    }

                    if (v1['reservationStarted'] != v2['reservationStarted'] && v2['reservationStarted']!=null) {
                        changes.push({field:'Reservation Started',value: moment(v2['reservationStarted']).format('MM/DD/YYYY, h:mm A')})
                    }
                    if (v1['reservationEnded'] != v2['reservationEnded'] && v2['reservationEnded']!=null) {
                        changes.push({field:'Reservation Completed',value: moment(v2['reservationEnded']).format('MM/DD/YYYY, h:mm A')})
                    }
                }
                return changes;
            },
            lookupGearById(gearId) {
                let item = store.equipment.items.find(gear => {
                    return gear._fsid == gearId;
                })
                return item ? item.name : "Gear not found";
            },
            async updateReservationStatus(action) {                
                // Store previous version
                let version = JSON.parse(JSON.stringify(new ReservationDoc(this.reservation)));
                version.editor = {
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                };

                let update = {
                    status: action === 'start' ? 'active' : 'complete'
                }
                // Write update to database
                update[action === 'start' ? 'reservationStarted' : 'reservationEnded'] = new Date();
                await store.reservations.updateReservation(update,this.reservation._fsid);
                // Update version history
                await store.reservations.addReservationVersion(this.reservation._fsid,version);

            }
        },
        computed: {
            notesWithLikes() {
                return this.store.reservations.notes.map(res => {
                    res["_likes"] = this.store.user.allUsers.filter(usr => {
                        return res.likes.includes(usr._fsid);
                    })
                    return res;
                })
            },
            reservation() {
                let item = new Reservation(store.reservations.items.find(item => item._fsid == this.$route.params.id))
                // let item = store.reservations.items.find(item => item._fsid == this.$route.params.id);
                return item;
            },
            reservationEquipment() {
                let equipment = [];
                let equipmentIds = [];
                let uniqueEquipmentIds = [];
                if (this.reservation && this.reservation.start && this.reservation.end) {
                    const selStartDate = new Date(this.reservation._fpstart);
                    const selEndDate = new Date(this.reservation._fpend);
                    const overlappingReservations = store.reservations.items.filter(reservation => {
                        // Overlapping conditions:
                        //  1. ReservationEnd > SelectedStart
                        //  2. ReservationStart < SelectedEnd
                        return (reservation.end > selStartDate && reservation.start < selEndDate) && reservation._fsid != this.reservation._fsid
                    })
                    overlappingReservations.forEach(res => {
                        equipmentIds = equipmentIds.concat(res.equipment)
                    })
                    uniqueEquipmentIds = [...new Set(equipmentIds)];
                }
                equipment = store.equipment.items.filter(gear => {
                    return this.reservation.equipment ? this.reservation.equipment.includes(gear._fsid) : false;
                }).map(gear => {
                    gear['_conflict'] = uniqueEquipmentIds.includes(gear._fsid);
                    return gear;
                });
                return equipment
            },
            unavailableEquipment() {
                if (this.reservationInstance._fpstart && this.reservationInstance._fpend) {
                    const selStartDate = new Date(this.reservationInstance._fpstart);
                    const selEndDate = new Date(this.reservationInstance._fpend);
                    const overlappingReservations = store.reservations.items.filter(reservation => {
                        // Overlapping conditions:
                        //  1. ReservationEnd > SelectedStart
                        //  2. ReservationStart < SelectedEnd
                        return (reservation.end > selStartDate && reservation.start < selEndDate) && reservation._fsid != this.reservationInstance._fsid
                    })
                    
                    let equipmentIds = [];
                    overlappingReservations.forEach(res => {
                        equipmentIds = equipmentIds.concat(res.equipment)
                    })
                    let uniqueEquipmentIds = [...new Set(equipmentIds)];
                    return uniqueEquipmentIds

                } else {
                    return []
                }
            },
            filteredEquipment() {
                let equipment = store.equipment.items;
                const isAdmin = store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin');
                // Filter out discarded and donated equipment
                if (!isAdmin) {
                    equipment = equipment.filter(item => {
                        return item.status != "Donated" && item.status != "Discarded";
                    })
                }
                return equipment;


                // console.log("::",this.ui.equipmentSearch,"::")
                // const fitems = store.equipment.items.filter(eqp => {
                //     return eqp.barcode.indexOf(this.ui.equipmentSearch) > -1
                // })
                // console.log(fitems)
                // return fitems;
            }
        },
        mounted() {
            store.reservations.subReservationNotes(this.$route.params.id);
            // Log Events
            store.analytics.logAppEvent({
                uid: store.user.user.uid,
                name: store.user.user.displayName,
                email: store.user.user.email,
                action: "View",
                type: "Reservation",
                target: this.$route.params.id,
                url: window.location.href,
                datetime: new Date()
            });
        },
        unmounted() {
            store.reservations.unsubReservationNotes();
        }
    }
</script>