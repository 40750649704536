<template>
    <div class="sm:block w-full sm:w-[50%] lg:w-[33%] xl:w-[25%]">
        <div class="my-2 sm:my-4 sm:mx-4">
            <div class="flex flex-col group bg-white shadow-lg rounded overflow-hidden cursor-pointer hover:shadow-gray-400/50">
                <!-- PROJECT CODE & NAME -->
                <div class="px-4 py-2 bg-bestbuy-800 text-white">
                    <div class="flex justify-between">
                        <span class="text-sm font-medium text-bestyellow-800">{{ reservation.project }}</span>
                        <div class="w-6 h-6 flex-shrink-0">
                            <div :style="`background-image:url('${ reservation.owner.profileImg ? reservation.owner.profileImg : defaultUsrImg }')`" name="userprofileimage" class="h-[24px] w-[24px] rounded-full bg-center bg-cover cursor-pointer"></div>
                        </div>
                    </div>
                    <div class="font-normal text-lg md:text-xl mb-2 one-line">{{ reservation.projectName }}</div>
                </div>
                <!-- DIVIDER -->
                <div class="h-px w-full bg-gray-100"></div>
                <!-- RESERVATION DATES-->
                <div class="px-6 py-4 flex justify-around items-center grow">
                    <div class="text-center">
                        <div class="font-light text-3xl">{{ reservation._mstart.format('MMM D') }}</div>
                        <div>
                            <font-awesome-icon class="text-gray-200 text-xs" icon="fa-calendar" />
                            {{ reservation._mstart.format('dddd') }}
                        </div>
                    </div>
                    <font-awesome-icon class="text-2xl text-gray-300" icon="arrow-right-long" />
                    <div class="text-center">
                        <div class="font-light text-3xl">{{ reservation._mend.format('MMM D') }}</div>
                        <div>
                            <font-awesome-icon class="text-gray-200 text-xs" icon="fa-calendar" />
                            {{ reservation._mend.format('dddd') }}
                        </div>
                    </div>
                </div>
               
                <!-- RESERVATION LENGTH -->
                <!-- <div v-if="reservation._mstart.diff(new Date(),'days') >= 0" class="text-center bg-bestbuy-100 px-2 py-1"> -->
                <div v-if="getDayDifference(reservation._mstart) >= 0" class="text-center bg-bestbuy-100 px-2 py-1">
                    <span>
                        <!-- Starts in <span class="font-semibold text-lg">{{ (reservation._mstart.date() - new Date().getDate()) }}</span> days -->
                        Starts in <span class="font-semibold text-lg">{{ Math.abs(getDayDifference(reservation._mstart)) }}</span> days
                    </span>
                </div>
                <div v-else class="text-center bg-bestyellow-100 px-2 py-1">
                    <span>
                        Started <span class="font-semibold text-lg">{{ Math.abs(getDayDifference(reservation._mstart)) }}</span> days ago
                    </span>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment';

    export default {
        props: ['reservation'],
        methods:{
            formatDate(dt,format) {
                return moment(dt).format(format);
            },
            getDayDifference(d) {
                if (d) {
                    return d.startOf('day').diff(moment().startOf('day'),'days');
                } else {
                    return 0
                }
            }
        }
    }
</script>

<style>

</style>
