<template>
    <div :class="isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'" class="transition-opacity ease-in duration-100 relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-1000"></div>

        <div @click="close" class="fixed inset-0 z-10 overflow-y-auto flex">
            <div class="flex w-full items-center justify-center text-center sm:items-center ">
                <!-- MODAL CONTENT -->
                <div @click.stop v-show="isOpen" class="relative flex flex-col h-full sm:h-auto transform sm:rounded-lg bg-white text-left shadow-xl transition-all w-full sm:max-w-2xl">
                    <!-- MODAL HEADER -->
                    <div class="bg-bestbuy-800 text-white px-4 pt-2 pb-2 text-xl" @click="checkEquipmentAvailability">Create a Reservation</div>
                    <!-- MODAL BODY -->
                    <div class="bg-white grow px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <form @submit.stop>
                            <div class="grid grid-cols-12 gap-8">
                                <!-- PROJECT -->
                                <div class="col-span-12 flex gap-4 items-center justify-center">
                                    <div :class=" projects.selected == null ? 'text-bestyellow-800' : 'text-gray-300' " class="h-full w-12 flex items-start justify-start font-bold text-6xl">1</div>
                                    <div class="w-full">
                                        <div class="text-md font-medium text-black">Select a project</div>
                                        <div class="">
                                            <v-select v-model="projects.selected" :options="store.projects.items" label="_projectIdName" class="" ></v-select>
                                        </div>
                                    </div>
                                </div>

                                <!-- DATES -->
                                <div class="col-span-12 flex gap-4 items-center justify-center" v-if="projects.selected != null">
                                    <div :class="projects.selected != null && (event.start == null || event.end == null) ? 'text-bestyellow-800' : 'text-gray-300' " class="h-full w-12 flex items-start justify-start font-bold text-6xl">2</div>
                                    <div class="w-full">
                                        <div class="text-md font-medium text-black">Choose your reservation dates</div>
                                        <div class="flex items-center gap-6 flex-wrap">
                                            <div class="w-full md:w-1/3 min-w-[180px]">
                                                <div class="">
                                                    <flat-pickr v-model="event.start" :config="flatpicker.startDate.config" placeholder="Start date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                                                </div>
                                            </div>
                                            <div class="text-mds font-light hidden md:block">
                                                <font-awesome-icon class="text-1xl text-slate-600" icon="arrow-right-long" />
                                            </div>
                                            <div class="w-full md:w-1/3 min-w-[180px]">
                                                <div class="">
                                                    <flat-pickr v-model="event.end" :config="flatpicker.endDate.config" placeholder="End date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- EQUIPMENT -->
                                <div class="col-span-12 flex gap-4 items-center justify-center" v-if="projects.selected != null && (event.end != null && event.start != null)">
                                    <div :class="projects.selected != null && !(event.start == null || event.end == null) ? 'text-bestyellow-800' : 'text-gray-300' " class="h-full w-12 flex items-start justify-start font-bold text-6xl">3</div>
                                    <div class="w-full">
                                        <div class="text-md font-medium text-black">Pick your gear</div>
                                        <div class="v-hide-selected">
                                            <v-select v-model="equipment.selected" :options="availableEquipment" :disabled="!event.start || !event.end" label="name" placeholder="Search and add equipment" multiple></v-select>
                                        </div>
                                        <div class="flex items-start content-start flex-wrap py-2 gap-2 min-h-[120px]">
                                            <div class="bg-gray-200 rounded text-sm flex items-center select-none " v-for="equipment in equipment.selected" :key="equipment.name" >
                                                <div class="pl-2">{{ equipment.name }}</div>
                                                <div @click="removeEquipment(equipment)" class="rounded flex items-center justify-center hover:bg-gray-300 px-2 h-full cursor-pointer">
                                                    <font-awesome-icon class="text-xs text-slate-600" icon="x" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <!-- MODAL FOOTER -->
                    <div class="border border-t border-gray-100 bg-gray-50 px-6 py-3 flex justify-center items-center mt-8">
                        <button @click="submit" :disabled="!event.start || !event.end || equipment.selected.length==0" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200 disabled:opacity-50">Submit</button>
                        <button @click="close" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200 ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '../store/store.js'
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    
    export default {
        components:{
            flatPickr,
            vSelect
        },
        props: ['isOpen', 'closeForm'],
        data() {
            return {
                store:store,
                event: {
                    project: "",
                    projectName:"",
                    start:null,
                    end:null,
                    equipment:null
                },
                flatpicker: {
                    startDate: {
                        config: {
                            enableTime: true,
                            dateFormat: "m/d/Y h:i K",
                            minDate: new Date(),
                            defaultHour: 12,
                            defaultMinute: 0,
                            onChange: (selectedDates, dateStr, instance) => this.reservationDateChange(selectedDates, dateStr, instance,'startDate')
                        }
                    },
                    endDate: {
                        config: {
                            enableTime: true,
                            dateFormat: "m/d/Y h:i K",
                            minDate: new Date(),
                            onChange: (selectedDates, dateStr, instance) => this.reservationDateChange(selectedDates, dateStr, instance,'endDate')
                        }
                    }
                },
                projects: {
                    selected:null
                },
                equipment: {
                    selected:[]
                },
                overlappingReservations:[],
                unavailableEquipment:[]
            }
        },
        computed: {
            availableEquipment() {
                return store.equipment.items.map(gear => {
                    gear['_isAvailable'] = !this.unavailableEquipment.includes(gear._fsid);
                    gear.name = gear.name + (this.unavailableEquipment.includes(gear._fsid) ? ' - unavailable' : '')
                    return gear;
                })
            }
        },
        methods: {
            output() {
                console.log(this.availableEquipment)
            },
            close() {
                this.closeForm();
                this.resetForm()
            },
            resetForm() {
                this.projects.selected = null
                this.equipment.selected = []
                this.event.start = null
                this.event.end = null
            },
            reservationDateChange(selectedDate, dateStr, instance, pickerType) {
                if (pickerType == 'startDate') {
                    // Update minimum end date value to start value
                    const endDateMin = selectedDate ? selectedDate[0] : null;
                    this.flatpicker.endDate.config.minDate = endDateMin;
                    this.checkEquipmentAvailability(dateStr,this.event.end)
                } else {
                    this.checkEquipmentAvailability(this.event.start,dateStr)
                }
            },
            removeEquipment(gear) {
                this.equipment.selected = this.equipment.selected.filter(item => item.equipmentId != gear.equipmentId )
            },
            submit() {
                
                const event = {
                    author:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    createdDate: new Date(),
                    editor:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    equipment:this.equipment.selected.map(_ => _._fsid),
                    modifiedDate: new Date(),
                    owner:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    project: this.projects.selected.projectnumber,
                    projectName:this.projects.selected.projecttitle,
                    projectId:this.projects.selected._fsid,
                    reservationStart: new Date(this.event.start),
                    reservationEnd: new Date(this.event.end)
                }
                store.reservations.createReservation(event).then(() => {
                    this.close()
                })
                
            },
            checkEquipmentAvailability(startDate,endDate) {
                console.log(":: checkEquipmentAvailability ::","Start: ",startDate," End: ",endDate)
                if (startDate && endDate) {
                    this.unavailableEquipment = [];
                    const selStartDate = new Date(startDate);
                    const selEndDate = new Date(endDate);
                    this.overlappingReservations = store.reservations.items.filter(reservation => {
                        // Overlapping conditions:
                        //  1. ReservationEnd > SelectedStart
                        //  2. ReservationStart < SelectedEnd
                        return (reservation.end > selStartDate && reservation.start < selEndDate)
                    })
                    console.log(" comp: overLapping Reservations",this.overlappingReservations)
                }
                /*
                store.reservations.getOverLappingReservations(startDate,endDate).then(items => {
                    this.overlappingReservations = items;
                    this.unavailableEquipment = [];
                    items.forEach(item => {
                        this.unavailableEquipment = this.unavailableEquipment.concat(item.equipment)
                    })
                    console.log("unavailableEquipment",this.unavailableEquipment)
                })
                */
                
            }

        },
        mounted() {
            console.log("NewReservation mounted");
            // Fetch Projects
            if (store.projects.items.length == 0) {
                store.projects.getProjects(false).then((results) => {
                    // Results
                });
            }
            // Fetch Equipment
            if (this.store.equipment.items.length == 0) {
                this.store.equipment.getEquipment().then((results) => {
                    // Results
                });
            }
        }
    }
</script>

<style>
input::placeholder {
    color: #cbd5e1!important;
}
input {
    cursor:pointer;
}
input:disabled {
    background:rgb(248,248,248);
    cursor:not-allowed;
}
.flatpickr-input:disabled {
    cursor: not-allowed;
}
.v-select .vs__selected-options {
    flex-wrap: nowrap;
}
.v-hide-selected .vs__selected {
    display:none;
}
</style>