<template>
    <section class="max-w-screen-2xl mx-auto px-4">
        <!-- BREADCRUMBS -->
        <div class="flex w-full justify-left pt-2">
            <div v-if="gear" class="flex w-full items-center pb-3 text-sm">
                <div class="breadcrumb-item truncate font-semibold text-gray-800 cursor-pointer select-none hover:underline" @click.stop=" selectFilter('catalog',gear.category) ">Equipment Catalog</div>
                <div v-if="gear['category']" class="breadcrumb-item truncate font-semibold text-gray-800 cursor-pointer select-none hover:underline" @click.stop="selectFilter('category',gear.category)">{{ gear.category }}</div>
                <div v-if="gear['subcategory']" class="breadcrumb-item truncate font-semibold text-gray-800 cursor-pointer select-none hover:underline"  @click.stop="selectFilter('subcategory',gear.subcategory)">{{ gear.subcategory }}</div>
            </div>
        </div>

       
        <!-- GEAR NAME -->
        <div class="py-4 pt-2 flex justify-between items-center">
            <PageTitle :title="gear ? gear.name : 'not found'" @click="logItem"></PageTitle>
            <button @click="editGear" v-if="store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin')" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
                <font-awesome-icon class="mr-2" icon="fa-pen-to-square" />
                <span class="hidden sm:inline">Edit Gear</span>
            </button>
            <button @click="editGear" v-if="store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin')" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
                <font-awesome-icon class="text-black text-2xl absolute" icon="fa-pen-to-square" />
            </button>
        </div>
        

        <!-- HR -->
        <div class="border-b-2 border-gray-300"></div>


        <!-- EQUIPMENT DETAILS -->
        <div v-if="gear" class="my-2">
            <div class="flex flex-col sm:flex-row gap-4">
                <div class="w-full sm:w-2/4 flex flex-col gap-4">
                    <!-- IMAGE GALLERY CONTROLS -->
                    <div class="flex justify-between items-center">
                        <div class="flex text-sm">
                            <button @click="setGalleryType('productimages')" :class="galleryType == 'productimages' ? 'bg-gray-400 text-white' : 'bg-white text-gray-600 hover:bg-gray-200' " class="py-1 px-2 md:py-2 md:px-4 border border-solid border-gray-400 rounded-l-md transition duration-200 ease-in-out select-none">Stock Images ({{ gear['images'] ? gear['images'].length : 0 }})</button>
                            <button @click="setGalleryType('userfeedback')" :disabled="userImages.length==0" :class="galleryType == 'userfeedback' ? 'bg-gray-400 text-white' : 'bg-white text-gray-600 hover:bg-gray-200' " class="py-1 px-2 md:py-2 md:px-4 border border-solid border-gray-400 rounded-r-md transition duration-200 ease-in-out select-none">User Images ({{ userImages.length }})</button>
                        </div>
                    </div>


                    <!-- IMAGE GALLERY -->
                    <div v-if="gear['images']">
                        <!-- COVER IMAGE -->
                        <div class="flex items-center justify-center h-96 overflow-hidden cursor-pointer">
                            <img v-if="galleryType=='productimages'" @click="openImageViewer(gear.images,imgIndex)" :src="(gear.images && gear.images.length>0) ? gear.images[imgIndex].url : ''" class="h-full object-cover" />
                            <img v-if="galleryType=='userfeedback'" @click="openImageViewer(userImages,imgIndex)" :src="userImages[imgIndex].url" class="h-full object-cover" />
                        </div>
                        <!-- THUMBNAILS -->
                        <div class="w-full flex items-center justify-center gap-4 my-2 overflow-hidden">
                            <div v-show="galleryType=='productimages'" v-for="(img,index) in gear.images" :key="img" >
                                <div  @click="thumbnailClick(index)" :class=" (index == imgIndex ? 'ring-1 border-black' : 'border-gray-300' )" class="transition duration-200 p-1 cursor-pointer shadow-sm ring-black border hover:ring-1 hover:border-black">
                                    <div :style="`background-image:url('${ img.url }')`" class="w-16 h-16 bg-center bg-no-repeat bg-cover rounded"></div>
                                </div>
                            </div>
                            <div v-show="galleryType=='userfeedback'" v-for="(img,index) in userImages" :key="img.url" >
                                <div @click="thumbnailClick(index)" :class=" (index == imgIndex ? 'ring-1 border-black' : 'border-gray-300' )" class="transition duration-200 p-1 cursor-pointer shadow-sm ring-black border hover:ring-1 hover:border-black">
                                    <div :style="`background-image:url('${ img.url }')`" class="w-16 h-16 bg-center bg-no-repeat bg-cover rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- GEAR NOTES -->
                    
                    <div v-if="gear.notes" class="bg-gray-100 border border-1 border-gray-300 rounded p-4">
                        <div class="text-bestbuy-800 two-line font-bold uppercase text-sm flex gap-2 items-center mb-2">
                            <font-awesome-icon class="" icon="fa-info-circle" />
                            <span>Notes</span>
                        </div>
                        <div class="">{{ gear.notes }}</div>
                    </div>


                </div>

                <div class="w-full sm:w-2/4">
                    <div class="mt-2 flex flex-col gap-4 justify-center items-start">
                        
                        <!-- EQUIPMENT DETAILS -->
                        <div class="w-full">
                            <div class="text-bestbuy-900 two-line font-bold text-xl mb-4">Details</div>
                            <div class="min-w-0  font-normal min-w-0">{{ gear.description }}</div>

                            <div class="flex gap-2 items-center">
                                <div>
                                    <EquipmentTags :tags="gear.tags" :mode="'view'"></EquipmentTags>
                                </div>
                            </div>


                            <!-- GEAR NOTES -->
                            <!-- <div v-if="gear.notes" class="h-4"></div>
                            <div v-if="gear.notes" class="bg-gray-100 border border-1 border-gray-300 rounded p-4">
                                <div class="text-bestbuy-800 two-line font-bold uppercase text-sm ">
                                    
                                    <font-awesome-icon class="" icon="fa-info-circle" />
                                    Notes
                                </div>
                                <div class="">{{ gear.notes }}</div>
                            </div> -->

                            <!-- GEAR PARENT -->
                            <div v-if="gear.isBundleChild" class="w-full">
                                <div class="flex flex-col h-full justify-end">
                                    <div class="pr-3 text-sm text-gray-800 font-light">
                                        <div class="flex align-center bg-slate-100 border border-slate-200 rounded px-2 py-1">
                                            <div class="w-full flex items-center justify-between">
                                                <div><font-awesome-icon class="text-bestbuy-800 pr-2 " icon="fa-circle-info" />This item is part of a bundle. </div>
                                                <div class="my-2">
                                                    <div class="flex items-center justify-center md:inline-flex gap-4">
                                                        <button v-if="gear.bundleParent" @click="openEquipment(gear.bundleParent)" class="text-bestbuy-800 font-semibold px-2 py-1 rounded-md bg-slate-200 hover:bg-slate-300 focus:outline-none transition duration-200 ">
                                                            <font-awesome-icon class="text-bestbuy-800 text-xs cursor-pointer mr-1" icon="fa-external-link" />
                                                            View bundle
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="h-4"></div>

                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Name: </div>
                                <div>{{ gear.name }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Category: </div>
                                <div>{{ gear.category }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Subcategory: </div>
                                <div>{{ gear.subcategory }}</div>
                            </div>
                            <div class="flex gap-2">
                                <div class="font-bold text-gray-600">Base Location: </div>
                                <div>{{ gear.baseLocation }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Item ID: </div>
                                <div>{{ gear.equipmentId }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Serial Number: </div>
                                <div>{{ gear.serialNumber }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Purchase Cost: </div>
                                <div>{{ gear.purchasePrice ? gear.purchasePrice.toLocaleString('en-US',{ style: 'currency', currency: 'USD' }) : '' }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Purchase Date: </div>
                                <div v-if="gear.purchaseDate && gear.purchaseDate.seconds !== 0">{{ moment(gear.purchaseDate.seconds * 1000).format('MM/DD/YYYY') }}</div>
                            </div>
                            <div class="flex gap-2 ">
                                <div class="font-bold text-gray-600">Status: </div>
                                <EquipmentStatusBadge :status="gear.status"></EquipmentStatusBadge>
                            </div>
                            <div class="w-full mt-2">
                                <!-- <div class="font-bold text-gray-600" @click="barcode">Barcode: </div> -->
                                <div class="w-full flex justify-center">
                                    <svg class="jsbarcode" :jsbarcode-value="gear.barcode"></svg>
                                </div>
                            </div>

                            
                        </div>

                        <!-- EQUIPMENT BUNDLE -->
                        <div v-if="gear.isBundleParent" class="w-full">
                            <div class="w-full h-1 bg-gray-300 my-2"></div>
                            <div>
                                <div class="text-bestbuy-900 two-line font-bold text-xl mb-4">Bundled Gear</div>
                                <div>
                                    <div v-for="child in bundledGear" :key="child._fsid" class="">
                                        <div @click="openEquipment(child._fsid)" class="flex items-center gap-2 py-1 cursor-pointer hover:bg-gray-100">
                                            <div v-if="child.images && child.images.length>0" :style="`background-image:url('${ child.images[0].url }')`" class="mx-2 w-8 h-8 bg-center bg-no-repeat bg-cover rounded"></div>
                                            <div class="select-none">{{ child.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- HORIZONTAL RULE -->
                        <div class="w-full border-t-2 border-gray-300 my-2"></div>

                        <!-- AVAILABILITY CALENDAR -->
                        <PageSection :title="'Availability'" :isCollapsed="true">
                            <FullCalendar ref="fullcalendar" :options="calendarOptions" />
                        </PageSection>

                        <!-- HORIZONTAL RULE -->
                        <!-- <div class="w-full h-1 bg-gray-300 my-2"></div> -->
                        <div class="w-full border-t-2 border-gray-300 my-2"></div>

                        <!-- USER FEEDBACK -->
                        <PageSection :title="`User Reviews (${userfeedback.length})`"  :isCollapsed="true">
                            <!-- FEEDBACK HEADER & CONTROLS -->
                            <div class="w-full flex flex-row-reverse justify-between">
                                <!-- NEW FEEDBACK BUTTON -->
                                <div>
                                    <button @click="openUploadImagesForms" class="sm:inline-flex w-auto justify-center items-center rounded-md px-4 py-2 text-bestbuy-800 hover:bg-gray-100 text-sm font-medium transition duration-200 ease-in-out select-none">
                                        <font-awesome-icon class="mr-2" icon="fa-plus" />
                                        <span class="sm:inline">New Review</span>
                                    </button>
                                </div>
                            </div>

                            <!-- FEEDBACK ITEMS -->
                            <div class="w-full flex flex-col gap-4">
                                <EquipmentFeedback v-for="feedback in userfeedback" :key="feedback._fsid" :feedback="feedback" :store="store" @update="getEquipmentFeedback" @imageClick="reviewImageClick"></EquipmentFeedback>
                                <div v-if="userfeedback.length == 0" class="my-4 italic font-light">It looks like no reviews have been left for this item.</div>
                            </div>
                        </PageSection>
                        

                        

                    </div>
                </div>
            </div>


        </div>

        <!-- RESERVATION Details -->
        <ReservationDetails :isOpen="forms.showDetails" :closeForm="closeDetailsForm" :reservation="selectedReservation" />

        <!-- UPLOAD IMAGES -->
        <EquipmentImageUpload  :isOpen="imageUploader.show" :closeForm="closeUploadImagesForm" :gear="gear" @closeEvent="closeUploadImagesForm" />

        <!-- VIEW IMAGES -->
        <ImageViewer v-if="imageviewer.show" :initialIndex="imageviewer.index" :images="imageviewer.images" :title="gear ? gear.name : ''" :store="store" @closeEvent="closeImageViewer"></ImageViewer>
        
    </section>

</template>

<script>
import { store } from '../store/store.js'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import EquipmentTags from '../components/EquipmentTags.vue';
import ReservationDetails from '../components/ReservationDetails.vue';
import moment from 'moment';
import PageTitle from '../components/PageTitle.vue';
import ImageViewer from '../components/ImageViewer.vue';
import EquipmentStatusBadge from '../components/EquipmentStatusBadge.vue';
import EquipmentImageUpload from '../components/EquipmentImageUpload.vue';
import EquipmentFeedback from '../components/EquipmentFeedback.vue';
import PageSection from '../components/PageSection.vue';

import jsbarcode from 'jsbarcode';

export default {
    components: {
      FullCalendar,
      EquipmentTags,
      ReservationDetails,
      PageTitle,
      EquipmentStatusBadge,
      EquipmentImageUpload,
      EquipmentFeedback,
      ImageViewer,
      PageSection
    },
    data(){
        return {
            moment:moment,
            store,
            fsid: this.$route.params.id,
            imgIndex:0,
            reservations:[],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                events: [],
                eventClick: (info) => {
                    this.selectReservation(info.event.extendedProps)
                },
                eventContent: (item) => { 
                    return {
                    html: 
                    `<div class='flex gap-2 p-1 items-center'>
                        <div class="rounded-full w-8 h-8 bg-center bg-cover" style="min-width:32px;min-height:32px;background-image: url('${ item.event.extendedProps.owner.profileImg ? item.event.extendedProps.owner.profileImg : store.user.defaultUserImg }');" title="${ item.event.extendedProps.owner.name }" ></div>
                        <div class="overflow-hidden truncate">${ item.event.title }</div>
                    </div>`
                    }
                },
                headerToolbar: {
                    end:'prev,next'
                },
                height:'auto',
                eventBackgroundColor: "#DEE7F0",//"#00be78",//"#46be00", // fallback bg color
                eventBorderColor: "#F1F5F9",
                eventTextColor: "#333"
            },
            forms:{
                showNew:false,
                showDetails:false
            },
            selectedReservation:null,
            imageUploader: {
                show:false
            },
            galleryType: "productimages",
            userfeedback:[],
            imageviewer:{
                show:false,
                index:0,
                images:[]
            }
        }
    },
    methods:{
        closeImageViewer() {
            this.imageviewer.show = false;
            this.imageviewer.index = 0;
            this.imageviewer.images = [];
        },
        openImageViewer(imgs,idx) {
            this.imageviewer.index = idx;
            this.imageviewer.images = imgs;
            this.imageviewer.show = true;
        },
        thumbnailClick(idx){
            this.imgIndex = idx;
        },
        logItem() {
            console.log(this.$route.params.id)
            console.log(this.store.equipment.items)
            console.log(this.store.equipment.items.find(item => item._fsid == this.$route.params.id))
        },
        log(item){
            console.log(item);
        },
        selectFilter(field,value) {
            this.store.catalog.addFilter(field,value);
            this.$router.push({name:"equipment"});
        },
        navigateBack() {
            this.$router.back()
        },
        editGear() {
            this.$router.push({
                name:"equipmentedit",
                params: {
                    id: this.$route.params.id
                }
            })
        },
        selectReservation(reservation) {
          this.selectedReservation = reservation;
          this.forms.showDetails = true;
        },
        closeDetailsForm() {
          this.selectedReservation = null;
          this.forms.showDetails = false;
        },
        openEquipment(_fsid) {
            this.$router.push({
            name:"equipmentdetails",
            params: {
              id: _fsid
            }
          })
        },
        barcode() {
            //jsbarcode("#code128", "0000101");
            jsbarcode(".jsbarcode").init();
        },
        openUploadImagesForms() {
            this.imageUploader.show = true;
        },
        closeUploadImagesForm() {
            this.imageUploader.show = false;
            this.getEquipmentFeedback();
        },
        setGalleryType(type) {
            this.imgIndex = 0;
            this.galleryType = type;
        },
        getEquipmentFeedback() {
            // Get equipment's feedback
            const equipmentid = this.$route.params.id;
            if (equipmentid) {
                store.equipment.getEquipmentFeedback(equipmentid).then(feedback => {
                    console.log("feedback",feedback)
                    this.userfeedback = feedback.map(item => {
                        let created = item.created 
                            ? (item.created.hasOwnProperty("seconds") ? moment(item.created.seconds * 1000).format('MMMM D, YYYY') : "") 
                            : "";

                        item["_created"] = created;
                        return item;
                    });
                });
            }
        },
        reviewImageClick(images,idx) {
            console.log(idx,images);
            this.openImageViewer(idx,images);
        }
    },
    computed: {
        gear() {
            return this.store.equipment.items.find(item => item._fsid == this.$route.params.id)
        },
        bundledGear() {
            let items = [];
            if (this.gear && this.gear['bundleEquipment']) {
                items = this.store.equipment.items.filter(_ => this.gear['bundleEquipment'].indexOf(_['_fsid']) !== -1)
            }
            return items;
        },
        userImages() {
            let usrimages = [];
            this.userfeedback.forEach(feedback => {
                let _feedback = JSON.parse(JSON.stringify(feedback));
                usrimages = usrimages.concat(
                    _feedback.images.map(img => {
                        delete _feedback.images
                        img["_feedback"] = _feedback;
                        return img;
                    })
                )
            });
            return usrimages;
        },
    },
    mounted() {
        // Log Event
        store.analytics.logAppEvent({
            uid: store.user.user.uid,
            name: store.user.user.displayName,
            email: store.user.user.email,
            action: "View",
            type: "Gear",
            target: this.$route.params.id,
            url: window.location.href,
            datetime: new Date()
        });

        // Get equipment's reservations
        console.log("EquipmentDetails mounted")
        store.reservations.getReservationsForEquipment(this.$route.params.id).then((reservations) => {
            this.reservations = reservations;
            this.calendarOptions.events = reservations;
        })

        // Ensure all equipment is loaded
        if (this.store.equipment.items.length == 0) {
            this.store.equipment.getEquipment().then((results) => {
                // Results
            });
        }

        // Get equipment's feedback
        this.getEquipmentFeedback();

        // Render barcode

    },
    updated() {
        jsbarcode(".jsbarcode").init();
    }
}
</script>