<template>
  <section class="max-w-screen-2xl mx-auto px-4">
    <div class="py-4 flex justify-between items-center">
      <PageTitle :title="'Equipment Catalog'"></PageTitle>
      <div class="flex gap-4">
        <!-- EXPORT EQUIPMENT BUTTON -->
        <button @click="exportEquipment" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-300 transition duration-200 ease-in-out select-none">
            <font-awesome-icon class="mr-2" icon="fa-download" />
            <span class="hidden sm:inline">Export</span>
        </button>
        

        <!-- NEW EQUIPMENT BUTTON -->
        <button @click="newEquipment" v-if="store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin')" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
            <font-awesome-icon class="mr-2" icon="fa-plus" />
            <span class="hidden sm:inline">Add Gear</span>
        </button>
        <button @click="newEquipment" v-if="store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin')" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
            <font-awesome-icon class="text-black text-2xl absolute" icon="fa-plus" />
        </button>
      </div>
    </div>
    <!-- HR -->
    <div class="border-b-2 border-gray-300"></div>
    <!-- CONTROl BAR -->
    <div class="flex justify-between items-center py-4">
      <!-- SEARCH -->
      <div class="flex gap-2 w-full items-end items-center">
        <input @keydown="resetPaging" v-model="searchQuery" type="text" class="form-control block w-full md:max-w-md px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" placeholder="Search catalog" />
        <font-awesome-icon @click="toggleBarcodeScanner" class="text-bestbuy-800 text-xl cursor-pointer" icon="fa-camera" />
        <!-- <QRScanner v-if="ui.showBarcodeScanner" @barcodeUpdate="(bc) => barcodeScanUpdate(bc)"></QRScanner> -->
        <BarcodeScanner v-if="ui.showBarcodeScanner" @barcodeUpdate="(bc) => barcodeScanUpdate(bc)"></BarcodeScanner>
      </div>
      <!-- VIEW TYPE -->
      <div class="hidden sm:flex gap-4">
        <div class="flex items-center text-xl px-2 py-2 rounded cursor-pointer" :class="ui.viewType == 'grid' ? 'text-white bg-bestbuy-900 hover:bg-bestbuy-900' : 'text-bestbuy-900 bg-white hover:bg-gray-100'" @click="selectView('grid')"  title="Grid view">
          <font-awesome-icon icon="fa-grip" />
        </div>
        <div class="flex items-center text-xl px-2 py-2 rounded cursor-pointer" :class="ui.viewType == 'list' ? 'text-white bg-bestbuy-900 hover:bg-bestbuy-900' : 'text-bestbuy-900 bg-white hover:bg-gray-100'" @click="selectView('list')" title="List view">
          <font-awesome-icon icon="fa-list" />
        </div>
      </div>
    </div>

    <!-- FILTERS -->
    <div class="flex gap-2">
      <div v-for="filter in filtersArr" :key="filter.key" class="bg-slate-200 rounded text-sm flex items-center select-none capitalize">
        <div class="pl-2">{{ filter.value }}</div>
        <div class="text-xs ml-2 py-1 px-2 cursor-pointer hover:bg-slate-300 rounded-r" @click="removeFilter(filter.key)">
            <font-awesome-icon icon="fa-x" />
        </div>
      </div>
    </div>

    <div class="py-4 flex flex-wrap">
      <EquipmentCard v-for="equipment in filteredEquipment.slice(0,pageSizeTotal)" :key="equipment._fsid" :equipment="equipment" :viewtype="ui.viewType" @equipment-selected="selectEquipment(equipment)" @update-filter="(field,value) => filterItems(field,value)" />
    </div>
    <div class="text-center mt-4 mb-8">
      <button @click="loadMore" v-show="filteredEquipment.length > pageSizeTotal" class="inline-block text-black px-4 py-2 rounded text-xs font-medium bg-bestyellow-800 hover:bg-bestyellow-700">Load More</button>
    </div>

  </section>
</template>

<script>
  import { store } from '../store/store.js';
  import EquipmentCard from '../components/EquipmentCard.vue';
  import PageTitle from '../components/PageTitle.vue';
  import QRScanner from "../components/QRScanner.vue";
  import BarcodeScanner from "../components/BarcodeScanner.vue";

  const ls = require('local-storage');

  export default {
    components: {
      EquipmentCard,
      PageTitle,
      QRScanner,
      BarcodeScanner
    },
    data() {
      return {
        store:store,
        ui:{
          viewType: ls('ab-catalog-view') ? ls('ab-catalog-view') : 'grid',
          page: 0,
          pageSize:36,
          showBarcodeScanner: false
        },
        forms:{
          showDetails: false
        },
        selectedEquipment:{},
        searchQuery:"",
        filters:{},
        equipmentStore: []
      }
    },
    methods: {
      async getEquipment() {
        const results = await store.getEquipment();
      },
      toggleBarcodeScanner() {
        this.ui.showBarcodeScanner = !this.ui.showBarcodeScanner;
      },
      barcodeScanUpdate(bc) {
          console.log("barcodeScanUpdate :",bc)
          this.searchQuery = "barcode:"+bc;
          this.ui.showBarcodeScanner = false;
      },
      selectView(view) {
        this.ui.viewType = view;
        ls.set('ab-catalog-view',view)
      },
      selectEquipment(equipment) {
          this.selectedEquipment = equipment;
          
          this.$router.push({
            name:"equipmentdetails",
            params: {
              id:this.selectedEquipment._fsid
            }
          })
      },
      filterItems(field,value) {
        this.store.catalog.addFilter(field,value);
      },
      removeFilter(fltr) {
        store.catalog.removeFilter(fltr)
      },
      newEquipment() {
        this.$router.push({
            name:"equipmentedit",
            params: {
                id: 'new'
            }
        })
      },
      resetPaging() {
        this.ui.page = 0
      },
      loadMore() {
        this.ui.page = this.ui.page + 1;
      },
      async exportEquipment() {
        store.equipment.exportAsCSV();
      }
    },
    computed: {
      filtersArr() {
        let arr = []
          for (const [k,v] of Object.entries(store.catalog.filters)) {
          arr.push({key:k,value:v})
        }
        return arr;
      },
      filteredEquipment() {
        // Filter equipment based on search query
        const isAdmin = store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin'); // Check if user is admin to determine if they can see discarded and donated equipment
        const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
        let fd = [];
        // Check for barcode
        if (search.indexOf("barcode:") > -1) {
          const barcode = search.match(/(?<=barcode:).*/g)[0]
          fd = store.equipment.items.filter(function (item) {
            return item.barcode == barcode;
          })
          if (fd && fd.length>0) {
            this.selectEquipment(fd[0])
          }
        } else {
        // No barcode
          fd = store.equipment.items.filter(function (item) {
            return search == '' ||
              (item.name && item.name.toLowerCase().includes(search)) || 
              (item.category && item.category.toLowerCase().includes(search)) ||
              (item.subcategory && item.subcategory.toLowerCase().includes(search)) ||
              (item.tags && item.tags.join(" ").toLowerCase().includes(search)) ||
              (item.status && item.status.toLowerCase().includes(search)) ||
              (item.barcode && item.barcode.toLowerCase().includes(search))
          })

          // Filter equipment based on filters
          this.filtersArr.forEach(fltr => {
            fd = fd.filter(item => {
              return item[fltr.key] == fltr.value
            })
          })
        }

        // Filter out discarded and donated equipment
        if (!isAdmin) {
          fd = fd.filter(item => {
            return item.status != "Donated" && item.status != "Discarded" && item.status != "Transfer";
          })
        }

        return fd;
      },
      pageSizeTotal() {
        return (this.ui.page * this.ui.pageSize) + this.ui.pageSize
      }
    },
    mounted() {
      console.log("EquipmentView mounted");

      // Log Event
      store.analytics.logAppEvent({
          uid: store.user.user.uid,
          name: store.user.user.displayName,
          email: store.user.user.email,
          action: "View",
          type: "Gear Catalog",
          target: "Gear Catalog",
          url: window.location.href,
          datetime: new Date()
      });

      if (store.equipment.items.length == 0) {
        store.equipment.getEquipment().then((results) => {
          console.log("Equipment",results)
        });
      }
    }
  }
</script>