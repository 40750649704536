import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ReservationDetailsView from '../views/ReservationDetailsView.vue'
import NewReservationView from '../views/NewReservationView.vue'
import EquipmentView from '../views/EquipmentView.vue'
import EquipmentDetailsView from '../views/EquipmentDetailsView.vue'
import EquipmentEditView from '../views/EquipmentEditView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import MyProfileView from '../views/MyProfileView.vue'
import AppUsageView from '../views/AppUsageView.vue'
import ManageUsers from '../views/ManageUsers.vue'
import ManageProjects from '../views/ManageProjects.vue'
import { getAuth, onAuthStateChanged } from "firebase/auth"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/reservationdetails/:id',
    name: 'reservationdetails',
    component: ReservationDetailsView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/newreservation',
    name: 'newreservation',
    component: NewReservationView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: EquipmentView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/equipmentdetails/:id',
    name: 'equipmentdetails',
    component: EquipmentDetailsView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/equipmentedit/:id',
    name: 'equipmentedit',
    component: EquipmentEditView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: ScheduleView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    component: MyProfileView,
    meta: {
      requiresAuth: true,
      title: 'BBY Assets'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
      title: 'BBY Asset'
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPasswordView,
    meta: {
      requiresAuth: false,
      title: 'BBY Asset'
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: AppUsageView,
    meta: {
      requiresAuth: true,
      title: 'BBY Asset - Stats'
    }
  },
  {
    path: '/manageusers',
    name: 'manageusers',
    component: ManageUsers,
    meta: {
      requiresAuth: true,
      title: 'BBY Asset - Users'
    }
  },
  {
    path: '/manageprojects',
    name: 'manageprojects',
    component: ManageProjects,
    meta: {
      requiresAuth: true,
      title: 'BBY Asset - Projects'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'active-link text-yellow-400 underline',
  linkExactActiveClass: 'exact-active-link',
})

const getCurrentUser = () => {
  return new Promise((resolve,reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  })
}

router.beforeEach(async (to,from,next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next({name:"login"})
    }
  } else {
    next();
  }
})

router.afterEach(async (to, from) => {
  document.title = to.meta.title;
});

export default router
