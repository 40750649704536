<template>
    <section class="max-w-screen-2xl mx-auto px-4">
        <div class="py-4 flex justify-between items-center ">
            <div @click="printUser" class="text-2xl md:text-3xl font-bold">
                My Profile
            </div>
        </div>
        <!-- HR -->
        <div class="border-b-2 border-gray-300"></div>
        <!-- PROFILE FORM -->
        <div v-if="storeUser.user">
            <div class="flex flex-col gap-4 py-4 max-w-xl">
                <!-- DETAILS -->
                <div class="mx-auto">
                    <div :style="`background-image:url(${ update.newImageUrl ? update.newImageUrl : ( storeUser.userImg ? storeUser.userImg : storeUser.defaultUsrImg) })`" @click="selectInput" class="group flex h-80 w-80 rounded-full bg-center bg-cover cursor-pointer justify-center items-center relative shadow-md">
                        <div class="absolute w-full h-full bg-black rounded-full opacity-0 group-hover:opacity-50 transition duration-200 z-10"></div>
                        <div class="absolute w-full h-full flex items-center justify-center z-20 text-white opacity-0 group-hover:opacity-100 z-20">Upload new image</div>
                    </div>
                    <input type="file" @change="imageInputChange" id="profileimageinput" style="display:none" accept="image/png, image/jpeg" />
                </div>
                
                <div class="w-full">
                    <label for="display-name" class="block font-medium text-gray-700">Name</label>
                    <input v-model="storeUser.user.displayName" type="text" name="display-name" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                </div>
                <div class="w-full">
                    <label for="user-email" class="block font-medium text-gray-700">Email</label>
                    <input disabled v-model="storeUser.user.email" type="text" name="user-email" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                </div>
                <div class="px-6 py-3 flex justify-center items-center ">
                    <button @click="updateProfile" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200">Update</button>
                    <button @click="navigateTo('home')" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 font-medium text-black shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-gray-300 transition duration-200">Close</button>
                </div>
                <div v-if="update.message" :class=" update.success ? 'text-green-600' : 'text-red-600' " class="px-4 flex justify-center items-center">{{ update.message }}</div>
            </div>
        </div>
    </section>
</template>

<script>
import { store } from '../store/store.js'
import { getStorage, ref, uploadBytes } from "firebase/storage";

export default {
    emits: ["profileImgUpdate"],
    data() {
        return {
            //defaultUsrImg:"https://avatars.githubusercontent.com/u/1834413?v=4",
            update:{
                newImageUrl:"",
                success:true,
                messaage:""
            },
            storeUser: store.user
        }
    },
    methods: {
        updateProfile() {
            if (this.update.newImageUrl.length>0 && this.storeUser.user.photoURL != this.update.newImageUrl) {
                // New profile image
                console.log("Profile image change")
                this.uploadProfilePicture(() => this.saveProfileChanges())
            } else {
                // Only values have changed
                console.log("No profile image change")
                this.saveProfileChanges();
            }
        },
        navigateTo(routeName) {
            this.$router.push({name:routeName});
        },
        selectInput() {
            document.querySelector("#profileimageinput").click();
        },
        imageInputChange(event) {
            const fileList = event.target.files;
            if (fileList && fileList.length != 0) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.update.newImageUrl = e.target.result
                }
                reader.readAsDataURL(fileList[0])
            }
        },
        saveProfileChanges() {
            // Check if we have an updated profile image
            let o = {
                displayName: this.storeUser.user.displayName,
            }
            if (this.update.newImageUrl.length>0 && this.storeUser.user.photoURL != this.update.newImageUrl) {
                o['photoURL'] = this.update.newImageUrl
            }

            // Update user's profile from store
            store.user.updateUser(o).then(() => {
                this.update.success = true;
                this.update.message = "Your profile has been updated!"
                this.update.newImageUrl = "";
            }).catch(() => {
                this.update.success = false;
                this.update.message = "An error occured while attempting to save changes"
            })
        },
        uploadProfilePicture(_callback) {
            const path = 'UserProfileImages/' + this.storeUser.user.uid + "/profileimage.jpg";
            const file = document.getElementById("profileimageinput").files[0];
            const storage = getStorage();
            const storageRef = ref(storage,path);
            uploadBytes(storageRef,file).then((snapshot) => {
                this.update.newImageUrl = "https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/" + encodeURIComponent(path) + "?alt=media";
                //this.$emit('profileImgUpdate',this.update.newImageUrl + "&v=" + new Date().valueOf())
                if (_callback) {
                    _callback()
                }
            })
        },
        printUser() {
            console.log("store.user",this.storeUser)
        }
    },
    mounted() {
        console.log("v1");
        // Log Event
        store.analytics.logAppEvent({
            uid: store.user.user.uid,
            name: store.user.user.displayName,
            email: store.user.user.email,
            action: "View",
            type: "Profile",
            target: "My Profile",
            url: window.location.href,
            datetime: new Date()
        });
    }
}
</script>