<template>
  <div class="flex items-center">
    <div class="text-2xl md:text-3xl font-bold dark:text-white">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
    props: ['title']
}
</script>