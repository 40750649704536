<template>
    <Modal>
        <template v-slot:header>
            Barcode Scanner
        </template>
        <template v-slot:body>
            <div class="w-full h-96">
                <div id="scandit-barcode-picker">
                    
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button @click="closeScanner" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">Close</button>
        </template>
    </Modal>
</template>
  
<script>
import { store } from '../store/store.js';
import Modal from "./Modal.vue";
import * as ScanditSDK from "scandit-sdk";

export default {
    emits:['barcodeUpdate'],
    components:{Modal},
    data() {
        return {
            barcodePicker:null
        }
    },
    methods: {
        closeScanner() {
            this.$emit('barcodeUpdate','');
        },
        async initializeScandit() {
            await ScanditSDK.configure(
                "AcUjRRrhOXAQCeCxDhcl05QwFCPbKhuZ0zlqa31j6GzLQoZuBVa4F4hkV6RDWNBNfxSEutslEDBIYxRfM3QugopjYJNUVO77DVtfWa9Qx9flf5sO+TMZKb19kZwhU0MLXEx04CBxrlOfOI/ntyFnmJk/yAncKawuseMDbtClaSBi2mFMklkFKCtT6TIL9ysDwHF2smU94iFnxK7xULj46gRLiP4ZcMnlJPNQjMEmKb8636mNUhQhwS2gCEO5tJXWZo9i8dzxmY0FlhF2qK7c1wCJQEGpI8oDRAJ8/AeP4QORfsAG6O/TnnSAcpjb3TEmL37cc7J8J8D710aw5sKId/IrspxeBIPx23/cvlqMy7CUVrI+8kW/QNYJS+Tj2Ox4PuW3tR5iQSuwKMEccBgpSz7OksbhmQxxbDkUAPVph+rloespxunn84mmC71nm67CHtJV8FLb02+wSRGI00C26BRAvpoV5Bkch7rvNv9fqum3IXsY6n/23tNAS+V3nHNddtxgtxC7v1qOaiDpq9/ZYtzUgdpQXAtWvhdBmjnKzAuWUecL73nC/M+K2GHpU46ImZ6QNUKZc2KEezVYUpwIKDegRdI42jEIFd5wJEW/iInRkopglaoPivel3Ks6FDBMPdVi0XnlT/5GrqIeSFU/4XlBZj5vo934H1dVLKX5N97FlIWhbYT1MPFzv1X8ZlJ68hhhPiJ9pvTQMXDAQDF6Lhw0aYj87xwIQJjgpMDOZdu+sJE4hTmkpVf7epDvFnFuLb6eegEvRFlRDzyqLKjIfYlZ/I4hdsDjZuZdkvlWn6rthcBXzO38ZoTUAiVnn8hTI+JKEumPjpc=", 
                {
                    engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk/build",
                }
            );
            this.barcodePicker = await ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
                    playSoundOnScan: true,
                    vibrateOnScan: true,
            });
            
            // barcodePicker is ready here to be used (rest of the tutorial code should go here)
            const scanSettings = new ScanditSDK.ScanSettings({
                enabledSymbologies: [ScanditSDK.Barcode.Symbology.CODE128,ScanditSDK.Barcode.Symbology.EAN8, ScanditSDK.Barcode.Symbology.EAN13],
                codeDuplicateFilter: 1000, // Minimum delay between duplicate results
            });
            this.barcodePicker.applyScanSettings(scanSettings);

            this.barcodePicker.on("scan",(scanResult) => {
                let barcode = scanResult.barcodes[0].data;
                barcode = barcode.replace(/^0+/, '');
                // Log Event
                store.analytics.logAppEvent({
                    uid: store.user.user.uid,
                    name: store.user.user.displayName,
                    email: store.user.user.email,
                    action: "Scan",
                    type: "Barcode",
                    target: barcode,
                    url: window.location.href,
                    datetime: new Date()
                });
                this.$emit('barcodeUpdate',barcode);
                
            })

                
            
        }
    },
    mounted: function() {
        this.initializeScandit()
    },
    unmounted: function() {
        console.log(":: barcode destruction ::");
        this.barcodePicker ? this.barcodePicker.destroy() : false
    }
}
</script>

<style>
.html5-qrcode-element[type="button"] {
    padding:2px 12px;
    color:#ffffff;border-radius: 20px;
}
#html5-qrcode-button-camera-stop {
    background-color:rgb(239 68 68);
}
#html5-qrcode-button-camera-start {
    background-color:rgb(55 48 163);
}
#html5-qrcode-button-camera-permission {
    background-color:rgb(55 48 163);
}
</style>