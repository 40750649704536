<template>
    <div @click="close" id="modal" class="absolute sm:fixed z-10 inset-0 transition-opacity ease-in duration-100 sm:z-10" >
        <div class="overflow-y-hidden fixed sm:absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300">
            <div class="z-20 overflow-y-auto flex h-full">
                <div class="sm:relative sm:top-1/2 sm:-translate-y-1/2 flex w-full items-center justify-center text-center sm:items-center">
                    <!-- MODAL CONTENT -->
                    <div @click.stop :class="modalClasses" class="modal-content flex flex-col w-full h-full transform sm:rounded-lg bg-white text-left shadow-xl transition-all">
                        <!-- MODAL HEADER -->
                        <div v-if="!headless" class="bg-bestbuy-800 text-white px-4 pt-2 pb-2 text-xl">
                            <slot name="header"></slot>
                        </div>
                        <!-- MODAL BODY -->
                        <div class="bg-white grow px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-auto" >
                            <slot name="body"></slot>
                        </div>
                        <!-- MODAL FOOTER -->
                        <div class="border border-t border-gray-100 bg-gray-50 px-4 py-3 flex justify-center px-6 items-center">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        
    </div>
</template>

<script>
    export default {
        props: ['size','headless'],
        emits:['close'],
        data() {
            return {}
        },
        methods: {
            close() {
                this.$emit('close');
                const bodyEl = document.querySelector("body");
                bodyEl.classList.remove("body--modal-active");
            }
        },
        computed: {
            modalClasses() {
                // sm:max-w-2xl sm:h-auto sm:max-h-[80%]
                if (this.size) {
                    if (this.size == 'large') {
                        return 'sm:max-w-4xl sm:h-auto sm:max-h-[80%] sm:mx-8';
                    } else if (this.size == 'small') {
                        return 'sm:max-w-lg sm:h-auto sm:max-h-[80%]';
                    }
                }
                return 'sm:max-w-2xl sm:h-auto sm:max-h-[80%]';
            }
        },
        mounted() {
            console.log(":: Modal Mounted ::")
            const bodyEl = document.querySelector("body");
            bodyEl.classList.add("body--modal-active");
        },
        unmounted() {
            const bodyEl = document.querySelector("body");
            bodyEl.classList.remove("body--modal-active");
        }
    }
</script>

<style scoped>

#modal {
    height: 100vh;
}

@media (max-width: 640px) {
    #modal {
        height: 100vh;
        height: var(--doc-height);
    }
}
</style>