<template>
    <div class="flex gap-4 w-full">
        <div class="w-12 h-12">
            <div :style="`background-image:url('${ user.userImg }')`" name="userprofileimage" class="h-12 w-12 rounded-full bg-center bg-cover"></div>
        </div>
        <div class="w-full">
            <textarea @keydown.enter="submitNote" v-model="msg" type="text" :placeholder=" placeholder ? placeholder : 'Write a comment...'" class="form-control block w-full px-3 py-1.5 font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['user','placeholder'],
    emits: ['submit'],
    data() {
        return {
            msg: ""
        }
    },
    methods: {
        submitNote(e) {
            e.preventDefault();
            this.$emit('submit',this.msg);
            this.msg = "";
        }
    }
}
</script>