<template>
  <section class="max-w-screen-2xl mx-auto px-4">
    <div class="py-4 flex justify-between items-center ">
      <PageTitle :title="'My Reservations'"></PageTitle>
      <button @click="openNewForm" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
        <font-awesome-icon class="mr-2" icon="fa-plus" />
        <span class="hidden sm:inline">New Reservation</span>
      </button>
      <button @click="openNewForm" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
        <font-awesome-icon class="text-black text-2xl absolute" icon="fa-plus" />
      </button>
    </div>
    <!-- HR -->
    <div class="border-b-2 border-gray-300"></div>
    <!-- SEARCH & FILTERS -->
    <div class="py-4 flex gap-4">
      <input type="text" v-model="searchQuery" class="form-control max-w-md block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" placeholder="Search reservations" />
      <Menu :options="filter.options" @menu-update="menuUpdate" />
    </div>
    <!-- RESERVATIONS -->
    <div class="py-4 flex flex-wrap justify-center md:justify-start">
      <Reservation v-for="reservation in filteredReservations" :key="reservation.id" :reservation="reservation" @click="selectReservation(reservation)" />
      <div v-if="filteredReservations.length == 0" class="px-2 sm:px-4 italic font-light text-gray-500">It doesn't look like you have any active reservations.</div>
    </div>
  </section>
</template>

<script>
  import { store } from '../store/store.js'
  import Reservation from '../components/Reservation.vue'
  import Menu from '../components/Menu.vue'
  import PageTitle from '../components/PageTitle.vue';

  export default {
    components: {
      Reservation,
      Menu,
      // NewReservation,
      // ReservationDetails,
      PageTitle
    },
    data() {
      return {
        selectedReservation:null,
        searchQuery: "",
        filter: {
          menuValue:"active",
          options: [{
            name:"Active",
            value: "active"
          },
          {
            name:"Past",
            value: "past"
          }]
        },
        forms:{
          showNew:false,
          showDetails:false
        }
      }
    },
    methods: {
      menuUpdate(val) {
        this.filter.menuValue = val;
      },
      openNewForm() {
        this.$router.push({name:"newreservation"});
        // this.forms.showNew = true;
      },
      closeNewForm() {
        this.forms.showNew = false;
      },
      selectReservation(reservation){
        this.$router.push({
          name:"reservationdetails",
          params: {
            id:reservation._fsid
          }
        })
      },
      closeDetailsForm() {
        this.selectedReservation = null;
        this.forms.showDetails = false;
      },
      printStore() {
        store.user.printUser("displayName")
      }
    },
    computed: {
      filteredReservations() {
        // Filter reservations based on search and filters
        const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
        return store.reservations.items.filter(function(item) {
          // User's reservations
          return item.owner.id == store.user.user.uid;
        }).filter((item) => {
          // Filter value: active or past
          if (this.filter.menuValue == 'active') {
            // return (item.end >= new Date());
            return (item.status == 'not started' || item.status == 'active')
          } else {
            return (item.end < new Date() || item.status == 'complete');
          }
        }).filter(function (item) {
          return search == '' ||
            (item.project && item.project.toLowerCase().includes(search)) || 
            (item.projectName && item.projectName.toLowerCase().includes(search)) ||
            (item.equipment && item.equipment.join(" ").toLowerCase().includes(search))
        })
      }
    },
    mounted() {
      // Log Event
      if (store.user.user) {
        store.analytics.logAppEvent({
          uid: store.user.user.uid,
          name: store.user.user.displayName,
          email: store.user.user.email,
          action: "View",
          type: "Home",
          target: "My Reservations",
          url: window.location.href,
          datetime: new Date()
        });
      }

      // Fetch reservations if empty
      if (store.reservations.items.length == 0) {
        
      }
    },
    unmounted() {
      
    }
  }
</script>