<template>

    <div @click="close" id="modal" class="absolute sm:fixed z-10 inset-0 transition-opacity ease-in duration-100 sm:z-10" >
        <div class="overflow-y-hidden fixed sm:absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300">
            <div class="z-20 overflow-y-hidden flex h-full">
                <div class="sm:relative sm:top-1/2 sm:-translate-y-1/2 flex w-full items-center justify-center text-center sm:items-center">
                    <!-- MODAL CONTENT -->
                    <div @click.stop class="modal-content sm:h-auto sm:mx-8 flex flex-col justify-between w-full h-full sm:max-h-[80%] sm:max-w-[1200px] transform sm:rounded-lg bg-white text-left shadow-xl transition-all">
                        <!-- MODAL HEADER -->
                        <div>
                            <div class="p-4 font-medium">{{ title }}</div>
                            <div class="sm:hidden flex justify-between p-4">
                                <button  @click="changeIndex(-1)" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-1 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none w-auto text-sm transition duration-200 flex gap-2 align-center items-center"><font-awesome-icon class="text-black" icon="fa-chevron-left" /> Previous</button>
                                <button  @click="changeIndex(1)" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-1 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none w-auto text-sm transition duration-200 flex gap-2 align-center items-center">Next <font-awesome-icon class="text-black" icon="fa-chevron-right" /></button>
                            </div>
                        </div>
                        <!-- MODAL BODY -->
                        <div :style="`background-image:url('${ images[index].url }')`" class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 shrink relative overflow-hidden bg-center bg-no-repeat bg-contain" >
                            <!-- IMAGE -->
                            <img class="invisible " :src="images[index].url" />
                            <div @click="changeIndex(-1)" class="hidden sm:inline-flex absolute z-10 left-0 top-0 bottom-0 text-3xl cursor-pointer px-4 bg-gradient-to-r from-black/0 to-black/0 hover:from-black/25 flex flex-col justify-center transition-all duration-500">
                                <font-awesome-icon class="text-gray-400" icon="fa-chevron-left" />
                            </div>
                            <div @click="changeIndex(1)" class="hidden sm:inline-flex absolute right-0 top-0 bottom-0 text-3xl cursor-pointer px-4 bg-gradient-to-l from-black/0 to-black/0 hover:from-black/25 flex flex-col justify-center transition-all duration-500">
                                <font-awesome-icon class="text-gray-400" icon="fa-chevron-right" />
                            </div>
                        </div>
                        <!-- FEEBACK -->
                        <div v-if="_feedbackComment" class="p-4 select-none">
                            <div class="ml-2 text-sm"> {{ _feedbackCreated }}</div>
                            <!-- <font-awesome-icon icon="fa-quote-left" class="text-slate-600 text-xxxs" /> -->
                            <span class="italic font-light "><strong>"</strong>{{ _feedbackComment }}<strong>"</strong></span>
                            <!-- <font-awesome-icon icon="fa-quote-right" class="text-slate-600 text-xxxs" />  -->
                            <span class="ml-2 italic font-light">- {{ _feedbackAuthor }}</span>
                            
                        </div>

                        <!-- MODAL FOOTER -->
                        <div class="border border-t border-gray-100 bg-gray-50 px-4 py-3 flex justify-center px-6 items-center sm:rounded-b-lg">
                            <button @click="close" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none ml-3 w-auto text-sm transition duration-200">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

    export default {
        props: ['initialIndex','images','title','store'],
        emits: ['closeEvent'],
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key == 'Escape') {
                    this.$emit('closeEvent');
                } else if (e.key == 'ArrowRight') {
                    this.changeIndex(1);
                } else if (e.key == 'ArrowLeft') {
                    this.changeIndex(-1);
                }
            });
        },
        data() {
            return {
                index:0
            }
        },
        methods:{
            swipeEvent(e) {
                console.log(e);
            },
            close() {
                this.$emit('closeEvent');
                const bodyEl = document.querySelector("body");
                bodyEl.classList.remove("body--modal-active");
            },
            changeIndex(dif) {
                let newIndex = this.index + dif;
                if (newIndex >= this.images.length) {
                    // If at the end, circle back to zero
                    newIndex = 0;
                } else if (newIndex < 0) {
                    // If at the start, go to end
                    newIndex = this.images.length - 1;
                }
                this.index = newIndex;
            }
        },
        computed:{
            _feedbackAuthor() {
                let result = "";
                if (this.images[this.index].hasOwnProperty("_feedback")) {
                    result = this.images[this.index]._feedback.authorname
                }
                return result;
            },
            _feedbackComment() {
                let result = "";
                if (this.images[this.index].hasOwnProperty("_feedback")) {
                    result = this.images[this.index]._feedback.comment
                }
                return result;
            },
            _feedbackCreated() {
                let result = "";
                if (this.images[this.index].hasOwnProperty("_feedback")) {
                    result = this.images[this.index]._feedback._created
                }
                return result;
            }
        },
        mounted() {
            this.index = (this.initialIndex <= this.images.length) ? this.initialIndex : 0;

            // Log Event
            this.store.analytics.logAppEvent({
                uid: this.store.user.user.uid,
                name: this.store.user.user.displayName,
                email: this.store.user.user.email,
                action: "View",
                type: "Gear Image",
                target: this.images[this.index].url,
                url: window.location.href,
                datetime: new Date()
            });

            const bodyEl = document.querySelector("body");
            bodyEl.classList.add("body--modal-active");

        },
        unmounted() {
            const bodyEl = document.querySelector("body");
            bodyEl.classList.remove("body--modal-active");
        }
        
    }

</script>

<style scoped>
#modal {
    height: 100vh;
}

@media (max-width: 640px) {
    #modal {
        height: 100vh;
        height: var(--doc-height);
    }
}
</style>