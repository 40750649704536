<template>
  <section class="max-w-screen-2xl mx-auto px-4 flex flex-col items-center">
    <div class="py-4 ">
      <div class="text-2xl md:text-3xl font-bold">
        Sign in
      </div>
    </div>
    <div class="w-full max-w-xs">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <label for="user-email" class="block font-medium text-gray-700">Email</label>
          <input type="email" v-model="email" @keyup.enter="login" placeholder="jsmith@bestbuy.com" name="user-email" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
        </div>
        <div class="col-span-12">
          <div class="flex justify-between items-end">
            <label for="user-password" class="block font-medium text-gray-700">Password</label>
            <div @click="$router.push({name:'forgotpassword'})" class="text-xs text-bestbuy-500 hover:text-bestbuy-600 cursor-pointer">Forgot password</div>
          </div>
          <input type="password" v-model="password" @keyup.enter="login" placeholder="*********" name="user-password" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
        </div>
        <div class="col-span-12 text-center">
          <button @click="login" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200">Submit</button>
        </div>
        <div v-if="errorMsg" class="col-span-12">
          <div class="text-sm text-center text-red-600">{{ errorMsg }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { getAuth, signInWithEmailAndPassword ,sendPasswordResetEmail  } from "firebase/auth"

  export default {
    data() {
      return {
        email: "",
        password: "",
        errorMsg: ""
      }
    },
    methods: {
      login() {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then((data) => {
            this.$router.push({name:"home"})
          })
          .catch((error) => {
            console.log(error.code)
            switch (error.code) {
              case "auth/invalid-email":
                this.errorMsg = "Invalid email"
                break;
              case "auth/user-not-found":
                this.errorMsg = "No account with that email was found"
                break;
              case "auth/wrong-password":
                this.errorMsg = "Incorrect password"
                break;
              default:
                this.errorMsg = "Email or password was incorrect"
                break;
            }
          })
      },
      forgotPassword() {
        const auth = getAuth();
        sendPasswordResetEmail(auth,this.email)
          .then(() => {

          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
</script>