<template>
  <section class="max-w-screen-2xl mx-auto px-4">
        <!-- BREADCRUMBS -->
        <div class="flex w-full justify-left pt-2">
            <div v-if="gear" class="flex w-full items-center pb-3 text-sm">
                <div class="breadcrumb-item truncate font-semibold text-gray-800 cursor-pointer select-none hover:underline" @click.stop=" selectFilter('equipment','') ">Equipment Catalog</div>
                <div v-if="gear['category']" class="breadcrumb-item truncate font-semibold text-gray-800cursor-pointer select-none hover:underline" @click.stop="selectFilter('equipmentdetails',gear._fsid)">{{ gear.name }}</div>
                <div v-if="gear['subcategory']" class="breadcrumb-item truncate font-semibold text-gray-800 cursor-pointer select-none hover:underline" >Edit</div>
            </div>
        </div>

        <!-- GEAR NAME -->
        <div class="py-4 pt-2 flex justify-between items-center">
            <PageTitle :title="formState == 'edit' ? gear.name : 'New Gear'" @click="printDetails"></PageTitle>
        </div>
        
        <!-- HR -->
        <div class="border-b-2 border-gray-300"></div>

        <!-- GEAR DETAILS SECTION -->
        <div class="w-full mx-auto my-6">
            <div @click="toggleSection('details')" class="w-full border-b mb-2 flex items-center gap-2 cursor-pointer">
                <font-awesome-icon v-show="ui.sections.details.expanded" class="text-xs w-4" icon="fa-chevron-down" />
                <font-awesome-icon v-show="!ui.sections.details.expanded" class="text-xs w-4" icon="fa-chevron-right" />
                <span class="text-gray-900 text-xl" >Details</span>
            </div>
            <div :class="ui.sections.details.expanded ? 'max-h-[999999px]' : 'max-h-0' " class="overflow-hidden">
                <div v-if="gear && gear != null" name="section-details" class="h-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4 ">
                    <!-- NAME -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="name" class="font-bold text-gray-900">Name</label>
                            <input v-model="gear.name" name="name" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>
                    
                    <!-- ITEM ID -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="equipment-id" class="font-bold text-gray-900">ID</label>
                            <input v-model="gear.equipmentId" name="equipment-id" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>

                    <!-- CATEGORY -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="category" class="font-bold text-gray-900">Category</label>
                            <select v-model="gear.category" name="category" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                                <option v-for="cat in categories" :key="cat.category">{{ cat.category }}</option>
                            </select>
                            <!-- <input v-model="gear.category" name="category" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>

                    <!-- SUBCATEGORY -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="subcategory" class="font-bold  text-gray-900">Subcategory</label>
                            <select v-model="gear.subcategory" name="subcategory" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                                <option v-for="subcat in subcategories" :key="subcat">{{ subcat }}</option>
                            </select>
                            <!-- <input v-model="gear.subcategory" name="subcategory" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>

                    <!-- PURCHASE DATE -->
                    <div class="w-full" v-if="flatpicker.isMounted">
                        <div class="flex flex-col">
                            <label for="purchase-date" class="font-bold text-gray-900">Purchase Date</label>
                            <flat-pickr v-model="flatpicker.purchaseDateValue" :config="flatpicker.purchaseDate.config" placeholder="Purchase Date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>

                    <!-- PURCHASE PRICE -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="purhcase-price" class="font-bold text-gray-900">Purchase Price</label>
                            <input v-model="gear.purchasePrice" name="purchase-price" type="number" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>

                     <!-- STATUS -->
                     <div class="w-full">
                        <div class="flex flex-col">
                            <label for="status" class="font-bold text-gray-900">Status</label>
                            <select v-model="gear.status" name="status" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                                <option v-for="statusDef in statusDefs" :key="statusDef.value">{{ statusDef.value }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- BASE LOCATION -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="base-location" class="font-bold text-gray-900">Base Location</label>
                            <input v-model="gear.baseLocation" name="base-location" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>

                    <!-- SERIAL NUMBER -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="serial-number" class="font-bold text-gray-900">Serial Number</label>
                            <input v-model="gear.serialNumber" name="serial-number" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                        </div>
                    </div>

                    <!-- BARCODE -->
                    <div class="w-full">
                        <div class="flex flex-col" id="barcode-container">
                            <div class="flex justify-between items-center">
                                <label for="serial-number" class="font-bold text-gray-900">Barcode</label>
                                <font-awesome-icon @click="toggleBarcodeScanner" class="text-bestbuy-800 text-lg cursor-pointer" icon="fa-camera" />
                            </div>
                            <!-- <input v-model="gear.barcode" @focus="barcodeInputFocused" @blur="barcodeInputUnfocused" name="barcode" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                            <input v-model="gear.barcode" name="barcode" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <QRScanner v-if="ui.showBarcodeScanner" @barcodeUpdate="(bc) => barcodeScanUpdate(bc)"></QRScanner>
                        </div>
                    </div>

                    <!-- TAGS -->
                    <div class="w-full md:col-span-2">
                        <div class="flex flex-col">
                            <label for="tags" class="font-bold text-gray-900">Tags</label>
                            <input v-model="newTag" @keydown.enter="addTag" name="tags" type="text" placeholder="Enter tag and press enter" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <div class="py-2">
                                <EquipmentTags :tags="gear.tags" :mode="'edit'" @removeTag="removeTag"></EquipmentTags>
                            </div>
                        </div>
                    </div>

                    <!-- DESCRIPTION -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="description" class="font-bold text-gray-900">Description</label>
                            <textarea v-model="gear.description" name="description" rows="4" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" ></textarea>
                        </div>
                    </div>

                    <!-- NOTE -->
                    <div class="w-full">
                        <div class="flex flex-col">
                            <label for="note" class="font-bold text-gray-900">Note</label>
                            <textarea v-model="gear.notes" name="note" rows="4" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" ></textarea>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- GEAR TYPE SECTION -->
        <div class="w-full mx-auto my-6">
            <div class="w-full col-span-1 md:col-span-2 mt-4">
                <div @click="toggleSection('type')" class="w-full border-b mb-2 flex items-center gap-2 cursor-pointer">
                    <font-awesome-icon v-show="ui.sections.type.expanded" class="text-xs w-4" icon="fa-chevron-down" />
                    <font-awesome-icon v-show="!ui.sections.type.expanded" class="text-xs w-4" icon="fa-chevron-right" />
                    <div>
                        <span class="text-gray-900 text-xl" >Gear Type </span>
                    </div>
                </div>
                
                <div v-if="gear && gear != null" :class="ui.sections.type.expanded ? 'max-h-[999999px]' : 'max-h-0 overflow-hidden' " class=" px-4 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4 ">
                    
                    <!-- GEAR PARENT -->
                    <div v-if="gear.isBundleChild" class="w-full">
                        <div class="flex flex-col h-full justify-end">
                            <div class="pl-8 pr-3 text-sm text-gray-800 font-light">
                                <div class="flex align-center bg-slate-100 border border-slate-200 rounded px-2 py-1">
                                    <div><font-awesome-icon class="text-bestbuy-800 pr-2 " icon="fa-circle-info" /></div>
                                    <div>
                                        <div>This item is part of a bundle. </div>
                                        <div class="my-2">
                                            <div class="flex items-center justify-center hidden md:inline-flex gap-4">
                                                <button v-if="gear.bundleParent" @click="openEquipment(gear.bundleParent)" class="text-bestbuy-800 font-semibold px-2 py-1 rounded-md bg-slate-200 hover:bg-slate-300 focus:outline-none transition duration-200 ">
                                                    <font-awesome-icon class="text-bestbuy-800 text-xs cursor-pointer mr-1" icon="fa-external-link" />
                                                    View bundle
                                                </button>
                                                <button @click="removeFromBundle" class="text-bestbuy-800 font-semibold px-2 py-1 rounded-md bg-slate-200 hover:bg-slate-300">
                                                    <font-awesome-icon class="text-bestbuy-800 text-xs cursor-pointer mr-1" icon="fa-trash" />
                                                    Remove from bundle
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- GEAR TYPE -->
                    <div v-if="!gear.isBundleChild" class="w-full">
                        <div class="flex flex-col">
                            <label for="category" class="font-bold text-gray-900">What type of gear is this?</label>
                            <select v-model="ui.sections.type.selected" name="category" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                                <option v-for="opt in ui.sections.type.options" :key="opt">{{ opt }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- GEAR TYPE DESCRIPTION -->
                    <div v-if="!gear.isBundleChild" class="w-full">
                        <div class="flex flex-col h-full justify-end">
                            <div class="pl-8 pr-3 text-sm text-gray-800 font-light">
                                <div class="flex align-center bg-slate-100 border border-slate-200 rounded px-2 py-1">
                                    <div><font-awesome-icon class="text-bestbuy-800 pr-2 " icon="fa-circle-info" /></div>
                                    <div v-show="ui.sections.type.selected == 'Gear Item' ">
                                        A <span class="text-bestbuy-800 font-bold">Gear Item</span> is a reservable item which itself does not contain other reservable gear items. (Example: tripod, battery, HDMI cable)
                                    </div>
                                    <div v-show="ui.sections.type.selected == 'Bundle' ">
                                        A <span class="text-bestbuy-800 font-bold">Bundle</span> is a reservable item which is made up of other gear items. (Example: A case with a camera, lens, battery, and charging cable inside)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                     <!-- CHILD EQUIPMENT -->
                     <div v-if="!gear.isBundleChild" class="w-full col-span-1 md:col-span-2">
                        <div class="flex flex-col">
                            <label for="category" class="font-bold text-gray-900">Select bundled gear: </label>
                            <div class="v-hide-selected">
                                <v-select v-model="childGear" :options="store.equipment.items" :selectable="(gear) => isChildGearAvailable(gear)" label="name" placeholder="Bundled equipment" multiple>
                                    <template v-slot:option="option">
                                        <div class="w-full truncate">{{ option.name }}</div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="mx-1 my-2 text-sm  text-black flex flex-col h-full justify-center">
                                <div>
                                    <div v-for="gear in childGear" :key="gear._fsid" class="border-b">
                                        <div :class=" gear['_conflict'] ? 'border-l-red-300 bg-red-100 hover:bg-red-100' : 'border-l-slate-300 hover:bg-gray-100' " class="py-2 border-l-4 cursor-pointer flex justify-between items-center">
                                            <div class="flex items-center gap-2">
                                                <div v-if="gear.images && gear.images.length>0" :style="`background-image:url('${ gear.images[0].url }')`" class="mx-2 w-8 h-8 bg-center bg-no-repeat bg-cover rounded"></div>
                                                <div class="select-none">{{ gear.name }}</div>
                                            </div>
                                            <div @click="removeChildItem(gear)" class="px-2 py-2 mx-2 rounded w-fit flex items-center gap-1 select-none text-white bg-red-400">
                                                <font-awesome-icon icon="fa-x" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- GEAR IMAGES SECTION -->
        <div class="w-full mx-auto my-6">
            <div class="w-full col-span-1 md:col-span-2 mt-4">
                <div @click="toggleSection('images')" class="w-full border-b mb-2 flex items-center gap-2 cursor-pointer">
                    <font-awesome-icon v-show="ui.sections.images.expanded" class=" w-4" icon="fa-chevron-down" />
                    <font-awesome-icon v-show="!ui.sections.images.expanded" class=" w-4" icon="fa-chevron-right" />
                    <div>
                        <span class="text-gray-900 text-xl" >Images </span>
                        <span class="text-gray-400  font-medium italic">{{ '(' + gear.images.length + ( gear.images.length > 1 ? ')' : ')') }}</span>
                    </div>
                </div>
                
                <!-- IMAGES -->
                <div :class="ui.sections.images.expanded ? 'max-h-[9999px]' : 'max-h-0' " class="overflow-hidden px-4 flex flex-col gap-4 ">
                    <div v-for="img in gear.images" :key="img.url" class="flex flex-nowrap gap-4 pb-4 border-l-8">
                        <div class="flex flex-col justify-center items-center ml-2 gap-2">
                            <div :style="`background-image:url('${ img.url }')`" class="w-32 h-32 bg-center bg-no-repeat bg-cover"></div>
                            <div class="flex items-center justify-center md:hidden">
                                <button @click="removeImage(img)" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-2 py-1 font-medium text-white text-xs shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto transition duration-200">Delete</button>
                            </div>
                        </div>
                        <div class="grow grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4">
                            <!-- URL -->
                            <div class="col-span-2">
                                <div class="flex flex-col">
                                    <label for="image-title" class="font-bold text-gray-900">URL</label>
                                    <input v-model="img.url" name="image-title" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                                </div>
                            </div>
                            <!-- TITLE -->
                            <div class="col-span-2 md:col-span-1">
                                <div class="flex flex-col">
                                    <label for="image-title" class="font-bold text-gray-900">Title</label>
                                    <input v-model="img.title" name="image-title" type="text" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                                </div>
                            </div>
                             <!-- ORDER -->
                             <div class="col-span-2 md:col-span-1">
                                <div class="flex flex-col">
                                    <label for="image-title" class="font-bold text-gray-900">Order</label>
                                    <input v-model="img.order" name="image-title" type="number" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center hidden md:inline-flex">
                            <button @click="removeImage(img)" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-2 py-1 font-medium text-white text-sm shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto transition duration-200">Delete</button>
                        </div>
                    </div>

                    <!-- NEW IMAGE -->
                    <button @click="selectImage" type="button" class="inline-flex justify-center rounded-md border border-green-600 bg-green-600 px-2 py-1 font-medium text-white text-sm shadow-sm hover:bg-green-700 focus:outline-none mx-auto w-28 transition duration-200">Add Image</button>
                    <input type="file" @change="imageInputChange" id="gearimageinput" style="display:none" accept="image/*" />

                </div>
            </div>
        </div>

        


        <!-- SPACER -->
        <div class="h-16"></div>
        <!-- PAGE FOOTER -->
        <div class="px-6 py-3 flex justify-center items-center mt-8 border-t-2 fixed bottom-0 left-0 right-0 bg-slate-100">
            <button v-if="formState == 'edit'" @click="deleteGear" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto text-sm transition duration-200">Delete</button>
            <button @click="saveEdits" :disabled="gear.name.length == 0" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200 disabled:opacity-50">Save</button>
            <button @click="cancelEditGear(gear._fsid)" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200 ">Cancel</button>
        </div>

    </section>
</template>

<script>
import { store } from '../store/store.js';
import { Equipment } from '../utilities/utilities.js';
import EquipmentTags from '../components/EquipmentTags.vue';
import PageTitle from '../components/PageTitle.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from "vue-select";
import { equipmentStatus } from '../store/store.js'
import { getStorage, ref, uploadBytes } from "firebase/storage";
import QRScanner from "../components/QRScanner.vue";

export default {
    components: {
        EquipmentTags,
        PageTitle,
        flatPickr,
        vSelect,
        QRScanner
    },
    data() {
        return {
            barcodeScanner: null,
            store:store,
            statusDefs: equipmentStatus.values,
            gear: new Equipment(null),
            newTag:"",
            flatpicker: {
                isMounted:false,
                purchaseDateValue: new Date(),
                purchaseDate: {
                    config: {
                        enableTime: false,
                        //minDate: new Date(),
                        dateFormat: "m/d/Y",
                        defaultHour: 0,
                        defaultMinute: 0,
                        onChange: (selectedDates, dateStr, instance) => this.purchaseDateChange(selectedDates, dateStr, instance)
                    }
                }
            },
            ui: {
                showBarcodeScanner:false,
                sections:{
                    details:{
                        expanded: true
                    },
                    type:{
                        expanded: true,
                        options: ["Gear Item","Bundle"],
                        selected: 'Gear Item'
                    },
                    images:{
                        expanded: true
                    }
                }
            },
            childGear:[],
            formState: this.$route.params.id == 'new' ? 'new' : 'edit',
            liveItem: new Equipment(null) // On mount, store copy of server item
        }
    },
    methods: {
        toggleBarcodeScanner() {
            this.ui.showBarcodeScanner = !this.ui.showBarcodeScanner;
        },
        barcodeScanUpdate(bc) {
            console.log("barcodeScanUpdate :",bc)
            this.gear.barcode = bc;
            this.ui.showBarcodeScanner = false;
        },
        selectFilter(page,value) {
            let params = null;
            if (value) {
                params = {
                    id: value
                }
            }
            this.$router.push({
                name: page,
                params: params
            })
        },
        toggleSection(section) {
            this.ui.sections[section].expanded = !this.ui.sections[section].expanded;
        },
        async saveEdits() {
            let item = JSON.parse(JSON.stringify(this.gear));
            
            // Manually set values preflight
            item.purchaseDate = this.flatpicker.purchaseDateValue ? new Date(this.flatpicker.purchaseDateValue) : null;
            if (this.ui.sections.type.selected == 'Bundle') {
                item.bundleEquipment = this.childGear.map(_ => _._fsid);
                item.isBundleParent = true;
                item.isBundleChild = false;
            } else {
                item.bundleEquipment = [];
                item.isBundleParent = false;
            }
            
            // Determine if child items need to be updated
            // Add isBundleChild

            // Remove isBundleChild

            let gearId = this.gear._fsid;
            delete item._fsid;
            if (this.formState == 'edit' && gearId) {
                this.logSaveEvent("Edit Gear");
                store.utilities.updateRecord("equipment",gearId,item).then((res) => {
                    this.updateBundleChildren(gearId); // Update bundled children items
                    this.cancelEditGear(gearId);

                    // store.equipment.getEquipmentById(gearId).then(gear => {
                    //     // Update gear item in local store
                    //     let updatedGear = JSON.parse(JSON.stringify(new Equipment(gear)));
                    //     updatedGear['_fsid'] = gearId;
                    //     store.equipment.updateLocalEquipmentItem(gearId,updatedGear);
                    //     this.cancelEditGear(gearId);
                    // });
                    
                })
            } else if (this.formState == 'new') {
                //store.utilities.addRecord("equipment",item).then(() => {
                this.logSaveEvent("Create Gear");
                const res = await store.equipment.addEquipment(item);
                const gearId = res.id;
                this.updateBundleChildren(gearId); // Update bundled children items
                this.cancelEditGear(gearId);
                // store.equipment.getEquipmentById(gearId).then(gear => {
                //     // Update gear item in local store
                //     // let updatedGear = JSON.parse(JSON.stringify(new Equipment(gear)));
                //     // updatedGear['_fsid'] = gearId;
                //     // this.updateBundleChildren(gearId); // Update bundled children items
                //     // store.equipment.updateLocalEquipmentItem(gearId,updatedGear);
                //     // this.cancelEditGear(gearId);
                    
                // });
                
            }
        },
        deleteGear() {
            if (this.gear._fsid) {
                const confirmation = confirm("Are you sure you want to delete this gear item?")
                if (confirmation) {
                    store.utilities.deleteRecord("equipment",this.gear._fsid).then(() => {
                        // If this was a bundle, update child items
                        // Remove isBundleChild
                        if (this.liveItem.bundleEquipment) {
                            let removeAsChildItems = this.liveItem.bundleEquipment;
                            
                            const updateObjRem = {
                                isBundleChild: false,
                                bundleParent: ""
                            }
                            console.log("removeAsChildItems",removeAsChildItems,updateObjRem)
                            removeAsChildItems.forEach(itemId => {
                                store.utilities.updateRecord("equipment",itemId,updateObjRem)
                            })
                        }

                        this.$router.push({
                            name: "equipment",
                        })
                    })
                }
            }
        },
        cancelEditGear(fsid) {
            console.log(":: fx - cancelEditGear ::",fsid);
            if (fsid) {
                // Navigate to equipment details page
                this.$router.push({
                    name:"equipmentdetails",
                    params: {
                        id: fsid
                    }
                })
            } else {
                this.$router.push({
                    name:"equipment"
                })
            }
            // this.$router.back()
        },
        removeImage(img) {
            this.gear.images = this.gear.images.filter(_ => {
                return !(_.url == img.url && _.order == img.order)
            })
        },
        newImage(url) {
            let newOrder = 0;
            if (this.gear.images.length > 0) {
                newOrder = Math.max(...this.gear.images.map(_ => Number(_.order))) + 1;
            }
            this.gear.images.push({
                url:url,
                title:"",
                order: newOrder
            })
        },
        selectImage() {
            document.querySelector("#gearimageinput").click();
        },
        async imageInputChange(event) {
            const fileList = event.target.files;
            if (fileList && fileList.length != 0) {
                for (const file of fileList) {
                    const imgurl = URL.createObjectURL(file);

                    // Resize Image
                    const resizeblob = await this.resizeImage(imgurl);
                    const gearPath = this.gear._fsid ? this.gear._fsid : this.extractEquipmentIdFromUrl();

                    // Check that we have a gear id
                    if (!gearPath) {
                        // Log error event
                        store.analytics.logAppEvent({
                            uid: store.user.user.uid,
                            name: store.user.user.displayName,
                            email: store.user.user.email,
                            action: "Image Upload Error",
                            type: "Gear",
                            target: this.$route.params.id,
                            url: window.location.href,
                            datetime: new Date()
                        });

                        alert("No gear id found. Please refresh and try again.")
                        return;
                    }

                    const path = '/Gear/' + gearPath  + "/" + file.name + ".jpg";

                    const snapshot = await this.uploadImage(resizeblob,path);
                    const imageUrl = "https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/" + encodeURIComponent(snapshot.ref.fullPath) + '?alt=media';
                    this.newImage(imageUrl)

                }

            }
        },
        uploadImage(file,path) {
            const storage = getStorage();
            const storageRef = ref(storage,path);

            return uploadBytes(storageRef,file);

        },
        extractEquipmentIdFromUrl() {
            const url = window.location.href;
            const regex = /\/equipmentdetails\/([^\/]+)/;
            const match = url.match(regex);
            return match ? match[1] : null;
        },
        removeTag(tag) {
            this.gear.tags = this.gear.tags.filter(_ => {
                return !(_ == tag)
            })
        },
        addTag() {
            if (this.newTag && this.newTag.length>0 && !this.gear.tags.includes(this.newTag)) {
                this.gear.tags.push(this.newTag);
            }
            this.newTag = ""
        },
        purchaseDateChange(selectedDates, dateStr, instance) {

        },
        logSaveEvent(action) {
            // Log Event
            store.analytics.logAppEvent({
                uid: store.user.user.uid,
                name: store.user.user.displayName,
                email: store.user.user.email,
                action: action,
                type: "Gear",
                target: this.$route.params.id,
                url: window.location.href,
                datetime: new Date()
            });
        },
        printDetails() {
            console.log(this.gear);
            console.log("childGear",this.childGear);
            console.log("sections",this.ui.sections);
            if (this.gear.bundleParent) {
                // Get bundle item
                store.equipment.getEquipmentById(this.gear.bundleParent).then(bundle => {
                    console.log("Parent: ",bundle);
                    if (bundle && bundle.hasOwnProperty("bundleEquipment")) {
                        const bundleEquipment = bundle.bundleEquipment.filter(fsid => fsid != this.gear._fsid);
                        console.log("bundleEquipment: ",bundleEquipment);
                    }
                });
            }
        },
        removeChildItem(gear) {
            this.childGear = this.childGear.filter(_ => {
                return _['_fsid'] !== gear['_fsid']
            })
        },
        updateBundleChildren(fsid) {
            // Determine if child items need to be updated
            const newBundleChildrenIDs = this.childGear.map(_ => _['_fsid']);
            // Add isBundleChild
            let addAsChildItems = newBundleChildrenIDs.filter(_fsid => this.liveItem.bundleEquipment.indexOf(_fsid) == -1);
            
            const updateObjAdd = {
                isBundleChild: true,
                bundleParent: fsid
            }
            console.log("addAsChildItems",addAsChildItems,updateObjAdd)
            addAsChildItems.forEach(itemId => {
                store.utilities.updateRecord("equipment",itemId,updateObjAdd)
            })

            // Remove isBundleChild
            let removeAsChildItems = this.liveItem.bundleEquipment.filter(curChildID => newBundleChildrenIDs.indexOf(curChildID) == -1);
            
            const updateObjRem = {
                isBundleChild: false,
                bundleParent: ""
            }
            console.log("removeAsChildItems",removeAsChildItems,updateObjRem)
            removeAsChildItems.forEach(itemId => {
                store.utilities.updateRecord("equipment",itemId,updateObjRem)
            })
        },
        isChildGearAvailable(option) {
            const childGearIds = this.childGear.map(_ => _['_fsid']);
            return (option['_fsid'] !== this.gear['_fsid'] && !option.isBundleParent && !option.isBundleChild && childGearIds.indexOf(option['_fsid']) === -1)
        },
        resizeImage(url,
                options = {
                    maxWidth: 1600,
                    maxHeight: 1600,
                    contentType: "image/jpeg",
                    quality: 0.9
                }) {
                
                const calculateSize = (img) => {
                    let w = img.width,
                        h = img.height;
                    if (w > h) {
                        if (w > options.maxWidth) {
                        h = Math.round((h * options.maxWidth) / w);
                        w = options.maxWidth;
                        }
                    } else {
                        if (h > options.maxHeight) {
                        w = Math.round((w * options.maxHeight) / h);
                        h = options.maxHeight;
                        }
                    }
                    return [w, h];
                };
                
                return new Promise(resolve => {
                    var img = new Image;
                    img.onload = function() {
                        const [newWidth, newHeight] = calculateSize(
                            img,
                            options.maxWidth,
                            options.maxHeight
                        );
                        console.log("newWidth, newHeight",newWidth, newHeight)
                        const canvas = document.createElement("canvas");
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext("2d");

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);
                        console.log(":: img.onload ::");

                        const resultUrl = canvas.toDataURL(options.contentType, options.quality),
                        result = {
                            url: resultUrl,
                            contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
                            b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
                        };

                        canvas.toBlob(
                            (blob) => {
                                result.size = blob.size;
                                resolve(blob,result);
                            },
                            options.contentType,
                            options.quality
                        );
                    }
                    img.src = url;
                })
        },
        openEquipment(_fsid) {
            this.$router.push({
            name:"equipmentdetails",
            params: {
              id: _fsid
            }
          })
        },
        removeFromBundle() {
            const confirmation = confirm("Are you sure you want to remove this from the bundle?")
            if (confirmation) {
                this.logSaveEvent("Edit Gear - Remove from bundle");
                
                const gearId = this.gear._fsid;

                // Update current item
                const updateObj = {
                    isBundleChild: false,
                    bundleParent: ""
                }
                store.utilities.updateRecord("equipment",gearId,updateObj).then((res) => {
                    store.equipment.getEquipmentById(gearId).then(gear => {
                        // Update gear item in local store
                        let updatedGear = JSON.parse(JSON.stringify(new Equipment(gear)));
                        updatedGear['_fsid'] = gearId;
                        store.equipment.updateLocalEquipmentItem(gearId,updatedGear);
                        this.gear = JSON.parse(JSON.stringify(updatedGear));
                        // this.cancelEditGear(gearId);
                    });
                });

                // Update bundle item
                if (this.gear.bundleParent) {
                    // Get bundle item
                    store.equipment.getEquipmentById(this.gear.bundleParent).then(bundle => {
                        console.log(bundle);
                        if (bundle && bundle.hasOwnProperty("bundleEquipment")) {
                            bundle.bundleEquipment = bundle.bundleEquipment.filter(fsid => fsid != gearId);
                            const updateBundleObj = {bundleEquipment:bundle.bundleEquipment}
                            store.utilities.updateRecord("equipment",this.gear.bundleParent,updateBundleObj).then((res) => {
                                store.equipment.updateLocalEquipmentItem(this.gear.bundleParent,bundle);
                            });
                        }
                    });
                }
            }
        }
    },
    computed: {
        categories() {
            return store.equipment.categories;
        },
        subcategories() {
            let results = [];
            const matchedCategory = this.categories.find((category) => {
                return category.category == this.gear.category;
            });
            if (matchedCategory != undefined) {
                results = matchedCategory.subcategories;
            }
            return results;
        }
    },
    mounted() {
        console.log(":: EDIT EQUIPMENT, ID - ",this.$route.params.id)
        const gid = this.$route.params.id;
        let gearItem = undefined;
        if (gid != 'new') {
            // Check if we already have equipment
            if (store.equipment.items.length> 0) {
                // We have equipment
                gearItem = store.equipment.items.find((item) => {
                    return item._fsid == gid;
                });
                if (gearItem != undefined) {
                    this.gear = new Equipment(gearItem);
                    // Set Purchase Date
                    this.flatpicker.purchaseDateValue = this.gear.purchaseDate ? new Date(this.gear.purchaseDate.seconds * 1000) : null;
                    // Set Gear Type
                    this.ui.sections.type.selected = this.gear.isBundleParent ? "Bundle" : "Gear Item";
                    // Store live (server) item
                    this.liveItem = JSON.parse(JSON.stringify(this.gear));
                    // Set childGear
                    if (this.gear.isBundleParent) {
                        this.childGear = store.equipment.items.filter(gear => {
                            return this.gear.bundleEquipment.indexOf(gear['_fsid']) !== -1;
                        })
                    } else {
                        // Not a parent gear item
                        this.childGear = [];
                    }
                }
            } else {
                // We dont' have equipment, get then subscribe
                store.equipment.getAllEquipment(true).then(results => {
                    gearItem = results.find((item) => {
                        return item._fsid == gid;
                    });
                    this.gear = new Equipment(gearItem);
                })
                store.equipment.subscribeToChanges(
                    "editGear",
                    (gearItems) => {
                        console.log(":: Subscribed to equipment -- ",gearItems)
                        let gearItem = undefined;
                        if (gearItems) {
                            gearItem = gearItems.find((item) => {
                                return item._fsid == gid;
                            });
                        }
                        if (gearItem != undefined) {
                            this.gear = new Equipment(gearItem);
                            // Set Purchase Date
                            this.flatpicker.purchaseDateValue = this.gear.purchaseDate ? new Date(this.gear.purchaseDate.seconds * 1000) : null;
                            // Set Gear Type
                            this.ui.sections.type.selected = this.gear.isBundleParent ? "Bundle" : "Gear Item";
                            // Store live (server) item
                            this.liveItem = JSON.parse(JSON.stringify(this.gear));
                             // Set childGear
                            if (this.gear.isBundleParent) {
                                this.childGear = store.equipment.items.filter(gear => {
                                    return this.gear.bundleEquipment.indexOf(gear['_fsid']) !== -1;
                                })
                            } else {
                                // Not a parent gear item
                                this.childGear = [];
                            }
                        }
                    }
                )
            }
        }
        this.flatpicker.isMounted = true;
        store.equipment.getEquipmentCategories();
    }
}
</script>

<style>

</style>
