<template>
    <div class="px-4 py-4 bg-gray-50 rounded shadow group/item"> <!--bg-gray-200 rounded p-2 border-l-gray-100 --> 
        <div class="flex flex-col gap-4">
            <div class="flex justify-between">
                <div class="w-full flex gap-2 items-center">
                    <div class="w-12 h-12 ">
                        <div :style="`background-image:url('https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/UserProfileImages%2F${ feedback.authorid }%2Fprofileimage.jpg?alt=media'),url(${ store.user.defaultUserImg })`" name="userprofileimage" class="w-12 h-12 rounded-full bg-center bg-cover"></div>
                    </div>
                    <div>
                        <div class="">{{ feedback.authorname }}</div>
                    </div>
                </div>
                <div class="text-gray-600 font-light text-sm min-w-fit text-right">
                    <div>{{feedback._created}}</div>
                    <div v-if="(store.user.userRoles.indexOf('admin') != -1) || (store.user.user['uid'] == feedback.authorid)" @click="deleteFeedback" class="hidden group-hover/item:inline text-gray-400 cursor-pointer">
                        <font-awesome-icon icon="fa-trash-can" />
                    </div>
                </div>
            </div>
            
            <div class="w-full italic font-light">{{ feedback.comment }}</div>

            <!-- FEEDBACK IMAGES -->
            <div class="flex flex-wrap gap-2">
                <div v-for="(image,index) in feedback.images" @click="thumbnailClick(index)" :key="image.name" class="group/image cursor-pointer">
                    <div :style="`background-image:url('${buildImageUrl(image.bucket,image.fullPath)}')`" class="w-24 h-24 bg-gray-200 bg-cover bg-center transition duration-200 ease-in-out group-hover/image:scale-[1.04] relative"></div>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
    export default {
        props: ['feedback','store'],
        emits: ['update','imageClick'],
        methods:{
            buildImageUrl(bucket,fullPath) {
                let newPath = "";
                let base = "https://firebasestorage.googleapis.com/v0/b/"
                if (bucket,fullPath) {
                    newPath = base + bucket + "/o/" + encodeURIComponent(fullPath) + "?alt=media"
                }
                return newPath;
            },
            async deleteFeedback() {
                const confirmation = confirm("Are you sure you want to delete this feedback?");
                if (confirmation) {
                    await this.store.equipment.deleteEquipmentFeedback(this.feedback._fsid);
                    this.$emit('update');
                }
            },
            thumbnailClick(idx) {
                let _feedback = JSON.parse(JSON.stringify(this.feedback));
                delete _feedback.images;
                let _images = this.feedback.images.map(item => {
                    item['_feedback'] = _feedback;
                    return item;
                });

                this.$emit('imageClick',idx,_images)
            }
        },
        computed:{
            
        },
        mounted() {
            
        },
        unmounted() {
            
        }
        
    }

</script>

<style>

</style>