<template>
  <div>
    <div>
        <div class="flex gap-4">
            <div :class=" note.parent == '' ? 'w-12 h-12' : 'w-8 h-8' ">
                <div :style="`background-image:url('https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/UserProfileImages%2F${ note.author }%2Fprofileimage.jpg?alt=media'),url(${ user.defaultUserImg })`" name="userprofileimage" :class=" note.parent == '' ? 'w-12 h-12' : 'w-8 h-8' " class="rounded-full bg-center bg-cover cursor-pointer"></div>
            </div>
            <div>
                <!-- COMMENT BUBBLE -->
                <div class="flex flex-col py-2 px-4 justify-center rounded bg-gray-100 relative min-w-[320px]">
                    <div class="font-medium">{{ note.authorName }}</div>
                    <div @click="printOut(note)" class="">{{ note.note }}</div>
                </div>
                <!-- LIKE & REPLY -->
                <div class="flex flex-wrap gap-4 px-2 text-gray-600 dark:text-gray-400">
                    <Tooltip @click="likeNote(note)" :text="noteLikes" :classList="note.likes.includes(user.user.uid) ? 'text-bestbuy-500 font-bold' : 'font-medium'">
                        <span>{{ likeText }}</span>
                    </Tooltip>

                    <div v-if="note.parent ==''" @click="openReply" class="text-sm font-medium cursor-pointer">Reply</div>
                    <div v-if="user.user.uid == note.author" @click="deleteNote(note)" class="text-sm font-medium cursor-pointer">Delete</div>
                    <div class="text-sm ">{{ friendifyTime(new Date(note.created.seconds * 1000)) }}</div>
                </div>
                <!-- REPLY -->
                <div v-if="showReply" class="mt-4">
                    <CommentBox @submit="(msg) => replyNote(msg)" :user="user"></CommentBox>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>

import friendlyTime from 'friendly-time';
import CommentBox from '../components/CommentBox.vue';
import Tooltip from '../components/Tooltip.vue';

export default {
    props: ['note','user'],
    emits: ['reply','like','delete'],
    components: {
        CommentBox,
        Tooltip
    },
    data() {
        return {
            showReply: false
        }
    },
    methods: {
        printOut(val) {
            console.log(val)
        },
        likeNote(cmt) {
            this.$emit('like',cmt);
        },
        replyNote(reply) {
            this.$emit('reply',this.note, reply);
            this.showReply = false;
        },
        deleteNote(cmt) {
            const res = confirm('Are you sure you want to delete this comment?');
            if (res) {
                this.$emit('delete',cmt);
            }
        },
        openReply() {
            this.showReply = true;
        },
        friendifyTime(date) {
            return friendlyTime(date);
        }
    },
    computed: {
        noteLikes() {
            return (this.note && this.note.hasOwnProperty('_likes')) ? this.note._likes.map(_ => _.name).join("\n") : ''
        },
        likeText() {
            if (this.note && this.note.hasOwnProperty('likes')) {
                const likes = this.note.likes;
                if (likes == null || likes.length == 0) {
                    return 'Like'
                } else if (likes.length == 1) {
                    return '1 Like'
                } else {
                    return likes.length + ' Likes'
                }
            } else {
                return ''
            }
        }
    }
}

</script>

<style scoped>
/* setup tooltips */
.tooltip {
    position: relative;
}
.tooltip:before,
.tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
}
.tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0,0,0,.75); 
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 20px;
    left: 20px;
    width: 0;
}
.tooltip:before {
    background: rgba(0,0,0,.75);
    border-radius: 2px;
    color: #fff;
    content: attr(data-title);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    padding: 6px 10px;
    top: 26px;
    white-space: pre-line;
    width: 160px;
}

/* the animations */
/* fade */
.tooltip.fade:after,
.tooltip.fade:before {
    transform: translate3d(0,-10px,0);
    transition: all .15s ease-in-out;
}
.tooltip.fade:hover:after,
.tooltip.fade:hover:before {
    opacity: 1;
    transform: translate3d(0,0,0);
}

</style>