import moment from 'moment';

export class FirebaseDate {
    constructor(item) {
        this.nanoseconds = item && item.hasOwnProperty("nanoseconds") ? item.nanoseconds : 0;
        this.seconds = item && item.hasOwnProperty("seconds") ? item.seconds : 0;
    }
}

export class ApplicationUser {
    constructor(item) {
        this.id = item && item.hasOwnProperty("id") ? item.id : "";
        this.name = item && item.hasOwnProperty("name") ? item.name : "";
        this.profileImg = item && item.hasOwnProperty("profileImg") ? item.profileImg : "";
    }
}

export class Reservation {
    constructor(item) {
        // Conditionally build Reservation object
        const createdDate = (item && item.hasOwnProperty("createdDate") && item.createdDate.hasOwnProperty("seconds")) ? item.createdDate : null;
        const modifiedDate = (item && item.hasOwnProperty("modifiedDate") && item.modifiedDate.hasOwnProperty("seconds")) ? item.modifiedDate : null;
        const reservationStart = (item && item.hasOwnProperty("reservationStart") && item.reservationStart && item['reservationStart'].hasOwnProperty("seconds")) ? item.reservationStart : null;
        const reservationEnd = (item && item.hasOwnProperty("reservationEnd") && item.reservationEnd && item['reservationEnd'].hasOwnProperty("seconds")) ? item.reservationEnd : null;
        const reservationStarted = (item && item.hasOwnProperty("reservationStarted") && item.reservationStarted && item['reservationStarted'].hasOwnProperty("seconds")) ? item.reservationStarted : null;
        const reservationEnded = (item && item.hasOwnProperty("reservationEnded")  && item.reservationEnded && item['reservationEnded'].hasOwnProperty("seconds")) ? item.reservationEnded : null;
        
        this.author = new ApplicationUser(item ? item['author'] : null);
        this.createdDate = createdDate ? new FirebaseDate(createdDate) : null;
        this.editor = new ApplicationUser(item ? item['editor'] : null);
        this.end = reservationEnd ? new Date(item.reservationEnd.seconds * 1000) : null;
        this.equipment = (item && item['equipment']) ? item['equipment'] : [];
        this.isComplete = item ? item['isComplete'] : false;
        this.modifiedDate = modifiedDate ? new FirebaseDate(modifiedDate) : null;
        this.owner = new ApplicationUser(item ? item['owner'] : null);
        this.project = item ? item['project'] : "";
        this.projectId = item ? item['projectId'] : "";
        this.projectName = item ? item['projectName'] : "";
        this.reservationEnd = reservationEnd ? new FirebaseDate(reservationEnd) : null;
        this.reservationEnded = reservationEnded ? new FirebaseDate(reservationEnded) : null;
        this.reservationStart = reservationStart ? new FirebaseDate(reservationStart) : null;
        this.reservationStarted = reservationStarted ? new FirebaseDate(reservationStarted) : null;
        this.status = (item && item.hasOwnProperty("status") && item['status'].length>0) ? item.status.toLowerCase() : '';
        this.start = reservationStart ? new Date(item.reservationStart.seconds * 1000) : null;
        this.title = item ? `(${item['project'] ? item['project'] : ''}) ${item.projectName ? item.projectName : ''}` : '';
        this._fsid = item ? item['_fsid'] : "";
        this._mend = reservationEnd ? moment(item.reservationEnd.seconds * 1000) : null;
        this._mstart = reservationStart ? moment(item.reservationStart.seconds * 1000) : null;
        this._fpstart = reservationStart ? moment(reservationStart.seconds * 1000).format('YYYY-MM-DD') : null;
        this._fpend = reservationEnd ? moment(reservationEnd.seconds * 1000).format('YYYY-MM-DD') : null;
        // this._fpstart = reservationStart ? moment(reservationStart.seconds * 1000).format('MM/DD/YYYY H:mm') : null;
        // this._fpend = reservationEnd ? moment(reservationEnd.seconds * 1000).format('MM/DD/YYYY H:mm') : null;
    }
}

export class ReservationDoc {
    constructor(item) {
        // Conditionally build Reservation object
        this.author = {
            id: (item && item['author']) ? item.author['id'] : "",
            name: (item && item['author']) ? item.author['name'] : "",
            profileImg: (item && item['author']) ? item.author['profileImg'] : ""
        };
        this.createdDate = (item && item['createdDate']) ? new Date(item.createdDate['seconds'] * 1000) : null;
        this.editor = {
            id: (item && item['editor']) ? item.editor['id'] : "",
            name: (item && item['editor']) ? item.editor['name'] : "",
            profileImg: (item && item['editor']) ? item.editor['profileImg'] : ""
        };
        this.equipment = (item && item['equipment']) ? item['equipment'] : [];
        this.modifiedDate = new Date();
        this.owner = {
            id: (item && item['owner']) ? item.owner['id'] : "",
            name: (item && item['owner']) ? item.owner['name'] : "",
            profileImg: (item && item['owner']) ? item.owner['profileImg'] : ""
        };
        this.project = item ? item['project'] : "";
        this.projectId = item ? item['projectId'] : "";
        this.projectName = item ? item['projectName'] : "";
        this.reservationEnd = (item && item['reservationEnd']) ? new Date(item.reservationEnd['seconds'] * 1000) : null;
        this.reservationEnded = (item && item['reservationEnded']) ? new Date(item.reservationEnded['seconds'] * 1000) : null;
        this.reservationStart = (item && item['reservationStart']) ? new Date(item.reservationStart['seconds'] * 1000) : null;
        this.reservationStarted = (item && item['reservationStarted']) ? new Date(item.reservationStarted['seconds'] * 1000) : null;
        this.status = (item && item['status']) ? item["status"] : "";
    }
}

export class Equipment {
    constructor(item) {
        // Conditionally build Reservation object
        const purchaseDate = (item && item.hasOwnProperty("purchaseDate") && item.purchaseDate && item.purchaseDate.hasOwnProperty("seconds")) ? item.purchaseDate : null;
        this.barcode = (item && item['barcode']) ? item['barcode'] : '';
        this.baseLocation = (item && item['baseLocation']) ? item['baseLocation'] : '';
        this.bundleEquipment = (item && item["bundleEquipment"]) ? item["bundleEquipment"] : [];
        this.bundleParent = (item && item['bundleParent']) ? item['bundleParent'] : '';
        this.category = (item && item['category']) ? item['category'] : '';
        this.description = (item && item['description']) ? item['description'] : '';
        this.equipmentId = (item && item['equipmentId']) ? item['equipmentId'] : '';
        this.images = (item && item['images']) ? item['images'] : [new Image];
        this.userimages = (item && item['userimages']) ? item['userimages'] : [];
        this.isBundleParent = (item && item['isBundleParent']) ? true : false;
        this.isBundleChild = (item && item['isBundleChild']) ? true : false;
        this.name = (item && item['name']) ? item['name'] : '';
        this.notes = (item && item['notes']) ? item['notes'] : '';
        this.purchasePrice = (item && item['purchasePrice']) ? item['purchasePrice'] : 0;
        this.purchaseDate = purchaseDate ? new FirebaseDate(purchaseDate) : null;
        this.serialNumber = (item && item['serialNumber']) ? item['serialNumber'] : '';
        this.status = (item && item['status']) ? item['status'] : 'Active';
        this.subcategory = (item && item['subcategory']) ? item['subcategory'] : '';
        this.tags = (item && item['tags']) ? item['tags'] : [];
        this._fsid = (item && item['_fsid']) ? item['_fsid'] : '';
    }
}

export class Image {
    constructor(item) {
        this.url = (item && item['url']) ? item['url'] : 'https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/Assets%2Fnoimage.jpg?alt=media';
        this.title = (item && item['title']) ? item['title'] : '';
        this.order = (item && item['order']) ? item['order'] : '';
    }
}

export class UserFeedback {
    constructor(item) {
        /* 
        {
            equipment: "VuSRBxU1bEL7HOJKx0qC",
            authorid:"",
            authorname:"",
            editorid:"",
            editorname:"",
            comment: "This item is worn",
            created: new Date(),
            modified: new Date(),
            images: [
                name: "Sample02.png",
                fullPath: "Gear/VuSRBxU1bEL7HOJKx0qC/Sample02.png",
                bucket: "bby-asset-tracker.appspot.com",
                size: 9
            ],
            likes:[],
            replies:[]
        }
        */
        const created = (item && item.hasOwnProperty("created") && item.created && item.created.hasOwnProperty("seconds")) ? item.created : (item && item.hasOwnProperty("created") ? item.created : null);
        const modified = (item && item.hasOwnProperty("modified") && item.modified && item.modified.hasOwnProperty("seconds")) ? item.modified : (item && item.hasOwnProperty("modified") ? item.modified : null);
        this.created = created;
        this.modified = modified;
        this.equipment = (item && item['equipment']) ? item['equipment'] : "";
        this.authorid = (item && item['authorid']) ? item['authorid'] : "";
        this.authorname = (item && item['authorname']) ? item['authorname'] : "";
        this.editorid = (item && item['editorid']) ? item['editorid'] : "";
        this.editorname = (item && item['editorname']) ? item['editorname'] : "";
        this.comment = (item && item['comment']) ? item['comment'] : "";
        this.images = (item && item['images']) ? item['images'] : [];
        this.likes = (item && item['likes']) ? item['likes'] : [];
        this.replies = (item && item['replies']) ? item['replies'] : [];
        
    }
}

