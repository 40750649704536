<template>
    <section class="max-w-screen-2xl mx-auto px-4">
      <div class="py-4 flex justify-between items-center ">
        <PageTitle :title="'App Usage'"></PageTitle>
      </div>
      <!-- HR -->
      <div class="border-b-2 border-gray-300"></div>

      <!-- LOADING -->
      <div v-if="isLoading" class="py-4 px-2 sm:px-4 italic font-light text-gray-500">loading...</div>

      <div v-else>
        <!-- USAGE BY USER -->
        <div class="py-4">
          <div v-for="usage in usageByUser" :key="usage.name">
              <div class="font-medium">{{ usage.name }} <span class="text-xxs">({{ usage.entries.length }})</span></div>
          </div>
        </div>

        <!-- ACTION TYPE FILTER -->
        <div class="py-4">
          <label for="action-type" class="font-bold text-gray-900">Action</label>
          <select v-model="actionType" @change="updateFilter" name="action-type" class="form-control mt-1 block px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            <option v-for="action in actions" :key="action" :value="action">{{ action }}</option>
          </select>
        </div>

        <!-- LOGS TABLES -->
        <table class="w-full border">
          <tr>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">Date</th>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">Name</th>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">Action</th>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">Target</th>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">User Agent</th>
              <th class="text-left px-2 py-1 bg-bestbuy-800 text-white">Version</th>
          </tr>
          <tr v-for="log in results" :key="log._fsid" class="">
              <td class="px-2">
                <div>{{ moment(log.datetime.seconds*1000).format('MMM DD, YYYY') }}</div>
                <div class="text-xxs"> ({{ moment(log.datetime.seconds*1000).format('hh:mm A') }})</div>
              </td>
              <td class="px-2">{{ log.name ? log.name : log.email }}</td>
              <td class="px-2">{{ log.action }}</td>
              <td class="px-2">{{ log.target }}</td>
              <td class="px-2">{{ getUserAgentString(log)}}</td>
              <td class="px-2">{{ log["appversion"] }}</td>
          </tr>
        </table>
      </div>

    </section>
  </template>
  
    <style scoped>
        table tr:nth-of-type(2n) td {
            background:#f2f2f2;
        }
    </style>

  <script>
    import { store } from '../store/store.js'
    import PageTitle from '../components/PageTitle.vue';
    import moment from 'moment';

    export default {
      components: {
        PageTitle
      },
      data() {
        return {
          isLoading: true,
          usageByUser:[],
          results:[],
          moment: moment,
          actionType: 'All',
          actions: ['All','App load','View','Create','Edit','Edit Gear','Create Gear']
        }
      },
      methods: {
        async getStats() {
            this.results = await store.analytics.getStats(this.actionType);
            const names = [... new Set(this.results.map(_ => _.email ).filter(Boolean))].sort();
            names.forEach(usr => {
                this.usageByUser.push({
                    name: usr,
                    entries: this.results.filter(res => res.email == usr)
                })
            });
            this.isLoading = false;
            console.log("Usage",this.usageByUser)
        },
        getUserAgentString(log) {
          let arr = [];
          arr.push(log['ua_browser']);
          arr.push(log['ua_os']);
          arr.push(log['ua_platform']);
          return arr.filter(Boolean).join(", ")
        },
        updateFilter() {
          this.results = [];
          this.usageByUser = [];
          this.getStats();
        }
      },
      mounted() {
        // Fetch reservations if empty
        this.getStats();
      }
    }
  </script>