<template>
  <section class="max-w-screen-2xl mx-auto px-4">
    <div class="flex items-center justify-between py-4">
      <PageTitle :title="'Schedule'" @click="print"></PageTitle>
      <button @click="openNewForm" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
        <font-awesome-icon class="mr-2" icon="fa-plus" />
        <span class="hidden sm:inline">New Reservation</span>
      </button>
      <button @click="openNewForm" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
        <font-awesome-icon class="text-black text-2xl absolute" icon="fa-plus" />
      </button>
    </div>
    <!-- HR -->
    <div class="border-b-2 border-gray-300"></div>
    <!-- CONTROL BAR -->
    <div class="flex justify-between items-center py-4 gap-1">
      <!-- SEARCH & FILTERS -->
      <div class="flex md:gap-4 w-full">
        <input type="text" v-model="searchQuery" class="form-control block w-full md:max-w-md px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" placeholder="Search schedule" />
        <Menu v-show="ui.viewType == 'list'" :options="filter.options" @menu-update="menuUpdate" />
      </div>
      <!-- VIEW TYPE -->
      <div class="flex gap-1 md:gap-4">
        <div class="text-xl px-2 py-1 rounded cursor-pointer " :class="ui.viewType == 'calendar' ? 'text-white bg-bestbuy-900 hover:bg-bestbuy-900' : 'text-bestbuy-900 bg-white hover:bg-gray-100'" @click="selectView('calendar')"  title="Grid view">
          <font-awesome-icon icon="fa-calendar-days" />
        </div>
        <div class="text-xl px-2 py-1 rounded cursor-pointer " :class="ui.viewType == 'list' ? 'text-white bg-bestbuy-900 hover:bg-bestbuy-900' : 'text-bestbuy-900 bg-white hover:bg-gray-100'" @click="selectView('list')" title="List view">
          <font-awesome-icon icon="fa-list" />
        </div>
      </div>
    </div>
    
    <div class="">
      <!-- CALENDAR -->
      <div v-if="ui.viewType == 'calendar' && eventsLoaded">
        <FullCalendar ref="fullcalendar" :options="calendarOptions" />
      </div>
      <!-- LIST -->
      <div v-show="ui.viewType == 'list'" class="">
        <ScheduleTable :reservations="listViewEvents" />
        <div v-if="listViewEvents.length == 0" class="px-2 sm:px-4 italic font-light text-gray-500">It doesn't look like there are any current or future reservations.</div>
      </div>
    </div>
    <!-- RESERVATION Details -->
    <ReservationDetails :isOpen="forms.showDetails" :closeForm="closeDetailsForm" :reservation="selectedReservation" />

  </section>
</template>

<script>
  import { store } from '../store/store.js'
  import '@fullcalendar/core/vdom' // solves problem with Vite
  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import ScheduleTable from '../components/ScheduleTable.vue'
  import NewReservation from '../components/NewReservation.vue'
  import ReservationDetails from '../components/ReservationDetails.vue'
  import PageTitle from '../components/PageTitle.vue'
  import Menu from '../components/Menu.vue'

  const ls = require('local-storage');

  export default {
    components: {
      FullCalendar,
      ScheduleTable,
      NewReservation,
      ReservationDetails,
      PageTitle,
      Menu
    },
    data() {
      return {
        ui:{
          viewType: ls('ab-schedule-view') ? ls('ab-schedule-view') : 'calendar'
        },
        searchQuery: "",
        eventsLoaded: false,
        selectedReservation:null,
        forms:{
          showNew:false,
          showDetails:false
        },
        filter: {
          menuValue:"active",
          options: [{
            name:"Active",
            value: "active"
          },
          {
            name:"Past",
            value: "past"
          }]
        }
      }
    },
    methods: {
      print() {
        console.log(this.filteredEvents);
      },
      selectView(view) {
        this.ui.viewType = view;
        ls.set('ab-schedule-view',view);
      },
      openNewForm() {
        this.$router.push({name:"newreservation"});
        //this.forms.showNew = true;
      },
      closeNewForm() {
        this.forms.showNew = false;
      },
      selectReservation(reservation) {
          this.selectedReservation = reservation;
          this.forms.showDetails = true;
      },
      closeDetailsForm() {
          this.selectedReservation = null;
          this.forms.showDetails = false;
      },
      menuUpdate(val) {
        this.filter.menuValue = val;
      },
    },
    computed:{
      filteredEvents() {
        return store.reservations.items.filter(item => {
          // Filter based on search bar
          const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
          return search == '' ||
            (item.project && item.project.toLowerCase().includes(search)) || 
            (item.projectName && item.projectName.toLowerCase().includes(search))
        });
      },
      listViewEvents() {
        // Show only current and future reservations
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return store.reservations.items.filter((item) => {
          if (this.filter.menuValue == "past") {
            return item.end <= yesterday
          } else {
            return item.end >= yesterday
          }
        }).filter(item => {
          // Filter based on search bar
          const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
          return search == '' ||
            (item.project && item.project.toLowerCase().includes(search)) || 
            (item.projectName && item.projectName.toLowerCase().includes(search))
        }).sort((a,b) => {
          if (this.filter.menuValue == "past") {
            return a.start <= b.start ? 1 : -1;
          } else {
            return a.start >= b.start ? 1 : -1;
          }
        })
      },
      calendarOptions() {
        return {
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          events: this.filteredEvents.map(event => {
            // Set all day
            // Add 1 day to display so the last day will show on calendar (if start date != end date)
            event["allDay"] = true;
            event["end"] = (event.start.valueOf() != event.end.valueOf() && event.end && event.end.getDate) ? new Date(event.end.valueOf()).setDate(event.end.getDate() + 1) : event["end"];
            return event;
          }),
          eventClick: (info) => {
            console.log(info.event.extendedProps)
            this.selectReservation(info.event.extendedProps)
          },
          eventContent: (item) => { 
            return {
              html: 
              `<div class='flex gap-2 p-1 items-center'>
                <div class="rounded-full w-8 h-8 bg-center bg-cover" style="min-width:32px;min-height:32px;background-image: url('${ item.event.extendedProps.owner.profileImg ? item.event.extendedProps.owner.profileImg : store.user.defaultUserImg }');" title="${ item.event.extendedProps.owner.name }" ></div>
                <div class="overflow-hidden truncate">${ item.event.title }</div>
              </div>`
            }
          },
          headerToolbar: {
            end:'prev,next'
          },
          height:'auto',
          eventBackgroundColor: "#DEE7F0",//"#00be78",//"#46be00", // fallback bg color
          eventBorderColor: "#F1F5F9",
          eventTextColor: "#333"
        }
      }
    },
    mounted(){
      // Log Event
      store.analytics.logAppEvent({
            uid: store.user.user.uid,
            name: store.user.user.displayName,
            email: store.user.user.email,
            action: "View",
            type: "Schedule",
            target: "Schedule",
            url: window.location.href,
            datetime: new Date()
        });

      this.calendarOptions.events = store.reservations.items
      this.eventsLoaded = true;

      // Subscribe to event changes
      store.reservations.subscribeToChanges('fullCalendar',() => {
        console.log("Rerender FullCalendar, Events changed")
        this.calendarOptions.events = this.filteredEvents;//store.reservations.items
        this.eventsLoaded = true;
      })
     
    },
    unmounted() {
      store.reservations.unsubscribeToChanges('fullCalendar')
    }
  }
</script>

<style>
  /* Full Calendar */
  :root {
    --fc-button-text-color: rgb(0,0,0);
    --fc-button-bg-color: #fff;
    --fc-button-border-color: #2C3E50;
    --fc-button-hover-bg-color: #e5e7eb;
    --fc-button-hover-border-color: #2C3E50;
    --fc-button-active-bg-color: #e5e7eb;
    --fc-button-active-border-color: #2C3E50;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom:.2rem;
  }
  .fc-col-header-cell {
    background-color: #0046BE;
    color:#FFFFFF;
  }
  .fc .fc-button-primary:focus, .fc .fc-button:focus {
    box-shadow: none!important;
  }
  .fc-event-title {
    font-weight: 600;
  }
  .fc-daygrid-block-event {
    cursor: pointer;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }
  .fc-daygrid-block-event:hover {
    background-color:#cad5e0!important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background:#f2f2f2;
  }

</style>