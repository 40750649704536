<template>
    <section class="max-w-screen-2xl mx-auto px-4 flex flex-col items-center">
      <div class="py-4 ">
        <div class="text-2xl md:text-3xl font-bold">
          Forgot Password
        </div>
      </div>
      <div class="w-full max-w-xs mt-4">
        <div class="grid grid-cols-12 gap-6">
          <div v-if="!submitted" class="col-span-12">
            <label for="user-email" class="block font-medium text-gray-700">Email</label>
            <input type="email" v-model="email" @keyup.enter="forgotPassword" placeholder="jsmith@bestbuy.com" name="user-email" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
          </div>
          <div v-if="message" class="col-span-12">
            <div class="text-sm text-center text-green-600">{{ message }}</div>
          </div>
          <div class="col-span-12 text-center">
            <button v-if="!submitted" :disabled="email.length==0" @click="forgotPassword" type="button" class="inline-flex justify-center rounded-md border border-indigo-700 bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-700 hover:border-indigo-700 transition duration-200 disabled:opacity-50 disabled:cursor-auto disabled:bg-indigo-400 disabled:border-indigo-400">Reset</button>
            <button @click="$router.push({name:'login'})" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 font-medium text-black shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-gray-300 hover:border-gray-300 transition duration-200 disabled:opacity-50 disabled:cursor-auto disabled:bg-gray-100">Back to Login</button>
          </div>
            <div v-if="errorMessage" class="col-span-12">
                <div class="text-sm text-center text-red-600">{{ errorMessage }}</div>
            </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
    import { getAuth, sendPasswordResetEmail  } from "firebase/auth"
  
    export default {
      data() {
        return {
            email: "",
            submitted: false,
            message: "",
            errorMessage:""
        }
      },
      methods: {
        forgotPassword() {
          const auth = getAuth();
          this.errorMessage = "";
          sendPasswordResetEmail(auth,this.email)
            .then(() => {
                this.message = "An email has been sent to you with password reset instructions."
                this.submitted = true;
            })
            .catch((error) => {
                switch (error.code) {
                case "auth/invalid-email":
                    this.errorMessage = "Invalid email"
                    break;
                case "auth/user-not-found":
                    this.errorMessage = "No account with that email was found"
                    break;
                case "auth/wrong-password":
                    this.errorMessage = "Incorrect password"
                    break;
                default:
                    this.errorMessage = "Email or password was incorrect"
                    break;
                }
            })
        }
      }
    }
  </script>
