<template>
    <!-- SMALL SCREEN - LIST -->
    <div @click="$emit('equipmentSelected')" class="group flex no-wrap sm:hidden py-4 border-b-2 border-slate-100 overflow-hidden">
        <div class="flex items-center relative">
            <div v-if="equipment.status && unavailableStatus.indexOf(equipment.status) != -1" class="absolute inset-x-0 bottom-0">
                <EquipmentStatusBadge :status="equipment.status" :style="'cover'"></EquipmentStatusBadge>
            </div>
            <div class="w-28 h-28 bg-image-center" :style="{ 'background-image':`url(${ (equipment.images && equipment.images.length>0) ? equipment.images[0].url : '' })` }"></div>
        </div>
        <div class="w-4"></div>
        <div class="grow flex flex-col justify-between overflow-hidden w-full">
            <div class="two-line font-bold text-md">{{ equipment.name }}</div>
            <div class="text-sm overflow-hidden min-w-0 font-normal truncate">{{ equipment.description }}</div>
            <div class="two-line">
                <EquipmentTags :tags="equipment.tags" :mode="'view'"></EquipmentTags>
            </div>
        </div>
    </div>

    <!-- LARGE SCREEN -->
    <!-- GRID -->
    <div v-show="viewtype == 'grid'" @click="$emit('equipmentSelected')" class="group flex-col bg-white hidden sm:flex sm:w-[50%] lg:w-[33%] xl:w-[25%] mb-8 rounded overflow-hidden cursor-pointer hover:bg-gray-100">
        <div class="equipment-top">
            <div class="bg-image-center w-[180px] h-[180px] transition duration-200 ease-in-out group-hover:scale-[1.01] relative overflow-hidden">
                <div v-if="equipment.status && unavailableStatus.indexOf(equipment.status) != -1" class="absolute inset-x-0 bottom-0">
                    <EquipmentStatusBadge :status="equipment.status" :style="'cover'"></EquipmentStatusBadge>
                </div>
                <img class="group-hover:hidden" :src="(equipment.images && equipment.images.length>0) ? equipment.images[0].url : '' " />
                <img class="hidden group-hover:block" :src="(equipment.images && equipment.images.length>1) ? equipment.images[1].url : ((equipment.images && equipment.images.length>0) ? equipment.images[0].url : '') " />
            </div>
        </div>
        <div class="flex items-center gap-4 px-6 truncate justify-center text-sm">
            <div class="font-semibold truncate select-none hover:underline" @click.stop="$emit('updateFilter','category',equipment.category)">{{ equipment.category }}</div>
            <font-awesome-icon class="" icon="fa-chevron-right" />
            <div class="font-semibold truncate select-none hover:underline" @click.stop="$emit('updateFilter','subcategory',equipment.subcategory)">{{ equipment.subcategory }}</div>
        </div>
        <div class="px-6 pb-2">
            <div class="text-bestbuy-900 font-bold text-lg truncate text-center">{{ equipment.name }}</div>
        </div>
        
        <div class="px-6 grow flex items-end mb-2 justify-center">
            <EquipmentTags :tags="equipment.tags" :mode="'view'"></EquipmentTags>
        </div>
    </div>
    <!-- LIST -->
    <div v-show="viewtype == 'list'" @click="$emit('equipmentSelected')" class="group hidden sm:block flex-nowrap w-full py-2 border-b-2 border-slate-100 cursor-pointer bg-white hover:bg-gray-100">
        <div class="flex">
            <div class="items-center px-4">
                <div class="w-20 h-20 bg-image-center transition duration-200 ease-in-out group-hover:scale-[1.01] relative">
                    <div v-if="equipment.status && unavailableStatus.indexOf(equipment.status) != -1" class="absolute inset-x-0 bottom-0">
                        <EquipmentStatusBadge :status="equipment.status" :style="'cover'"></EquipmentStatusBadge>
                    </div>
                    <img class="group-hover:hidden w-20 h-20" :src="(equipment.images && equipment.images.length>0) ? equipment.images[0].url : '' " />
                    <img class="hidden group-hover:block w-20 h-20" :src="(equipment.images && equipment.images.length>1) ? equipment.images[1].url : ((equipment.images && equipment.images.length>0) ? equipment.images[0].url : '') " />
                </div>
            </div>
            <div class="grow flex flex-col pl-4 overflow-hidden">
                <div class="text-bestbuy-900 two-line font-bold text-lg">{{ equipment.name }}</div>
                <div class="flex items-center gap-4">
                    <div class="two-line font-semibold text-sm select-none hover:underline" @click.stop="$emit('updateFilter','category',equipment.category)">{{ equipment.category }}</div>
                    <font-awesome-icon class="text-sm" icon="fa-chevron-right" />
                    <div class="two-line font-semibold text-sm select-none hover:underline" @click.stop="$emit('updateFilter','subcategory',equipment.subcategory)">{{ equipment.subcategory }}</div>
                </div>
                <div class=" font-normal">
                    <div class="truncate text-sm text-gray-700">{{ equipment.description }}</div>
                </div>
                <EquipmentTags :tags="equipment.tags" :mode="'view'"></EquipmentTags>
            </div>
        </div>
    </div>



</template>

<script>
    import EquipmentTags from './EquipmentTags.vue';
    import { equipmentStatus } from '../store/store.js';
    import EquipmentStatusBadge from './EquipmentStatusBadge.vue';

    export default {
        components: {
            EquipmentTags,
            EquipmentStatusBadge
        },
        props: ['equipment','viewtype'],
        emits:['equipmentSelected','updateFilter'],
        data() {
            return {
                unavailableStatus: equipmentStatus.unvailableStatus
            }
        }
    }
</script>

<style scoped>
    .equipment-top {
        padding:10px;
        display:flex;
        justify-content: center;
        align-items:center;
    }
</style>