<template>
    <span v-if="style=='cover'">
        <div :class="statusColorClass" class="w-full text-black text-xs p-1 text-center font-medium opacity-90">
            <font-awesome-icon v-if="showIcon" icon="fa-exclamation-triangle" />
            {{ status }}
        </div>
    </span>
    <span v-else>
        <span v-if="status !== null && status !== ''" :class="statusColorClass" class="rounded-full px-4 text-black text-xs">
            <font-awesome-icon v-if="showIcon" icon="fa-exclamation-triangle" />
            {{ status }}
        </span>
        <span v-else>-</span>
    </span>
</template>

<script>
import { equipmentStatus } from '../store/store.js'

export default {
    props: ["status","style"],
    computed: {
        statusColorClass() {
            const def = equipmentStatus.values.find((item) => {
                return item.value === this.status;
            })
            if (def) {
                return def.class
            } else {
                return 'bg-gray-100'
            }
        },
        showIcon() {
            const def = equipmentStatus.values.find((item) => {
                return item.value === this.status;
            })
            if (def) {
                return def.warning;
            } else {
                return false;
            }
        }
    }
}
</script>
