<template>
    <div class="relative inline-block text-left">
        <div>
            <button type="button" @click.stop="toggleMenu" class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-50 focus:outline-none" id="menu-button" aria-expanded="true" aria-haspopup="true">
                {{ selectedValue }}
                <!-- Heroicon name: mini/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>

        <div class="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100" :class=" expanded ? 'transform opacity-100 scale-100 z-10' : 'transform opacity-0 scale-95 -z-10' " role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1" >
            <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <div v-for="option in options" :key="option.value" :class="option.value === menuValue ? 'bg-gray-100' : '' " class="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 select-none" role="menuitem" tabindex="-1" id="menu-item-0" @click="selectOption(option)" >{{ option.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['options'],
        emits: ['menuUpdate'],
        data() {
            return {
                expanded: false,
                menuValue: ""
            }
        },
        methods:{
            toggleMenu() {
                this.expanded = !this.expanded
            },
            closeMenu() {
                this.expanded = false
            },
            selectOption(option) {
                this.menuValue = option.value
                this.closeMenu()
                this.$emit('menuUpdate',option.value)
            }
        },
        computed:{
            selectedValue() {
                if (this.options) {
                    const selectedValue = this.menuValue ? this.menuValue : this.options[0]['value']
                    return this.options.find(item => {
                        return item.value == selectedValue
                    }).name
                } else {
                    return ""
                }
            }
        },
        mounted() {
            window.addEventListener('click', this.closeMenu)
        },
        unmounted() {
            window.removeEventListener('click', this.closeMenu)
        }
        
    }

</script>

<style>

</style>