import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './index.css'
import { getFirebaseApp } from './firebase'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Navbar from './components/Navbar.vue'

// Font font-awesome icons
library.add(fas);

getFirebaseApp()


// Create web application
const app = createApp(App)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('Navbar',Navbar)
    .mount('#app')
