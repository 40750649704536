<template>
    <div class="flex items-center justify-between">
        <div class="flex items-end">
            <div class="text-gray-900 dark:text-white flex items-center gap-2" >
                <slot name="icon"></slot>
                <span class="text-gray-900 text-2xl grow dark:text-white" >{{ title }}</span>
            </div>
            <div class="ml-2 text-gray-400 text-xs font-medium italic mb-1">
                <slot name="subtitle"></slot>
            </div>
        </div>
        <div>
            <slot name="right"></slot>
        </div>
    </div>
</template>
  
  <script>
  export default {
      props: ['title']
  }
  </script>