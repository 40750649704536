<template>
    <div class="truncate flex flex-wrap gap-2">
        <div v-for="tag in tags" :key="tag" class="flex flex-nowrap items-center justify-center text-sm uppercase rounded text-black bg-bestyellow-300">
            <div class="px-2 text-xs">{{ tag }}</div>
            <div v-if="mode=='edit'" @click="clickRemove(tag)" class="cursor-pointer rounded-r bg-bestyellow-600 px-2 hover:bg-bestyellow-700">
                <font-awesome-icon class="text-xs text-black" icon="fa-x" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tags','mode'],
    emits: ['removeTag'],
    methods: {
        clickRemove(tag) {
            this.$emit("removeTag",tag)
        }
    }
}
</script>

<style>

</style>