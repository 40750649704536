<template>
    <section class="max-w-screen-2xl mx-auto px-4">
        <!-- NAVIGATE BACK -->
        <div class="py-2">
            <div @click="navigateBack" class="px-2 w-fit flex items-center gap-1 select-none cursor-pointer text-bestbuy-800">
                <font-awesome-icon class="text-xs" icon="fa-chevron-left" />
                <div class="text-sm font-medium">Back</div>
            </div>
        </div>

        <div class="py-4 pt-2 flex justify-between items-center ">
            <PageTitle :title="'New Reservation'" @click="print(reservation)"></PageTitle>
        </div>
        <!-- HR -->
        <div class="border-b-2 border-gray-300"></div>
        <!-- DETAILS -->
        <div class="w-full md:max-w-4xl mx-auto my-4">
            <div v-if="reservation && reservation != null" class="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4">
                <!-- OWNER -->
                <div class="w-full md:col-span-">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-600">Owner</label>
                        <div class="mx-1 flex items-center overflow-hidden">
                            <div class="w-12 h-12 flex-shrink-0 mr-2 sm:mr-3">
                                <div :style="`background-image:url('${ store.user.userImg ? store.user.userImg : store.user.defaultUserImg }')`" name="userprofileimage" class="h-[48px] w-[48px] rounded-full bg-center bg-cover cursor-pointer"></div>
                            </div>
                            <div class="text-gray-800 truncate">{{ store.user.user.displayName }}</div>
                        </div>
                    </div>
                </div>

                <!-- PROJECT NAME -->
                <div class="w-full md:col-span-2">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-600">Project Name</label>
                        <v-select v-model="projects.selected" :options="store.projects.items.filter(_ => _.status != 'Inactive')" @update:modelValue="updateLocalRecord" label="_projectIdName" class="" ></v-select>
                    </div>
                </div>

                <!-- RESERVATION START -->
                <div v-if="projects.selected" class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-600">Reservation Start</label>
                        <div >
                            <input type="date" v-model="reservation._fpstart" v-on:change="updateLocalRecord" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <!-- <flat-pickr v-model="reservation._fpstart" :config="flatpicker.startDate.config" placeholder="Start date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>
                </div>

                <!-- RESERVATION END -->
                <div v-if="projects.selected" class="w-full">
                    <div class="flex flex-col">
                        <label for="first-name" class="font-bold text-gray-600">Reservation End</label>
                        <div>
                            <input type="date" v-model="reservation._fpend" v-on:change="updateLocalRecord" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" />
                            <!-- <flat-pickr v-model="reservation._fpend" :config="flatpicker.endDate.config" placeholder="End date" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" /> -->
                        </div>
                    </div>
                </div>

                <!-- EQUIPMENT -->
                <div v-if="projects.selected" class="w-full col-span-1 md:col-span-2">
                    <div class="flex flex-col mt-4">
                        <label for="first-name" class="text-gray-600 font-bold" @click="print(reservationEquipment)">
                            Reserved Gear 
                            <span class="text-gray-400 text-xs font-medium italic">{{ '(' + equipment.selected.length + ( equipment.selected.length > 1 ? ' items)' : ' item)') }}</span>
                        </label>
                        <div class="v-hide-selected">
                            <v-select v-model="equipment.selected" 
                                :options="filteredEquipment" 
                                :filter="gearSearch" 
                                :selectable="(gear) => (isGearAvailable(gear) && !(isGearSelected(gear)) && isGearStatusAvailable(gear.status) )" 
                                @update:modelValue="updateLocalRecord" 
                                label="name" 
                                placeholder="Search and add equipment" 
                                multiple>
                                <template v-slot:option="option">
                                    <div v-if="isGearSelected(option)" class="w-full truncate font-semibold">{{ option.name }}<span class="text-xs italic"> (Selected)</span></div>
                                    <div v-else-if="!isGearAvailable(option)" class="w-full truncate"><s>{{ option.name }}</s><span class="px-2 text-xs italic text-red-400"> Unavailable</span></div>
                                    <div v-else-if="!isGearStatusAvailable(option.status)" class="w-full truncate"><s>{{ option.name }}</s><span class="px-2 text-xs italic text-red-400"> {{ option.status }} </span></div>
                                    <!-- <div v-else class="w-full truncate">{{ option.name }}</div> -->
                                    <div v-else class="flex justify-between w-full">
                                    <div class="truncate">
                                        {{ option.name }}
                                    </div>
                                    <div class="truncate">{{ option.barcode }}</div>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div class="mx-1 my-2 text-sm  text-black flex flex-col h-full justify-center">
                            <div>
                                <div v-for="gear in reservationEquipment" :key="gear._fsid" class="border-b">
                                    <div :class=" gear['_conflict'] ? 'border-l-red-300 bg-red-100  hover:bg-red-100' : 'border-l-slate-300 hover:bg-gray-100' " class="py-2 border-l-4 cursor-pointer flex justify-between items-center">
                                        <div class="flex items-center gap-2">
                                            <div v-if="gear.images && gear.images.length>0" :style="`background-image:url('${ gear.images[0].url }')`" class="mx-2 w-8 h-8 bg-center bg-no-repeat bg-cover rounded"></div>
                                            <div class="select-none">{{ gear.name }}</div>
                                        </div>
                                        <div @click="removeGearItem(gear)" class="px-2 py-2 mx-2 rounded w-fit flex items-center gap-1 select-none text-white bg-red-400">
                                            <font-awesome-icon icon="fa-x" aria-hidden="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- PAGE FOOTER -->
        <div class="px-6 py-3 flex justify-center items-center mt-8 border-t-2">
            <button @click="saveNewReservation" :disabled="!isValid" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200 disabled:opacity-50">Save</button>
            <button @click="navigateBack" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200 ">Cancel</button>
        </div>


    </section>

</template>

<style scoped>
    .breadcrumb-item:not(:first-child):before {
        border-bottom: 4px solid #0000;
        border-left: 4px solid #000;
        border-top: 4px solid #0000;
        content: " ";
        display: inline-block;
        margin: 0 8px;
    }
</style>

<script>
    import {
        store
    } from '../store/store.js'
    import FullCalendar from '@fullcalendar/vue3'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import vSelect from "vue-select";
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import { Reservation } from '../utilities/utilities.js';
    import moment from 'moment';
    import PageTitle from '../components/PageTitle.vue';
    import { equipmentStatus } from '../store/store.js';

    export default {
        components: {
            FullCalendar,
            flatPickr,
            vSelect,
            PageTitle
        },
        data() {
            return {
                store: store,
                reservation: new Reservation(null),
                projects: {
                    selected:null
                },
                unavailableStatus: equipmentStatus.unvailableStatus,
                equipment: {
                    selected:[]
                },
                flatpicker: {
                    startDate: {
                        config: {
                            enableTime: false,
                            //minDate: new Date(),
                            dateFormat: "m/d/Y h:i K",
                            defaultHour: 0,
                            defaultMinute: 0,
                            onChange: (selectedDates, dateStr, instance) => this.reservationDateChange(selectedDates, dateStr, instance,'startDate')
                        }
                    },
                    endDate: {
                        config: {
                            enableTime: false,
                            dateFormat: "m/d/Y h:i K",
                            //minDate: new Date(),
                            onChange: (selectedDates, dateStr, instance) => this.reservationDateChange(selectedDates, dateStr, instance,'endDate')
                        }
                    }
                },
            }
        },
        methods: {
            initializeComponent() {
                let _reservation = localStorage.getItem("_reservation");
                if (_reservation) {
                    _reservation = JSON.parse(_reservation);
                    console.log("Updated reservation from local storage. ", _reservation);
                    // We have a cached reservation, populate model
                    this.projects.selected = _reservation.project
                    this.reservation._fpstart = _reservation.start;
                    this.reservation._fpend = _reservation.end;
                    this.equipment.selected = _reservation.gear;
                }
            },
            print(val) {
                console.log("val",val);
            },
            selectGear(gear) {
                this.$router.push({
                    name:"equipmentdetails",
                    params: {
                        id:gear._fsid
                    }
                })
            },
            navigateBack() {
                localStorage.removeItem("_reservation");
                this.$router.back()
            },
            saveNewReservation() {
                const event = {
                    author:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    createdDate: new Date(),
                    editor:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    equipment: this.equipment.selected.map(_ => _._fsid),
                    modifiedDate: new Date(),
                    owner:{
                        id: store.user.user.uid,
                        name: store.user.user.displayName ? store.user.user.displayName : store.user.user.email,
                        profileImg: store.user.user.photoURL
                    },
                    project: this.projects.selected.projectnumber,
                    projectName: this.projects.selected.projecttitle,
                    projectId: this.projects.selected._fsid,
                    reservationStart: new Date(this.reservation._fpstart + "T00:00:00.000"),
                    reservationStarted: null,
                    reservationEnd: new Date(this.reservation._fpend + "T00:00:00.000"),
                    reservationEnded: null,
                    status: 'not started'
                }
                console.log("::",event);
                store.reservations.createReservation(event).then((docRef) => {
                    // Log Event
                    store.analytics.logAppEvent({
                        uid: store.user.user.uid,
                        name: store.user.user.displayName,
                        email: store.user.user.email,
                        action: "Create",
                        type: "Reservation",
                        target: docRef.id,
                        url: window.location.href,
                        datetime: new Date()
                    });
                    localStorage.removeItem("_reservation");
                    this.navigateBack();
                })
            },
            reservationDateChange(selectedDate, dateStr, instance, pickerType) {
                
            },
            removeGearItem(gear) {
                this.equipment.selected = this.equipment.selected.filter(selectedGear => {
                    return selectedGear._fsid != gear._fsid;
                })
            },
            formatDate(val,format) {
                return moment(val).format(format);
            },
            isGearAvailable(gear) {
                return !this.unavailableEquipment.includes(gear._fsid)
            },
            isGearSelected(gear) {
                return this.equipment.selected.map(_ => _._fsid).includes(gear._fsid);
            },
            isGearStatusAvailable(status) {
                return this.unavailableStatus.indexOf(status) === -1;
            },
            gearSearch(options,search) {
                search = search ? search.toLowerCase() : '';
                const fitems = options.filter(item => {
                    return search == '' ||
                    (item.name && item.name.toLowerCase().includes(search)) || 
                    (item.category && item.category.toLowerCase().includes(search)) ||
                    (item.subcategory && item.subcategory.toLowerCase().includes(search)) ||
                    (item.tags && item.tags.join(" ").toLowerCase().includes(search)) ||
                    (item.barcode && item.barcode.toLowerCase().includes(search))

                    // const barcode = eqp.barcode ? eqp.barcode.toLowerCase() : '';
                    // const name = eqp.name ? eqp.name.toLowerCase() : '';
                    // return (barcode.indexOf(search) > -1) || (name.indexOf(search) > -1);
                })
                return fitems;
            },
            updateLocalRecord() {
                const reservation = {
                    project: this.projects.selected,
                    start: this.reservation._fpstart,
                    end: this.reservation._fpend,
                    gear: this.equipment.selected
                }
                localStorage.setItem("_reservation",JSON.stringify(reservation));
            }
        },
        computed: {
            reservationEquipment() {
                let equipment = [];
                let selectedEquipment = this.equipment.selected.map(_ => _._fsid);
                let equipmentIds = [];
                let uniqueEquipmentIds = [];
                if (this.reservation && this.reservation._fpstart && this.reservation._fpend) {
                    const selStartDate = new Date(this.reservation._fpstart);
                    const selEndDate = new Date(this.reservation._fpend);
                    const overlappingReservations = store.reservations.items.filter(reservation => {
                        // Overlapping conditions:
                        //  1. ReservationEnd > SelectedStart
                        //  2. ReservationStart < SelectedEnd
                        return (reservation.end > selStartDate && reservation.start < selEndDate) && reservation._fsid != this.reservation._fsid
                    })
                    overlappingReservations.forEach(res => {
                        equipmentIds = equipmentIds.concat(res.equipment)
                    })
                    uniqueEquipmentIds = [...new Set(equipmentIds)];
                }

                equipment = store.equipment.items.filter(gear => {
                    return selectedEquipment ? selectedEquipment.includes(gear._fsid) : false;
                }).map(gear => {
                    gear['_conflict'] = uniqueEquipmentIds.includes(gear._fsid);
                    return gear;
                });

                return equipment;
                
            },
            filteredEquipment() {
                let equipment = store.equipment.items;
                const isAdmin = store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin');
                // Filter out discarded and donated equipment
                if (!isAdmin) {
                    equipment = equipment.filter(item => {
                        return item.status != "Donated" && item.status != "Discarded" && item.status != "Transfer";
                    })
                }
                return equipment;
            },
            unavailableEquipment() {
                if (this.reservation._fpstart && this.reservation._fpend) {
                    const selStartDate = new Date(this.reservation._fpstart);
                    const selEndDate = new Date(this.reservation._fpend);
                    const overlappingReservations = store.reservations.items.filter(reservation => {
                        // Overlapping conditions:
                        //  1. ReservationEnd > SelectedStart
                        //  2. ReservationStart < SelectedEnd
                        return (reservation.end > selStartDate && reservation.start < selEndDate) && reservation._fsid != this.reservation._fsid
                    })
                    
                    let equipmentIds = [];
                    overlappingReservations.forEach(res => {
                        equipmentIds = equipmentIds.concat(res.equipment)
                    })
                    let uniqueEquipmentIds = [...new Set(equipmentIds)];
                    console.log("unavailableEquipment",uniqueEquipmentIds)
                    return uniqueEquipmentIds

                } else {
                    return []
                }
            },
            isValid() {
                const equipmentConflict = this.reservationEquipment.map(_ => _['_conflict']).filter(Boolean)
                return (this.projects.selected != null && (this.reservation._fpstart != null && this.reservation._fpend != null) && this.equipment.selected.length>0 && equipmentConflict.length == 0)
            }
        },
        mounted() {
            store.projects.getProjects(false);
            this.initializeComponent();
        }
    }
</script>