<template>
    <button :data-title="text" :class="classList + (text ? ' tooltip' : '')" data-html="true" class="fade text-sm cursor-pointer" >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props:['classList','text']
}
</script>

<style scoped>
/* setup tooltips */
.tooltip {
    position: relative;
}
.tooltip:before,
.tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
}
.tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0,0,0,.75); 
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 20px;
    left: 20px;
    width: 0;
}
.tooltip:before {
    background: rgba(0,0,0,.75);
    border-radius: 2px;
    color: #fff;
    content: attr(data-title);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    padding: 6px 10px;
    top: 26px;
    white-space: pre-line;
    width: 160px;
}

/* the animations */
/* fade */
.tooltip.fade:after,
.tooltip.fade:before {
    transform: translate3d(0,-10px,0);
    transition: all .15s ease-in-out;
}
.tooltip.fade:hover:after,
.tooltip.fade:hover:before {
    opacity: 1;
    transform: translate3d(0,0,0);
}

</style>