<template>

    <Modal v-if="isOpen" @close="close" :class="isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'" class="">
        <template v-slot:header>
            Leave a Review
        </template>
        <template v-slot:body>
            
            <form @submit.stop>
                <div class="grid grid-cols-12 gap-6">

                    <!-- Images -->
                    <div class="col-span-12">
                        <div class="flex flex-col align-center">
                            <label for="comment" class="block font-medium text-gray-700">Images</label>
                            <div @click="clickFileInput" @drop.prevent="dragdropHandler($event)" @dragover.prevent="dragoverHandler" @dragleave="dragleaveHanlder" @dragenter.prevent :class="ui.showDragLines ? 'border-dashed border-4 border-indigo-500' : 'border' " class=" text-gray-500 flex items-center grow justify-center flex-wrap" style="max-height:350px;min-height:100px">
                                <span @click.stop="selectImages" v-if="files.length == 0" class="">Drag and drop files or <span class="text-indigo-600 cursor-pointer">browse</span></span>
                                <div v-for="file in files" :key="file.file.name" class="group text-center w-28 p-2 relative">
                                    <div v-if="ui.uploadStatus == '' && file.status==''" class="absolute top-0 right-0 invisible group-hover:visible" @click.stop="removeFile(file)">
                                        <font-awesome-icon class="rounded-full bg-white text-lg text-black cursor-pointer opacity-50" icon="fa-circle-xmark"  />
                                    </div>
                                    <div :style="`background-image:url('${ file.preview }')`" class="h-24 w-24 bg-cover bg-no-repeat bg-center"></div>
                                    <div class="truncate">{{ file.file.name }}</div>
                                    
                                    <div v-if="file.status != '' || ui.isLoading">
                                        <!-- Progress indication -->
                                        <div v-if="file.isLoading" class="italic text-xxs tracking-wider">
                                            <div class="bg-indigo-100 text-indigo-700">Uploading..</div>
                                        </div>
                                        <div v-else class="italic text-xxs tracking-wider">
                                            <div v-if="file.status == 'complete'" class="bg-green-100 text-green-700">Complete</div>
                                            <div v-else-if="file.status == 'error'" class="bg-red-100 text-red-700">Error</div>
                                            <div v-else class="bg-gray-100 text-gray-600">Pending</div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <!-- <button @click="selectImages" type="button" class="inline-flex justify-center rounded-md border border-green-600 bg-green-600 px-2 py-1 font-medium text-white text-sm shadow-sm hover:bg-green-700 focus:outline-none mx-auto w-28 transition duration-200">Add Images</button> -->
                            <input type="file" multiple accept="image/*" id="imageuploadinput" style="display:none" >
                        </div>
                    </div>
                    
                    <!-- Comment -->
                    <div class="col-span-12">
                        <div class="flex flex-col justify-between">
                            <label for="comment" class="block font-medium text-gray-700">Feedback</label>
                            <textarea v-model="comment" type="text" name="comment" rows="2" placeholder="Comments or feedback" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none"></textarea>
                        </div>
                        
                    </div>
                    
                </div>
            </form>


        </template>
        <template v-slot:footer>
            <button @click="submit" :disabled="files.length == 0 || comment.length == 0" type="button" class="inline-flex justify-center rounded-md border border-bestbuy-800 bg-bestbuy-800 px-4 py-2 font-medium text-white shadow-sm hover:bg-bestbuy-900 focus:outline-none ml-3 w-auto text-sm disabled:opacity-50 transition duration-200">Submit</button>
            <button @click="close" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none ml-3 w-auto text-sm transition duration-200">Cancel</button>
        </template>
    </Modal>

</template>

<script>
    import { store } from '../store/store.js'
    import Modal from '../components/Modal.vue';
    import { ResizeImage } from '../utilities/utilities';
    import { UserFeedback } from '../utilities/utilities.js';
    import { getStorage, ref, uploadBytes } from "firebase/storage";

    export default {
        components: {
            Modal
        },
        emits:['closeEvent'],
        props: ['isOpen', 'closeForm', 'gear'],
        data() {
            return {
                ui:{
                    isLoading:false,
                    showDragLines:false,
                    uploadStatus: ""
                },
                files:[],
                comment:"",
                user:store.user
            }
        },
        methods: {
            close() {
                this.closeForm();
                this.files = [];
                document.getElementById("imageuploadinput").value = '';
                this.$emit('closeEvent');
            },
            async submit() {
                console.log(":: Submit Images ::");

                // 1. Upload Files
                let uploads = []
                for (let item of this.files) {
                    //const path = `/Gear/${this.gear._fsid}/${item.file.name}`;
                    const path = `/Gear/${this.gear._fsid}/${item.name}`;
                    console.log(":: Uploading file ::",item.file, path);
                    const fileUpload = await this.uploadFile(item, path);
                    uploads.push(fileUpload);
                }

                // 2. Add new document (record) to equipmentFeedback collection
                const images = uploads.map(_ => {
                    return {
                        name: _.metadata.name,
                        fullPath: _.metadata.fullPath,
                        bucket: _.metadata.bucket,
                        size: _.metadata.size
                    }
                });

                const _now = new Date();
                let uploadObj = {
                    equipment: this.gear._fsid,
                    authorid: store.user.user["uid"],
                    authorname: store.user.user["displayName"],
                    editorid: store.user.user["uid"],
                    editorname: store.user.user["displayName"],
                    created: _now,
                    modified: _now,
                    comment: this.comment,
                    images: images,
                    likes:[],
                    replies:[]
                };


                console.log(":: ",uploadObj," ::");
                // await store.equipment.addEquipmentFeedback(JSON.parse(JSON.stringify(uploadObj)));
                await store.equipment.addEquipmentFeedback(uploadObj);
                // Log Event
                store.analytics.logAppEvent({
                    uid: store.user.user.uid,
                    name: store.user.user.displayName,
                    email: store.user.user.email,
                    action: "Add",
                    type: "Feedback",
                    target: this.gear._fsid,
                    url: window.location.href,
                    datetime: new Date()
                });
                this.close();

            },
            selectImages() {
                document.querySelector("#imageuploadinput").click();
            },
            clickFileInput(ev) {
                // ev.preventDefault();
                let fileinput = document.getElementById("imageuploadinput");
                if (fileinput) {
                    fileinput.click();
                }
            },
            async fileInputChange() {
                console.log(":: fileInputChange ::");
                let fileinput = document.getElementById("imageuploadinput");
                let files = fileinput.files;
                //for (let i = 0;i < files.length; i++) {
                for (const file of files) {
                    const imgurl = URL.createObjectURL(file);

                    // Resize Image
                    const resizeblob = await this.resizeImage(imgurl);

                    this.files.push({
                        file: resizeblob,
                        preview: imgurl,
                        status: "",
                        path:"",
                        vector:[],
                        isLoading: false,
                        percComp:0,
                        name: file.name
                    });

                    console.log(this.files);
                }
            },
            resizeImage(url,
                options = {
                    maxWidth: 1024,
                    maxHeight: 768,
                    contentType: "image/jpeg",
                    quality: 0.7
                }) {
                
                const calculateSize = (img) => {
                    let w = img.width,
                        h = img.height;
                    if (w > h) {
                        if (w > options.maxWidth) {
                        h = Math.round((h * options.maxWidth) / w);
                        w = options.maxWidth;
                        }
                    } else {
                        if (h > options.maxHeight) {
                        w = Math.round((w * options.maxHeight) / h);
                        h = options.maxHeight;
                        }
                    }
                    return [w, h];
                };
                
                return new Promise(resolve => {
                    var img = new Image;
                    img.onload = function() {
                        const [newWidth, newHeight] = calculateSize(
                            img,
                            options.maxWidth,
                            options.maxHeight
                        );
                        console.log("newWidth, newHeight",newWidth, newHeight)
                        const canvas = document.createElement("canvas");
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext("2d");

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);
                        console.log(":: img.onload ::");

                        const resultUrl = canvas.toDataURL(options.contentType, options.quality),
                        result = {
                            url: resultUrl,
                            contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
                            b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
                        };

                        canvas.toBlob(
                            (blob) => {
                                result.size = blob.size;
                                resolve(blob,result);
                            },
                            options.contentType,
                            options.quality
                        );
                    }
                    img.src = url;
                })
            },
            dragoverHandler() {
                if (this.ui.isLoading) {
                    return false;
                }
                this.ui.showDragLines = true;
            },
            dragleaveHanlder() {
                if (this.ui.isLoading) {
                    return false;
                }
                this.ui.showDragLines = false;
            },
            dragdropHandler(ev) {
                if (this.ui.isLoading) {
                    return false;
                }
                console.log(":: dragdropHandler ::",ev);
                this.ui.showDragLines = false;
                if (ev.dataTransfer.items) {
                    [...ev.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files or images, reject them
                    console.log(item);
                    if (item.kind === "file" && (item.type=="image/jpeg" || item.type=="image/png")) {
                        const file = item.getAsFile();
                        const imgurl = URL.createObjectURL(file);
                        this.files.push({
                            file: file,
                            preview: imgurl,
                            status: "",
                            path:"",
                            vector:[],
                            isLoading: false,
                            percComp:0
                        });
                    }
                    });
                } else {
                    // Use DataTransfer interface to access the file(s)
                    [...ev.dataTransfer.files].forEach((file, i) => {
                    console.log(`… file[${i}].name = ${file.name}`);
                    this.modal.files.push(file);
                    });
                }

            },
            removeFile(f) {
                this.files = this.files.filter(_ => _.file.name != f.file.name)
            },
            uploadFile(file,path) {
                console.log(":: uploadFile ::",file,path);
                const storage = getStorage();
                const storageRef = ref(storage,path);
                return uploadBytes(storageRef,file.file);
            }
        },
        computed: {

        },
        updated() {
            let fileinput = document.getElementById("imageuploadinput");
            if (fileinput) {
                fileinput.removeEventListener("change",this.fileInputChange);
                fileinput.addEventListener("change",this.fileInputChange);
            }
        },
        mounted() {
            console.log(":: Mounted EquipmentImageUpload ::");
        }
    }
</script>

<style>

</style>