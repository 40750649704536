<template>
    <div class="w-full">
        <div class="text-bestbuy-900 two-line font-bold text-xl">
            <button @click="toggleCollapsestate" class="w-full text-left">
                <font-awesome-icon v-show="_isCollapsed" class="mr-2" icon="fa-chevron-right" />
                <font-awesome-icon v-show="!_isCollapsed" class="mr-2" icon="fa-chevron-down" />
                <span>{{ title }}</span>
            </button>
        </div>
        <!-- Section Content -->
        <div class="overflow-hidden" :style="_isCollapsed ? 'height:0' : 'height:auto'">
            <div :style="_isCollapsed ? 'margin-top:-100%' : 'margin-top:0'" class="transition-all duration-500 ease-in-out">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
      props: {
        title: {
            type: String,
            default: ""
        },
        isCollapsed: {
            type: Boolean,
            default: false
        }
      },
      data() {
        return {
            _isCollapsed: this.isCollapsed
        }
      },
      methods:{
        toggleCollapsestate() {
            this._isCollapsed = !this._isCollapsed;
        }
      },
      mounted() {
        this._isCollapsed = this.isCollapsed;
      }
  }
  </script>