<template>

    <Modal v-if="reservation && reservation._mstart && reservation._mend" @close="close" :class="isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'" class="">
        <template v-slot:header>
            Reservation Overview
        </template>
        <template v-slot:body>
            <form @submit.stop>
                <div class="grid grid-cols-12 gap-6">
                    <!-- DETAILS -->
                    <div class="col-span-12">
                        <label for="first-name" class="block font-medium text-gray-700">Owner</label>
                        <div class="col-span-12 flex items-center overflow-hidden pt-2">
                            <div class="w-12 h-12 flex-shrink-0 mr-2 sm:mr-3">
                                <div :style="`background-image:url('${ reservation.owner.profileImg ? reservation.owner.profileImg : defaultUsrImg }')`" name="userprofileimage" class="h-[48px] w-[48px] rounded-full bg-center bg-cover cursor-pointer"></div>
                            </div>
                            <div class="text-gray-800 truncate">{{ reservation.owner.name }}</div>
                        </div>
                    </div>

                    <!-- HR -->
                    <div class="col-span-12 w-full border-b-2 border-gray-100"></div>

                    <div class="col-span-12">
                        <div class="flex justify-between">
                            <label for="first-name" class="block font-medium text-gray-700">Project</label>
                            <div class="mx-1 text-sm font-semibold text-black">{{ reservation['project'] }}</div>
                        </div>
                        <input disabled :value="reservation.projectName" type="text" name="first-name" placeholder="Search projects" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                    </div>
                    <div class="col-span-12 md:col-span-5">
                        <label for="start-date" class="block font-medium text-gray-700">Start Date</label>
                        <input disabled :value="reservation._mstart.format('M/DD/YYYY')" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                    </div>
                    <div class="hidden md:block col-span-2 flex justify-center items-center">
                    </div>
                    <div class="col-span-12 md:col-span-5">
                        <label for="end-date" class="block font-medium text-gray-700">End Date</label>
                        <input disabled :value="reservation._mend.format('M/DD/YYYY')" name="first-name" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                    </div>
                </div>
                <div class="my-6"></div>
                <!-- HR -->
                <div class="w-full my-4 border-b-2 border-gray-100"></div>
                <!-- EQUIPMENT -->
                <div class="">
                    <div>
                        <label for="first-name" class="block font-medium text-gray-700">Equipment</label>
                        <input type="text" name="first-name" class="hidden form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                    </div>
                    <div class="flex flex-wrap py-2 gap-2">

                        <div v-for="equipment in equipmentDetails" :key="equipment" class="bg-gray-200 rounded text-sm flex items-center select-none">
                            <div class="px-2">{{ equipment.name }}</div>
                        </div>

                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <button @click="viewMore" type="button" class="inline-flex justify-center rounded-md border border-bestbuy-600 bg-bestbuy-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-bestbuy-700 focus:outline-none ml-3 w-auto text-sm transition duration-200">Open</button>
            <button @click="close" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">Close</button>
        </template>
    </Modal>

</template>

<script>
    import { store } from '../store/store.js'
    import Modal from '../components/Modal.vue';
    
    export default {
        components: {
            Modal
        },
        props: ['isOpen', 'closeForm', 'reservation'],
        data() {
            return {
                defaultUsrImg: store.user.defaultUserImg
            }
        },
        methods: {
            close() {
                this.closeForm();
            },
            deleteReservation() {
                const confirmation = confirm("Are you sure you want to delete this reservation?")
                if (confirmation) {
                    store.reservations.deleteReservation(this.reservation._fsid).then(() => {
                        this.close()
                    })
                }
            },
            viewMore() {
                this.$router.push({
                    name:"reservationdetails",
                    params: {
                        id: this.reservation._fsid
                    }
                })
            }
        },
        computed: {
            equipmentDetails() {
                return this.reservation.equipment ? 
                    store.equipment.items.filter(equipment => {
                        return this.reservation.equipment.includes(equipment._fsid)
                    })
                    : []
            }
        }
    }
</script>

<style>

</style>