<template>
    <div>
        <!-- MOBILE SCREEN -->
        <div v-for="reservation in reservations" :key="reservation.id" @click="selectReservation(reservation)" class="md:hidden border-b-2 border-gray-100 py-2">
            <div class="flex gap-2">
                <div class="w-12 h-12 flex-shrink-0 mr-2 sm:mr-3">
                    <div :style="`background-image:url('${ reservation.owner.profileImg ? reservation.owner.profileImg : defaultUsrImg }')`" name="userprofileimage" class="h-[48px] w-[48px] rounded-full bg-center bg-cover cursor-pointer"></div>
                </div>
                <div class="truncate">
                    <div>
                        <div class="text-gray-800 font-bold truncate tracking-wide">{{ reservation.projectName }}</div>
                        <div class="pl-2 font-bold text-xs tracking-wider text-gray-500">{{ reservation.project }}</div>
                    </div>

                    <div class="flex items-start font-medium my-2">
                        <div class="flex items-center gap-4 w-full mx-auto">
                            <div class="text-center">
                                <div class="text-gray-700 text-2xl font-light">{{ reservation._mstart.format('MMM D') }}</div>
                                <div class="text-gray-600 text-sm text-gray-500">{{ reservation._mstart.format('dddd') }}</div>
                            </div>
                            <font-awesome-icon class="text-gray-300" icon="arrow-right-long" />
                            <div class="text-center">
                                <div class="text-gray-700 text-2xl font-light">{{ reservation._mend.format('MMM D') }}</div>
                                <div class="text-gray-600 text-sm text-gray-500">{{ reservation._mend.format('dddd') }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- LARGE SCREEN -->
        <div class="hidden md:block flex flex-col justify-center h-full">
            <div class="">
                <div class="overflow-hidden w-full">
                    <table class="table-fixed w-full border-collapse border-2 border-gray-100">
                        <thead class="text-xs font-semibold uppercase text-white bg-bestbuy-800">
                            <tr class="">
                                <th class="p-2 whitespace-nowrap w-5/12">
                                    <div class="font-semibold text-left">Project</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Start Date</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">End Date</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="text-left font-semibold">Owner</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-100">
                            <tr v-for="reservation in reservations" :key="reservation.id" @click="selectReservation(reservation)" class="w-full overflow-hidden hover:bg-gray-100 cursor-pointer">
                                <td class="p-2 overflow-hidden w-6/12">
                                    <div class="text-left">
                                        <div class="text-bestbuy-900 font-bold truncate">{{ reservation.projectName }}</div>
                                        <div class="pl-2 font-medium text-sm text-gray-500">{{ reservation.project }}</div>
                                    </div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="text-left ">{{ reservation._mstart.format('MM/DD/YYYY') }}</div>
                                    <div class="text-left text-sm text-gray-500">{{ reservation._mstart.format('dddd') }}</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="text-left ">{{ reservation._mend.format('MM/DD/YYYY') }}</div>
                                    <div class="text-left text-sm text-gray-500">{{ reservation._mend.format('dddd') }}</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="flex items-center overflow-hidden">
                                        <div class="w-12 h-12 flex-shrink-0 mr-2 sm:mr-3">
                                            <div :style="`background-image:url('${ reservation.owner.profileImg ? reservation.owner.profileImg : defaultUsrImg }')`" name="userprofileimage" class="h-[48px] w-[48px] rounded-full bg-center bg-cover cursor-pointer"></div>
                                        </div>
                                        <div class="text-gray-800 truncate">{{ reservation.owner.name }}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- RESERVATION Details -->
        <ReservationDetails :isOpen="forms.showDetails" :closeForm="closeDetailsForm" :reservation="selectedReservation" />
    </div>
</template>

<script>
import { store } from '../store/store.js'
    import ReservationDetails from '../components/ReservationDetails.vue'

    export default {
        components: {
            ReservationDetails
        },
        props: ['reservations'],
        data() {
            return {
                selectedReservation:{},
                forms:{
                    showNew:false,
                    showDetails:false
                },
                defaultUsrImg: store.user.defaultUserImg
            }
        },
        methods: {
            selectReservation(reservation) {
                this.selectedReservation = reservation;
                this.forms.showDetails = true;
            },
            closeDetailsForm() {
                this.selectedReservation = {};
                this.forms.showDetails = false;
            }
        }
    }
</script>