<template>
    <div class="h-full">
        <div class="inline-block">
            <div :class="classFromStatus" class="text-xs px-3 py-[2px] rounded-full capitalize font-medium">{{ status ? status : 'not started' }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['status'],
    computed: {
        classFromStatus() {
            const stat = this.status ? this.status.toLowerCase() : '';
            switch(stat) {
                case 'active':
                    return 'bg-green-200 text-green-800';
                    break;
                case 'complete':
                    return 'bg-indigo-200 text-indigo-800';
                    break;
                case 'not started':
                    return 'bg-gray-200 text-gray-800';
                    break;
                default:
                    return 'bg-gray-200 text-gray-800';
                    break;
            }
        }
    }
}
</script>