<template>
    <section class="max-w-screen-2xl mx-auto px-4">
      <div class="py-4 flex justify-between items-center ">
        <PageTitle :title="'Manage Users'" @click="printUsers"></PageTitle>
        <button @click="addUser" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
          <font-awesome-icon class="mr-2" icon="fa-plus" />
          <span class="hidden sm:inline">Add User</span>
        </button>
        <button @click="addUser" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
          <font-awesome-icon class="text-black text-2xl absolute" icon="fa-plus" />
        </button>
      </div>
      <!-- HR -->
      <div class="border-b-2 border-gray-300"></div>
      <!-- SEARCH & FILTERS -->
      <div class="py-4 flex gap-4">
        <input type="text" v-model="searchQuery" class="form-control max-w-md block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" placeholder="Search users" />
      </div>
      <!-- USERS -->
      <div class="flex flex-col ">
        <div v-for="user in filteredUsers" :key="user._fsid" @click="selectUser(user)" class="group flex items-center justify-between py-2 border-b-2 border-slate-100 cursor-pointer hover:bg-gray-100">
          <div class="flex gap-2 items-center px-4">
            <div class="mx-auto align-center">
              <div :style="`background-image:url('${ user.photoURL ? user.photoURL : defaultUserImg }')`" class="flex h-9 w-9 rounded-full bg-center bg-cover cursor-pointer justify-center items-center relative shadow-md">
                <div class="absolute w-full h-full bg-black rounded-full opacity-0 transition duration-200 z-10"></div>
              </div>
            </div>
            <div class="text-center text-gray-800 font-medium text-lg truncate">{{ user.displayName ? user.displayName : '--' }}</div>
          </div>
          <div class="hidden sm:block px-4">
            <div>{{ user.email }}</div>
          </div>
        </div>
      </div>

      <div v-if="filteredUsers.length == 0 && !isLoading" class="px-2 sm:px-4 italic font-light text-gray-500">No users found.</div>
      <div v-if="isLoading" class="px-2 sm:px-4 italic font-light text-gray-500">Loading...</div>

      <!-- USER MODAL -->
      <Modal v-if="'displayName' in workingUserValues && showModal" @close="cancelModal" :class="showModal ? 'opacity-100 visible' : 'opacity-0 invisible'">
        <template v-slot:header>
            User Details
        </template>
        <template v-slot:body>
          <div v-if="isModalUpdating" class="absolute inset-0 bg-white bg-opacity-50 z-50">
            <div class="flex h-full justify-center items-center italic font-medium text-medium">{{ modalUpdateMsg }}</div>
          </div>
          <div :class="isModalUpdating ? 'blur' : ''" class="flex flex-col gap-4">
            <div class="mx-auto">
              <div :style="`background-image:url('${ imageUpdate.newImageUrl ? imageUpdate.newImageUrl : workingUserValues.photoURL }')`" @click="formMode == 'edit' ? selectInput() : false" :class="formMode == 'edit' ? 'cursor-pointer' : ''" class="group flex h-48 w-48 rounded-full bg-center bg-cover justify-center items-center relative shadow-md">
                  <div v-if="formMode == 'edit'" class="absolute w-full h-full bg-black rounded-full opacity-0 group-hover:opacity-50 transition duration-200 z-10"></div>
                  <div v-if="formMode == 'edit'" class="absolute w-full h-full flex items-center justify-center z-20 text-white opacity-0 group-hover:opacity-100 z-20">Upload new image</div>
              </div>
              <input type="file" @change="imageInputChange" id="profileimageinput" style="display:none" accept="image/png, image/jpeg" />
            </div>
            <div class="col-span-12">
              <label for="display-name" class="block font-medium text-gray-700">Display Name</label>
              <input :disabled="formMode == 'view'" v-model="workingUserValues.displayName" name="display-name" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            </div>
            <div class="col-span-12">
              <label for="email" class="block font-medium text-gray-700">Email</label>
              <input :disabled="formMode == 'view'" v-model="workingUserValues.email" name="email" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            </div>
            <div v-if="formMode == 'new'" class="col-span-12">
              <label for="email" class="block font-medium text-gray-700">Password</label>
              <input :disabled="formMode == 'view'" v-model="newUserPW" name="email" class="form-control mt-1 block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button @click="editUser" v-if="formMode == 'view'" class="sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
            Edit User
          </button>
          <button @click="closeModal" v-if="formMode == 'view'" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">
            Close
          </button>
          <button @click="deleteUser" v-if="formMode == 'edit'" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto text-sm transition duration-200">
            Delete
          </button>
          <button @click="() => formMode=='new' ? createUser() : saveUserUpdate()" v-if="formMode != 'view'" :disabled="!(workingUserValues.displayName.length>0 && workingUserValues.email.length>0 && (formMode != 'new' || newUserPW.length>5))" type="button" class="inline-flex justify-center rounded-md border border-indigo-800 bg-indigo-800 px-4 py-2 font-medium text-white shadow-sm focus:outline-none ml-3 w-auto text-sm hover:bg-indigo-900 hover:border-indigo-900 transition duration-200 disabled:opacity-50">
            {{ formMode == 'new' ? 'Create User' : 'Save' }}
          </button>
          <button @click="cancelModal" v-if="formMode == 'edit' || formMode == 'new'" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">
            Cancel
          </button>
        </template>
      </Modal>

    </section>
  </template>
  
  <script>
    import { store } from '../store/store.js'
    import PageTitle from '../components/PageTitle.vue';
    import Modal from '../components/Modal.vue';
    import { getStorage, ref, uploadBytes } from "firebase/storage";
  
    export default {
      components: {
        PageTitle,
        Modal
      },
      data() {
        return {
            isLoading:true,
            users: [],
            showModal:false,
            formMode:"view",
            selectedUser:{},
            searchQuery: "",
            defaultUserImg: store.user.defaultUserImg,
            workingUserValues:{},
            isModalUpdating: false,
            modalUpdateMsg: "",
            newUserPW:"",
            imageUpdate:{
                newImageUrl:"",
                success:true,
                messaage:""
            }
        }
      },
      methods: {
        selectUser(usr) {
          console.log(usr);
          this.formMode = "view";
          this.imageUpdate.newImageUrl = "";
          this.selectedUser = usr;
          this.workingUserValues = {...this.selectedUser}
          this.showModal = true;
        },
        addUser() {
          this.selectedUser.displayName = "";
          this.selectedUser.email = "";
          this.selectedUser.photoURL = store.user.defaultUserImg;
          this.workingUserValues = {...this.selectedUser}
          this.formMode = "new";
          this.showModal = true;
        },
        editUser() {
          this.formMode = "edit";
          this.workingUserValues = {...this.selectedUser}
        },
        printUsers() {
          console.log(store.user.allUsers)
        },
        closeModal() {
          this.showModal = false;
          this.imageUpdate.newImageUrl = "";
        },
        cancelModal() {
          if (this.formMode == "edit") {
            // Cancel edit mode
            this.workingUserValues = {...this.selectedUser}
            this.formMode = "view";
            this.imageUpdate.newImageUrl = "";
          } else {
            // Close modal
            this.showModal = false;
            this.newUserPW = "";
            this.imageUpdate.newImageUrl = "";
          }
        },
        async saveUserUpdate() {
          console.log(":: fx - saveUserUpdate ::");
          this.modalUpdateMsg = "Updating user..."
          this.isModalUpdating = true;

          try {
            if (this.imageUpdate.newImageUrl) {
              await this.updateUserProfileImage();
              this.workingUserValues.photoURL = `https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/UserProfileImages%2F${this.selectedUser.uid}%2Fprofileimage.jpg?alt=media`;
            }
            store.user.adminUpdateUser(this.workingUserValues).then(updatedUsr => {
              store.user.getAllUsersV2(true).then(allusers => {
                this.users = allusers;
                this.selectedUser = {...updatedUsr};
                this.workingUserValues = {...this.selectedUser}
                this.formMode = "view";
                this.isModalUpdating = false;
                this.modalUpdateMsg = "";

                // Log Event
                if (store.user.user) {
                  store.analytics.logAppEvent({
                    uid: store.user.user.uid,
                    name: store.user.user.displayName,
                    email: store.user.user.email,
                    action: "Edit",
                    type: "Manage Users",
                    target: updatedUsr.uid,
                    url: window.location.href,
                    datetime: new Date()
                  });
                }

              })
          })

          } catch(err) {
            alert("Error updating user");
            console.log(err);
            this.formMode = "view";
            this.isModalUpdating = false;
            this.modalUpdateMsg = "";
          }

        },
        deleteUser() {
          const confirmation = confirm("Are you sure you want to delete this user?");
          this.modalUpdateMsg = "Deleting user..."
          this.isModalUpdating = true;
          if (confirmation) {
            console.log(":: fx - deleteUser ::");
            store.user.deleteUser(this.workingUserValues).then(success => {
              if (!success) {
                alert("Error deleting user");
                console.log("ERROR deleting user");
              }

              // Log Event
              if (store.user.user) {
                store.analytics.logAppEvent({
                  uid: store.user.user.uid,
                  name: store.user.user.displayName,
                  email: store.user.user.email,
                  action: "Delete",
                  type: "Manage Users",
                  target: this.workingUserValues.uid,
                  url: window.location.href,
                  datetime: new Date()
                });
              }
        
              this.closeModal();
              this.formMode = "view";
              this.isModalUpdating = false;
              this.modalUpdateMsg = "";
              this.isLoading = true;
              this.workingUserValues = {};
              this.selectedUser = {};
              store.user.getAllUsersV2(true).then(results => {
                  this.users = results;
                  this.isLoading = false;
              })
              
            })
          }
        },
        createUser() {
          console.log(":: fx - createUser ::");
          this.modalUpdateMsg = "Creating user...";
          this.isModalUpdating = true;
          store.user.createUser({...this.workingUserValues,password:this.newUserPW}).then(updatedUsr => {
            store.user.getAllUsersV2(true).then(allusers => {
              this.users = allusers;
              this.selectedUser = {...updatedUsr};
              this.workingUserValues = {...this.selectedUser}
              this.formMode = "view";
              this.isModalUpdating = false;
              this.modalUpdateMsg = "";
              this.newUserPW = "";

              // Log Event
              if (store.user.user) {
                store.analytics.logAppEvent({
                  uid: store.user.user.uid,
                  name: store.user.user.displayName,
                  email: store.user.user.email,
                  action: "Create",
                  type: "Manage Users",
                  target: updatedUsr.uid,
                  url: window.location.href,
                  datetime: new Date()
                });
              }

            })
          }).catch(err => {
            alert("Error updating user");
            console.log(err);
            this.formMode = "view";
            this.isModalUpdating = false;
            this.modalUpdateMsg = "";
            this.newUserPW = "";
          })
        },
        selectInput() {
            document.querySelector("#profileimageinput").click();
        },
        imageInputChange(event) {
            const fileList = event.target.files;
            if (fileList && fileList.length != 0) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageUpdate.newImageUrl = e.target.result
                }
                reader.readAsDataURL(fileList[0])
            }
        },
        updateUserProfileImage() {
          console.log(":: updateUserProfileImage ::")
          if (this.selectedUser.uid) {
            const path = 'UserProfileImages/' + this.selectedUser.uid + "/profileimage.jpg";
            const file = document.getElementById("profileimageinput").files[0];
            const storage = getStorage();
            const storageRef = ref(storage,path);
            return uploadBytes(storageRef,file)
            // return uploadBytes(storageRef,file).then((snapshot) => {
            //     this.update.newImageUrl = "https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/" + encodeURIComponent(path) + '?alt=media';
            // })
          } else {
            return new Promise(resolve => resolve())
          }
        }
      },
      computed: {
        filteredUsers() {
          // Filter users based on search
          const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
          return this.users.filter(function (usr) {
            return search == '' ||
              (usr.name && usr.name.toLowerCase().includes(search)) || 
              (usr.email && usr.email.toLowerCase().includes(search)) ||
              (usr.title && usr.title.toLowerCase().includes(search))
          })
        }
      },
      mounted() {
        // Log Event
        if (store.user.user) {
          store.analytics.logAppEvent({
            uid: store.user.user.uid,
            name: store.user.user.displayName,
            email: store.user.user.email,
            action: "View",
            type: "Manage Users",
            target: "Manage Users",
            url: window.location.href,
            datetime: new Date()
          });
        }

        // Fetch all users
        store.user.getAllUsersV2(true).then(results => {
            this.users = results;
            this.isLoading = false;
        })
  
      },
      unmounted() {
        
      }
    }
  </script>