import { reactive } from 'vue'
import { getAuth, updateProfile, signOut } from "firebase/auth"
import { db } from '../firebase.js'
import { collection, query, where, limit, getDocs, addDoc, update, updateDoc , onSnapshot, deleteDoc, doc, orderBy, FieldValue, getDoc } from "firebase/firestore";
import { getAnalytics,logEvent  } from "firebase/analytics";
import moment from 'moment';
import { Equipment, Image, Reservation } from '../utilities/utilities.js';
import convertArrayToCSV from 'convert-array-to-csv';
import Bowser from 'bowser';

const _appversion = "10.23";

const analytics = getAnalytics();

export const equipmentStatus = reactive({
    values: [
        {value:"Active",class:"bg-green-100 text-green-800 border-green-200 border",warning:false},
        {value:"Donated",class:"bg-orange-100 text-orange-800 border-orange-200 border",warning:true},
        {value:"Trash",class:"bg-orange-100 text-orange-800 border-orange-200 border",warning:true},
        {value:"Broken",class:"bg-orange-100 text-orange-800 border-orange-200 border",warning:true},
        {value:"Transfer",class:"bg-orange-100 text-orange-800 border-orange-200 border",warning:true}
    ],
    unvailableStatus: ["Donated","Trash","Broken","Transfer"]
})

export const store = reactive({
    /******************************/
    // STORE
    /******************************/
    equipment:{
        items:[],
        categories: [],
        subscriptions:{},
        unsubscribeToEquipment:null,
        isLoading: false,
        async getEquipment(force) {
            console.log(":: fx - getEquipment ::");
             // Listen to all equipment
            // Check if we're already listening
            if (this.unsubscribeToEquipment) {
                // Already listening to equipment changes
            } else {
                const q = query(collection(db,"equipment"),orderBy("name"))
                this.unsubscribeToEquipment = onSnapshot(q, (querySnapshot) => {
                    const results = [];
                    querySnapshot.forEach((doc) => {
                        let imgs = (doc.data().images && doc.data().images.length>0) ? doc.data().images : [new Image(null)];
                        let item = new Equipment(doc.data());
                        results.push(Object.assign(item, {
                            images: imgs,
                            _fsid: doc.id
                        }))
                    });

                    this.setEquipment(results);

                    // Notify subscribers
                    for (const [key, value] of Object.entries(this.subscriptions)) {
                        this.subscriptions[key]()
                    }
                });
            }
        },
        getAllEquipment(force) {
            return new Promise((resolve,reject) => {
                if (!this.isLoading) {
                    this.isLoading = true;
                    const localEquipment = localStorage.getItem('_equipment');
                    const lastRefresh = localStorage.getItem('_equipmentRefreshed');
                    let isExpired = true;
                    if (lastRefresh) {
                        const hrsSinceRefresh = (new Date() - new Date(lastRefresh)) / (60*60*1000)
                        isExpired = !!(hrsSinceRefresh > 24);
                    }
                    let results =[];
                    if (localEquipment && !isExpired && !force) {
                        console.log(":: equipment :: (local storage) ");
                        this.setEquipment(JSON.parse(localEquipment));
                        // Notify subscribers
                        for (const [key, value] of Object.entries(this.subscriptions)) {
                            this.subscriptions[key](results)
                        }
                        this.isLoading = false;
                        resolve(this.items)
                    } else {
                        getDocs(query(collection(db, "equipment"),orderBy("name"),limit(1000))).then(querySnapshot => {
                            querySnapshot.forEach((doc) => {
                                let imgs = (doc.data().images && doc.data().images.length>0) ? doc.data().images : [new Image(null)];
                                let item = new Equipment(doc.data());
                                results.push(Object.assign(item, {
                                    images: imgs,
                                    _fsid: doc.id
                                }))
                            });
                            console.log(":: equipment :: (server) ");
                            this.setEquipment(results)
                            // Notify subscribers
                            for (const [key, value] of Object.entries(this.subscriptions)) {
                                this.subscriptions[key](results)
                            }
                            this.isLoading = false;
                            resolve(this.items)
                        });
                        
                    }

                }
            })
        },
        setEquipment(val) {
            localStorage.setItem('_equipment',JSON.stringify(val));
            localStorage.setItem('_equipmentRefreshed',new Date());
            //this.items = new Array();
            this.items = val;
        },
        addEquipment(o) {
            return new Promise((resolve,reject) => {
                const col = collection(db, "equipment")
                addDoc(col,o).then((doc) => {
                    resolve(doc);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        async getEquipmentCategories() {
            let results =[];
            const querySnapshot = await getDocs(collection(db, "equipmentCategories"));
            querySnapshot.forEach((doc) => {
                results.push(Object.assign(doc.data(), {
                    _fsid: doc.id
                }))
            });
            this.categories = results;
            console.log(":: rs - equipmentCategories ::",results)
        },
        subscribeToChanges(name,fx) {
            console.log(`:: fx - subscribeToChanges (${name}) ::`)
            if (!Object.hasOwnProperty(this.subscriptions,name)) {
                this.subscriptions[name] = fx
            }
        },
        unsubscribeToChanges(name) {
            console.log("Unregistering subscription: ", name)
            if (Object.hasOwnProperty(this.subscriptions,name)) {
                delete this.subscriptions[name];
            }
            console.log(this.subscriptions)
        },
        async getEquipmentById(fsid) {
            console.log(`:: fx - getEquipmentById (${fsid}) ::`);
            const docRef = doc(db,"equipment",fsid);
            return new Promise((resolve,reject) => {
                getDoc(docRef).then(docSnap => {
                    if (docSnap.exists()) {
                        resolve(docSnap.data());
                    } else {
                        reject();
                    }
                });
            });
        },
        updateLocalEquipmentItem(fsid,item) {
            console.log(`:: fx - updateLocalEquipmentItem (${fsid}) ::`,item);
            let updateItems = JSON.parse(JSON.stringify(this.items));
            const objIndex = updateItems.findIndex(obj => obj['_fsid'] == fsid);
            if (objIndex >= 0) {
                // Found equipment item in local collection
                updateItems[objIndex] = item;

            } else {
                // New equipment item
                updateItems.push(item)
            }
            this.setEquipment(updateItems);
        },
        deleteLocalEquipmentItem(fsid) {
            console.log(`:: fx - deleteLocalEquipmentItem (${fsid}) ::`);
            let updateItems = JSON.parse(JSON.stringify(this.items));
            const objIndex = updateItems.findIndex(obj => obj['_fsid'] == fsid);
            if (objIndex >= 0) {
                updateItems.splice(objIndex,1);
                this.setEquipment(updateItems);
            }
        },
        async exportAsCSV() {
            console.log(`:: fx - exportAsCSV ::`);
            const columns = [
                /********************************
                    name = property name in firebase
                    title = display name in output CSV
                    quotes = wrap value in double quotes
                *********************************/
                {name:"name",title:"Name",quotes:false},
                {name:"_fsid",title:"ID",quotes:false},
                {name:"category",title:"Category",quotes:false},
                {name:"subcategory",title:"Subcategory",quotes:false},
                {name:"equipmentId",title:"Equipment ID",quotes:false},
                {name:"baseLocation",title:"Location",quotes:false},
                {name:"purchasePrice",title:"Purchase Price",quotes:false},
                {name:"purchaseDate",title:"Purchase Date",quotes:false},
                {name:"serialNumber",title:"Serial Number",quotes:false},
                {name:"status",title:"Status",quotes:false},
                {name:"tags",title:"Tags",quotes:false},
                {name:"description",title:"Description",quotes:false},
                {name:"images",title:"Images",quotes:false},
            ]
            return new Promise((resolve,reject) => {
                this.getAllEquipment(true).then((allEquipment) => {
                    let header = columns.map(_ => _.title);
                    let data = []
                    let row = [];
                    allEquipment.forEach(item => {
                        columns.forEach(field => {
                            let val = ''
                            if (field.name == 'purchaseDate') {
                                val = item.purchaseDate ? moment(item.purchaseDate.seconds * 1000).format('MM/DD/YYYY') : '';
                            } else if (field.name == 'tags') {
                                val = item.tags ? item.tags.join(",") : "";
                            } else if (field.name == 'purchasePrice') {
                                val = item.purchasePrice ? item.purchasePrice.toLocaleString('en-US',{ style: 'currency', currency: 'USD' }) : "-";
                            } else if (field.name == 'images') {
                                val = item.images ? item.images.map(_ => _.url).join("||") : "";
                            } else {
                                val = item[field.name];
                            } 
                            row.push((field.quotes ? '"' : '') + val + (field.quotes ? '"' : ''));
                        })
                        data.push(row);
                        row = [];
                    });


                    const csv = convertArrayToCSV(data,{
                        header,
                        separator:","
                    })

                    // CSV content has been generated, now create downloadable blob file
                    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const filename = "EquipmentExport.csv"
                    if (navigator.msSaveBlob) { // IE 10+
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            var url = URL.createObjectURL(blob);
                            link.setAttribute("href", url);
                            link.setAttribute("download", filename);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }

                    resolve();
                })
                
            })
        },
        async getEquipmentFeedback(fsid) {
            return new Promise((resolve,reject) => {
                const base = "https://firebasestorage.googleapis.com/v0/b/"
                let results =[];
                let q = query(collection(db, "equipmentFeedback"),orderBy("created","desc"),where("equipment", "==", fsid));
                return getDocs(q).then(querySnapshot => {
                    querySnapshot.forEach((doc) => {
                        results.push(Object.assign(doc.data(), {
                            // Store full URL of image
                            images: doc.data().images.map(img => {
                                img['url'] = base + img.bucket + "/o/" + encodeURIComponent(img.fullPath) + "?alt=media";
                                return img;
                            }),
                            _fsid:doc.id
                        }));
                    });
                    
                    resolve(results);
                });
            });
        },
        async addEquipmentFeedback(o) {
            return new Promise((resolve,reject) => {
                const col = collection(db, "equipmentFeedback")
                addDoc(col,o).then((doc) => {
                    resolve(doc);
                }).catch(error => {
                    console.log(":: ERROR fx - addEquipmentFeedback ::");
                    reject(error);
                })
            })
        },
        async deleteEquipmentFeedback(fsid) {
            console.log(`:: fx - deleteEquipmentFeedback (${fsid}) ::`);
            return new Promise((resolve,reject) => {
                deleteDoc(doc(db, "equipmentFeedback", fsid)).then(resolve);
            })
        }
    },
    reservations:{
        items:[],
        notes:[],
        subscriptions:{},
        unsubscribeToReservations:null,
        unsubscribeToReservationNotes:null,
        async getReservationsForEquipment(equipmentId) {
            let results =[];
            const reservationsRef = collection(db, "reservations");
            const q = query(reservationsRef, where("equipment", "array-contains", equipmentId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                results.push(Object.assign(
                    doc.data(),
                    {
                        _fsid:doc.id,
                        _mstart: moment(doc.data().reservationStart.seconds * 1000),
                        _mend: moment(doc.data().reservationEnd.seconds * 1000),
                        start: new Date(doc.data().reservationStart.seconds * 1000),
                        end: new Date(doc.data().reservationEnd.seconds * 1000),
                        title:`(${doc.data().project}) ${doc.data().projectName}`,
                        _fpstart: moment(doc.data().reservationStart.seconds * 1000).format('MM/DD/YYYY H:mm'),
                        _fpend: moment(doc.data().reservationEnd.seconds * 1000).format('MM/DD/YYYY H:mm')
                    })
                )
            });
            return results;
        },
        async createReservation(event) {
            console.log(":: fx - createReservation: ", event);
            return new Promise((resolve,reject) => {
                addDoc(collection(db, "reservations"), event).then(docRef => {
                    resolve(docRef);
                }).catch(error => {
                    reject();
                });
            })
        },
        async deleteReservation(id) {
            console.log(":: fx - deleteReservation: ", id)
            await deleteDoc(doc(db, "reservations", id));
        },
        async updateReservation(event,id) {
            const reservationsRef = doc(db, "reservations", id);
            // await updateDoc(reservationsRef, event);
            return new Promise((resolve,reject) => {
                updateDoc(reservationsRef, event).then(() => {
                    resolve();
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            }) 
        },
        setReservations(val) {
            this.items = val;
        },
        setReservationNotes(val) {
            this.notes = val;
        },
        listenToReservations() {
            console.log(":: fx - listenToReservations ::");
            // Listen to all reservations with a start date > 1st of 3 months ago
            // Check if we're already listening
            if (this.unsubscribeToReservations) {
                // Already listening to reservation changes
            } else {
                const today = new Date();
                const firstOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 3, 1);
                const q = query(collection(db,"reservations"), where("reservationStart", ">", firstOfLastMonth))
                this.unsubscribeToReservations = onSnapshot(q, (querySnapshot) => {
                    const reservations = [];
                    querySnapshot.forEach((doc) => {
                        reservations.push(Object.assign(
                            new Reservation(doc.data()),
                            {
                                _fsid:doc.id
                            }
                            )
                        );
                    });
                    this.setReservations(reservations);

                    // Notify subscribers
                    for (const [key, value] of Object.entries(this.subscriptions)) {
                        this.subscriptions[key]()
                    }
                });
            }
        },
        subReservationNotes(reservationId) {
            console.log(":: fx - subReservationNotes ::",reservationId);
            // Listen to reservation's notes
            if (reservationId == null) {
                // No reservationId
                console.log(":: No reservationId",reservationId)
            } else {
                const q = query(collection(db,"reservationNotes"),orderBy("created","desc"), where("reservation", "==", reservationId))
                console.log(q)
                this.unsubscribeToReservationNotes = onSnapshot(q, (querySnapshot) => {
                    const reservationNotes = [];
                    querySnapshot.forEach((doc) => {
                        reservationNotes.push(Object.assign(
                            doc.data(),
                            {
                                _fsid: doc.id,
                                _mcreated: moment(doc.data().created.seconds * 1000)
                            })
                        );
                    });
                    console.log(":: Reservation notes :: ", reservationNotes);
                    this.setReservationNotes(reservationNotes);

                    // Notify subscribers
                    for (const [key, value] of Object.entries(this.subscriptions)) {
                        this.subscriptions[key](reservationNotes)
                    }
                });
            }
        },
        unsubReservationNotes() {
            if (this.unsubscribeToReservationNotes) {
                console.log(":: fx - unsubFromReservationNotes ::")
                this.unsubscribeToReservationNotes();
            }
        },
        subscribeToChanges(name,fx) {
            console.log(`:: fx - subscribeToChanges (${name}) ::`)
            if (!Object.hasOwnProperty(this.subscriptions,name)) {
                this.subscriptions[name] = fx
            }
        },
        unsubscribeToChanges(name) {
            console.log("Unregistering subscription: ", name)
            if (Object.hasOwnProperty(this.subscriptions,name)) {
                delete this.subscriptions[name];
            }
            console.log(this.subscriptions)
        },
        async getReservationNotes(reservationId) {
            console.log(":: fx - getReservationNotes - ",reservationId," ::");
            if (reservationId == null) {
                return []
            } else {
                let results = [];
                const reservationNotesRef = collection(db, "reservationNotes");
                const q = query(reservationNotesRef, where("reservation", "==", reservationId));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    results.push(Object.assign(
                        doc.data(),
                        {
                            _fsid: doc.id,
                            _mcreated: moment(doc.data().created.seconds * 1000)
                        })
                    )
                });
                return results;
            }
        },
        async getReservationVersions(reservationId) {
            console.log(":: fx - getReservationVersions - ",reservationId," ::");
            return new Promise((resolve,reject) => {
                if (reservationId == null) {
                    resolve([]);
                } else {
                    let results = [];
                    const reservationVersionsRef = collection(db, `reservations/${reservationId}/versions`);
                    const q = query(reservationVersionsRef,orderBy("modifiedDate","asc"));
                    getDocs(q).then(querySnapshot => {
                        querySnapshot.forEach((doc) => {
                            results.push(Object.assign(
                                doc.data(),
                                {
                                    _fsid: doc.id,
                                })
                            )
                        });
                        resolve(results)
                    }).catch(error => {
                        console.log(error);
                        reject(error);
                    })
                    
                }
            })
        },
        async addReservationVersion(reservationId,item) {
            console.log(":: fx - addReservationVersion - ",reservationId,item," ::");
            return new Promise((resolve,reject) => {
                if (reservationId == null || item == null || reservationId == undefined || item == undefined) {
                    reject();
                } else {
                    addDoc(collection(db, `reservations/${reservationId}/versions`), item).then(() => {
                        resolve();
                    }).catch(error => {
                        console.log(error);
                        reject();
                    })
                }
            })
            
        }
    },
    projects: {
        items:[],
        async getProjects(force,getall) {
            console.log(":: getProjects ::",force,getall)
            const lastRefresh = localStorage.getItem('_projectsRefreshed');
            let isExpired = true;
            if (lastRefresh) {
                const hrsSinceRefresh = (new Date() - new Date(lastRefresh)) / (60*60*1000)
                isExpired = !!(hrsSinceRefresh > 24);
            } else {
                force = true;
            }
            if (this.items.length==0 || force || isExpired) {
                // Check local storage
                const existing = localStorage.getItem('_projects');
                if (existing && !force && !isExpired) {
                    this.setProjects(JSON.parse(existing));
                    return existing;
                } else {
                    console.log(":: fx - getProjects ::");
                    let results =[];
                    let q = query(collection(db, "spprojects"),where("status", "!=", "Inactive"));
                    if (getall) {
                        q = null;
                        q = query(collection(db, "spprojects"), orderBy("projectnumber","desc"));
                    }
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        results.push(Object.assign(doc.data(), {
                            _fsid:doc.id,
                            _projectIdName: `(${doc.data().projectnumber}) ${doc.data().projecttitle}`
                        }))
                    });
                    results = results.sort((a,b) => {
                        if (a.projectnumber > b.projectnumber) {
                            return -1;
                        } else if(a.projectnumber < b.projectnumber) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    this.setProjects(results);
                    localStorage.setItem('_projects',JSON.stringify(results));
                    localStorage.setItem('_projectsRefreshed',new Date());
                    return results;
                }
            } else {
                return this.items;
            }
        },
        setProjects(val) {
            this.items = val
        }
    },
    user:{
        allUsers: [],
        user:null,
        userRoles:[],
        userImg:"",
        defaultUserImg: "https://firebasestorage.googleapis.com/v0/b/bby-asset-tracker.appspot.com/o/UserProfileImages%2Fdefault.png?alt=media",
        setUser(usr) {
            this.user = usr;
            this.userImg = (this.user && this.user.photoURL) ? `${this.user.photoURL}&v=${new Date().valueOf()}` : this.defaultUserImg;
            if (usr) {
                this.getUserRoles();
            }
        },
        setUsers(usrs) {
            console.log(":: fx - setUsers", usrs);
            this.allUsers = usrs;
            localStorage.setItem('_users',JSON.stringify(usrs));
        },
        async updateUser(updateObject) {
            const auth = getAuth();
            return new Promise((resolve,reject) => {
                updateProfile(auth.currentUser, updateObject).then(() => {
                    this.userImg = (this.user && this.user.photoURL) ? `${this.user.photoURL}&v=${new Date().valueOf()}` : this.defaultUserImg
                    resolve(this.user);
                }).catch((error) => {
                    console.log(error);
                    reject();
                })
            })
        },
        async getUserRoles() {
            console.log(":: fx - getUserRoles ::");
            const uid = this.user['uid'];
            if (uid != null) {
                let results = [];
                const querySnapshot = await getDocs(collection(db, "roles"));
                querySnapshot.forEach((doc) => {
                    const members = doc.data().members;
                    if (members.includes(uid)) {
                        results.push(doc.id)
                    }
                });
                this.userRoles = results;
                console.log(":: userRoles - ", results);
            }
        },
        printUser: function(property) {
            if (this.user == null) {
                console.log(null);
            }
            else if (property) {
                console.log(this.user[property])
            } else {
                console.log(this)
            }
        },
        getAllUsers(force) {
            console.log(":: fx - getAllUsers ::");
            return new Promise((resolve,reject) => {
                // Check local storage
                const existing = localStorage.getItem('_users');
                if (existing && !force) {
                    this.setUsers(JSON.parse(existing));
                    resolve(existing);
                } else {
                    getDocs(collection(db, "users")).then(res => {
                        console.log(":: fx - getAllUsers(" + force + ") ::")
                        let results = [];
                        res.docs.forEach((doc) => {
                            results.push(Object.assign(doc.data(), {
                                _fsid: doc.id,
                            }))
                        });
                        this.setUsers(JSON.parse(JSON.stringify(results)));
                        resolve(results)
                    }).catch(error => {
                        console.log(error);
                        reject();
                    })
                };
            });
        },
        getAllUsersV2(force) {
            console.log(":: fx - getAllUsersV2 ::");
            // This calls a custom Cloud Function to fetch users from the auth list
            const existing = localStorage.getItem('_users');
            if (!force && existing ) {
                console.log(":: getAllUsersV2 -- loaded from cache ::");
                this.setUsers(JSON.parse(existing));
                return JSON.parse(existing)
            } else {
                return fetch("https://listusers-emtl5eerea-uc.a.run.app")
                .then(res => res.json())
                .then(body => {
                    console.log(body);
                    const usrs = body.users
                    .map(_ => {
                        return {..._.providerData[0],uid:_.uid}
                    })
                    .map(usr => {
                        return {
                            displayName: 'displayName' in usr ? usr.displayName : '',
                            email: 'email' in usr ? usr.email : '--',
                            photoURL: 'photoURL' in usr ? (usr.photoURL ? usr.photoURL : this.defaultUserImg) : this.defaultUserImg,
                            providerId: 'providerId' in usr ? usr.providerId : '',
                            uid: 'uid' in usr ? usr.uid : ''
                        }
                    })
                    .sort((a,b) => {
                        const name1 = a["displayName"] ? a["displayName"].toLowerCase() : '';
                        const name2 = b["displayName"] ? b["displayName"].toLowerCase() : '';
                        if (name1 > name2) {
                            return 1;
                        } else if(name1 < name2) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    console.log(usrs);
                    this.setUsers(usrs);
                    return usrs;
                }).catch(err => {
                    console.log(err);
                    return []
                })
            }
        },
        adminUpdateUser(usr) {
            // https://updateuser-emtl5eerea-uc.a.run.app
            console.log(":: fx - updateUser ::",usr);
            // This calls a custom Cloud Function to update a user from the auth list
            const request = new Request("https://updateuser-emtl5eerea-uc.a.run.app",{
                method: "POST",
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(usr)
            })
            return fetch(request)
            .then(res => res.json())
            .then(body => {
                console.log(body)
                const photoURL = "photoURL" in body.providerData[0] ? body.providerData[0].photoURL : this.defaultUserImg;
                const updatedUsr = {...body.providerData[0],uid:body.uid,photoURL:photoURL};
                console.log("updatedUsr",updatedUsr);
                return updatedUsr;
            }).catch(err => {
                console.log(err);
                return {}
            })
        },
        createUser(usr) {
            // https://adduser-emtl5eerea-uc.a.run.app
            console.log(":: fx - createUser ::",usr);
            // This calls a custom Cloud Function to fetch users from the auth list
            const request = new Request("https://adduser-emtl5eerea-uc.a.run.app",{
                method: "POST",
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(usr)
            })
            return fetch(request)
            .then(res => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                } else {
                    return res.json()
                }
            })
            .then(body => {
                console.log(body)
                const photoURL = "photoURL" in body.providerData[0] ? body.providerData[0].photoURL : this.defaultUserImg;
                const newUser = {...body.providerData[0],uid:body.uid,photoURL:photoURL};
                console.log("New User",newUser);
                return newUser;
            }).catch(err => {
                console.log(err);
                return {}
            })
            
        },
        deleteUser(usr) {
            // https://deleteuser-emtl5eerea-uc.a.run.app
            console.log(":: fx - deleteUser ::",usr);
            // This calls a custom Cloud Function to fetch users from the auth list
            const request = new Request("https://deleteuser-emtl5eerea-uc.a.run.app",{
                method: "POST",
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(usr)
            })
            return fetch(request)
            .then(res => {
                if (res.ok) {
                    // Success
                    return true
                } else {
                    return false
                }
            }).catch(err => {
                console.log(err);
                return false;
            })

        },
        async signout() {
            if (this.user) {
                console.log(":: signout ::",this.user);
            }
            return new Promise((resolve,reject) => {
                localStorage.removeItem('_equipment');
                localStorage.removeItem('_projects');
                localStorage.removeItem('_users');
                const auth = getAuth();
                signOut(auth);
                resolve();
            })
        },
        syncProfile(usr) {

        }
    },
    catalog:{
        filters:{},
        addFilter(field,value) {
            if (field == "catalog") {
              this.filters = {};
            }
            else {
                this.filters[field] = value;
            }
        },
        removeFilter(fltr) {
            delete this.filters[fltr]
        }
    },
    utilities: {
        async updateRecord(col, id, item) {
            try {
                console.log(`:: fx - updateRecord (${col},${id},${item}) ::`)
                const collectionRef = doc(db, col, id);
                const res = await updateDoc(collectionRef, item);
                return res;
            } catch (err) {
                console.log(err);
                return false;
            }
        },
        async addRecord(col,item) {
            console.log(`:: fx - addRecord (${col},${item}) ::`);
            const res = await addDoc(collection(db, col), item);
            return res;
        },
        async deleteRecord(col,itemId) {
            console.log(":: fx - deleteRecord: ", itemId)
            const res = await deleteDoc(doc(db, col, itemId));
            return res;
        },
        resizeImage(
            url,
            options = {
              maxWidth: 1024,
              maxHeight: 768,
              contentType: "image/jpeg",
              quality: 0.7
            }
          ) {
            const calculateSize = (img) => {
                let w = img.width,
                    h = img.height;
                if (w > h) {
                    if (w > options.maxWidth) {
                    h = Math.round((h * options.maxWidth) / w);
                    w = options.maxWidth;
                    }
                } else {
                    if (h > options.maxHeight) {
                    w = Math.round((w * options.maxHeight) / h);
                    h = options.maxHeight;
                    }
                }
                return [w, h];
            };
            
            return new Promise((resolve) => {
                console.log(":: new Promise ::",url,canvas)
                var img = new Image;
                               

                if (img.complete) {
                    console.log(":: img.complete ::",img);
                }
                img.onload = function() {
                    console.log(":: img.onload ::");
                    const [newWidth, newHeight] = calculateSize(
                        img,
                        options.maxWidth,
                        options.maxHeight
                    );

                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");

                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    console.log(":: img.onload ::");

                    const resultUrl = canvas.toDataURL(options.contentType, options.quality),
                    result = {
                        url: resultUrl,
                        contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
                        b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
                    };
                    
                    canvas.toBlob(
                        (blob) => {
                            result.size = blob.size;
                            console.log(result);
                            resolve(result);
                        },
                        options.contentType,
                        options.quality
                    );

                }

                console.log(url);
                img.src = '';
                img.src = url;

            });

        }
    },
    analytics: {
        logAppEvent: function(event) {
            try {
                const exclusions = [
                    "negpitch@gmail.com",
                    "abandoly@gmail.com",
                    "anthony@advantageux.com"
                ]
                const browser = Bowser.getParser(window.navigator.userAgent);
                event["ua_browser"] = browser.getBrowserName();
                event["ua_browserversion"] = browser.getBrowserVersion();
                event["ua_os"] = browser.getOSName();
                event["ua_osversion"] = browser.getOSVersion();
                event["ua_platform"] = browser.getPlatformType();
                event["appversion"] = _appversion;
                console.log(":: logAppEvent ::",event);
                if (event && exclusions.indexOf(event.email) === -1) {
                    console.log(":: Logging event ::",event);
                    addDoc(collection(db, "activityLogs"), event)
                } else {
                    console.log(":: Logging Bypassed ::");
                }
            } catch(err) {
                console.log(err);
            }
        },
        getStats: function(actionType) {
            actionType = actionType ? actionType : 'All';
            let _getDocs = getDocs(query(collection(db, "activityLogs"),orderBy("datetime", "desc"),limit(500)));
            if (actionType != 'All') {
                _getDocs = getDocs(query(collection(db, "activityLogs"),orderBy("datetime", "desc"),where("action", "==", actionType),limit(500)))
            }
            
            return new Promise((resolve,reject) => {
                _getDocs.then(querySnapshot => {
                    let results = [];
                    querySnapshot.forEach((doc) => {
                        let item = doc.data();
                        results.push(Object.assign(item, {
                            _fsid: doc.id
                        }))
                    })
                    resolve(results);
                }).catch(err => {
                    reject([]);
                })
            })
        }
    },
    _dev: {
        devversion:_appversion,
        version:0,
        setVersion(val) {
            this.version = val;
        },
        async isLatestVersion(_callback) {
            let results =[];
            const q = query(collection(db, "versions"), orderBy("version","desc"), limit(1));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                results.push(Object.assign(doc.data(), {
                    _fsid: doc.id
                }))
            })
            if (results && results.length>0) {
                this.setVersion(results[0].version);
            }
            console.log(":: APP VERSION :: ", this.version);
            console.log(":: DEV VERSION :: ", this.devversion);

            // Compare local version to server version
            const localVersion = localStorage.getItem('_version');
            if (!localVersion || localVersion == 'undefined' || Number(localVersion) < this.version) {
                console.log(":: >> Past app version << ::")
                localStorage.setItem('_version',this.version);
                if (_callback) {;
                    _callback(false);
                };
            } else {
                console.log(":: >> Current app version << ::")
            }
            
        }
    }
})