<template>
  <div class="tracking-wide text-base">
    <Navbar :isLoggedIn="isLoggedIn" />
    <router-view @profile-img-update="refreshProfileImage" />
  </div>
</template>

<script>
import { store } from './store/store.js'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"

export default {
  data() {
    return {
      isLoggedIn: false,
      user:{}
    }
  },
  methods:{
    refreshProfileImage(val) {
      if (this.user) {
        this.user.photoURL = val;
      }
    },
    resizeHandler(e) {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
  },
  mounted() {
    // Set window height CSS variable
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)

    // Handle auth state
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(":: user ::",user);
        store.analytics.logAppEvent({
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          action: "App load",
          type: "Load",
          target: "",
          url: window.location.href,
          datetime: new Date()
        });
        this.isLoggedIn = true;
        this.user = user;
        store.user.setUser(user);

        store.user.syncProfile(user)
        
        // Check Version
        store._dev.isLatestVersion((isLatest) => {
          if (!isLatest) {
            // Force refresh of data
            console.log(":: ==> reloading data ::");
            store.equipment.getAllEquipment(true);
            store.projects.getProjects(true);
          }
        });

        if (store.equipment.items.length == 0){
          // Fetch Equipment
          store.equipment.getEquipment()
        }
        // Listen to reservations
        store.reservations.listenToReservations()
        // Fetch users
        //store.user.getAllUsers();
        
      } else {
        this.isLoggedIn = false;
        this.user = {};
        store.user.setUser(null);
        store.reservations.unsubscribeToReservations();
      }
    });

    /* SET DARK-LIGHT THEME */
    // if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    //   document.documentElement.classList.add('dark');
    // } else {
    //   document.documentElement.classList.remove('dark')
    // }

    // const appEl = document.getElementById('app');
    // appEl.classList.add('dark:bg-gray-900');

  },
  unmounted() {
    // App unmounted
    store.reservations.unsubscribeToReservations()
  },
  created() {
    window.addEventListener("resize",this.resizeHandler)
  },
  destroyed()  {
    window.removeEventListener("resize", this.resizeHandler);
  }
}

</script>

<style>
  /* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap'); */
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,500;1,600;1,700&display=swap');

  :root {
    --doc-height: 100%;
  }

  #app {
    min-height: 100vh;
  }

  html {
    background-color: rgba(255,255,255)/*rgb(243,244,246)*/
  }
  #app {
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1d252c; /*#000;*/
  }
  .bg-image-center {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
  }
  .one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .three-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .four-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .breadcrumb-item:not(:first-child):before {
    border-bottom: 4px solid #0000;
    border-left: 4px solid #000;
    border-top: 4px solid #0000;
    content: " ";
    display: inline-block;
    margin: 0 8px;
  }

</style>