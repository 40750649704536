<template>
    <nav class="gradient-blue">
        <div class="px-4 max-w-screen-2xl mx-auto text-white relative">
            <div class="flex justify-between items-center">
                <div @click="navigateTo('home')" class="flex items-center cursor-pointer">
                    <img class="h-16 w-16" :src="bestbuylogo">
                </div>

                <!-- MOBILE NAV ICON -->
                <div v-if="isLoggedIn" class="md:hidden block absolute top-4 right-8 fixed" @click="toggleMenu">
                    <button aria-label="navigation" type="button" class="md:hidden text-gray-200 transition duration-300 focus:outline-none focus:text-white hover:text-white">
                        <font-awesome-icon class="text-2xl text-3xl" icon="bars" />
                    </button>
                </div>

                <!-- LARGE SCREENS - CENTER -->
                <div v-if="isLoggedIn" class="hidden md:flex">
                    <ul class="hidden md:flex">
                        <li class="text-lg pr-8 ">
                            <button class="transition duration-800 focus:outline-none focus:text-white-500 hover:text-bestyellow-900 font-semibold" :class="{ 'text-bestyellow-900' : currentRouteName == 'home' }" style="text-underline-offset: 8px;" @click="navigateTo('home')" >My Reservations</button>
                        </li>
                        <li class="text-lg pr-8 ">
                            <button class="transition duration-800 focus:outline-none focus:text-white-500 hover:text-bestyellow-900 font-semibold" :class="{ 'text-bestyellow-900' : currentRouteName == 'equipment' }" style="text-underline-offset: 8px;" @click="navigateTo('equipment')" >Equipment</button>
                        </li>
                        <li class="text-lg pr-8 ">
                            <button class="transition duration-800 focus:outline-none focus:text-white-500 hover:text-bestyellow-900 font-semibold" :class="{ 'text-bestyellow-900' : currentRouteName == 'schedule' }" style="text-underline-offset: 8px;" @click="navigateTo('schedule')" >Schedule</button>
                        </li>
                    </ul>
                </div>

                <!-- LARGE SCREENS - RIGHT -->
                <div v-if="isLoggedIn" class="hidden md:flex relative">
                    <div>
                        <!-- PROFILE IMAGE -->
                        <div @click.stop="showProfileMenu = !showProfileMenu" :style="`background-image:url('${ store.user.userImg ? store.user.userImg : defaultUsrImg }')`" name="userprofileimage" class="h-[48px] w-[48px] rounded-full bg-center bg-cover cursor-pointer"></div>
                        <!-- DROPDOWN -->
                        <!-- :class="profileMenuClass" -->
                        <div :class="profileMenuClass" class="absolute z-10 bg-bestbuy-900 w-64 right-0 top-[50px] rounded transition duration-200 origin-top-right shadow-md ">
                            <div @click="navigateTo('myprofile')" class="px-4 py-2 rounded-t cursor-pointer bg-bestbuy-800 hover:opacity-90 ">My Profile</div>
                            <div @click="navigateTo('manageprojects')" class="px-4 py-2 rounded-t cursor-pointer bg-bestbuy-800 hover:opacity-90">Manage Projects</div>
                            <div @click="logout" class="px-4 py-2 cursor-pointer bg-bestbuy-800 hover:opacity-90">Log Out</div>

                            
                            <!-- ADMIN TOOLS -->
                            <div v-if="store.user.userRoles.includes('equipmentmgr') || store.user.userRoles.includes('admin')">
                                <div class="px-4 bg-bestbuy-800 border-b-2 border-bestbuy-700"></div>
                                <div class="px-4 py-2 bg-bestbuy-800 text-xs font-bold text-gray-200">
                                    ADMIN TOOLS
                                </div>
                                <!-- <div @click="exportEquipment" class="pl-6 pr-4 py-2 cursor-pointer bg-bestbuy-800 hover:opacity-90">
                                    Export Equipment
                                </div> -->
                                <div @click="navigateTo('manageusers')" class="pl-6 pr-4 py-2 cursor-pointer bg-bestbuy-800 hover:opacity-90">
                                    Manage Users
                                </div>
                            </div>

                            <!-- VERSION -->
                            <div class="tracking-wider px-4 py-1 bg-bestbuy-800 opacity-90 font-light rounded-b italic text-xs text-white text-center">{{ version }}</div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- MOBILE MENU -->
            <div v-if="isLoggedIn" id="mobileMenu" class="inset-x-0 p-4 md:hidden fixed top-[60px] z-30 h-screen bg-gray-200 transition duration-300 origin-top-right" :class="getMblMenuClasses">
                <div class="flex flex-col justify-center items-start w-full">
                    
                    <button :class="mblLinkClasses" style="text-underline-offset: 8px;" @click="navigateTo('home')">My Reservations</button>
                    <button :class="mblLinkClasses" style="text-underline-offset: 8px;" @click="navigateTo('equipment')">Equipment</button>
                    <button :class="mblLinkClasses" style="text-underline-offset: 8px;" @click="navigateTo('schedule')">Schedule</button>
                    <button :class="mblLinkClasses" style="text-underline-offset: 8px;" @click="navigateTo('manageprojects')">Manage Projects</button>
                    <!-- HR -->
                    <div class="w-full my-4 border-b-2 border-gray-300"></div>
                    <button :class="mblLinkClasses" style="text-underline-offset: 8px;" @click="navigateTo('manageusers')">Manage Users</button>
                    
                    <!-- HR -->
                    <div class="w-full my-4 border-b-2 border-gray-300"></div>
                    <div class=" w-full flex gap-2 items-center"  @click="navigateTo('myprofile')">
                        <div :style="`background-image:url('${ store.user.userImg ? store.user.userImg : defaultUsrImg }')`" name="userprofileimage" class="h-[32px] w-[32px] min-w-[32px] rounded-full bg-center bg-cover cursor-pointer" style="flex-basis:32px;"></div>
                        <button :class="mblLinkClasses" style="text-underline-offset: 8px;">My Profile</button>
                    </div>
                    <div class="w-full flex gap-2 items-center" @click="logout">
                        <div class="h-[32px] w-[32px] min-w-[32px]"></div>
                        <button :class="mblLinkClasses" style="text-underline-offset: 8px;" >Logout</button>
                    </div>
                    <!-- HR -->
                    <div class="w-full my-4 border-b-2 border-gray-300"></div>
                    <div class="w-full">
                        <div class="text-gray-700 text-xs text-center">{{ version }}</div>
                    </div>

                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { store } from '../store/store.js'
import { getAuth, signOut } from "firebase/auth"
import bestbuylogo from '../assets/bestbuy.svg';

export default {
    props: ['isLoggedIn'],
    data() {
        return {
            store:store,
            bestbuylogo: bestbuylogo,
            showMenu: false,
            showProfileMenu: false,
            defaultUsrImg: "https://avatars.githubusercontent.com/u/1834413?v=4",
            version:store._dev.version
        }
    },
    methods:{
        toggleMenu() {
            this.showMenu = !this.showMenu
        },
        closeMenu() {
            this.showMenu = false;
        },
        log() {
            console.log(this.getClasses)
        },
        logout() {
            this.store.user.signout().then(() => {
                    this.$router.push({name:'login'})
                }
            );
        },
        navigateTo(routeName) {
            this.closeMenu();
            this.$router.push({name:routeName});
        },
        closeProfileMenu() {
            this.showProfileMenu = false;
        },
        async exportEquipment() {
            store.equipment.exportAsCSV();
        }
    },
    computed: {
        getMblMenuClasses() {
            return (this.showMenu
            ? "scale-100" 
            : "scale-0")
        },
        mblLinkClasses() {
            return `block w-full text-black text-left cursor-pointer py-3 transition duration-300 focus:outline-none font-semibold ${ this.showMenu ? 'opacity-100' : 'opacity-0' }`
        },
        currentRouteName() {
            return this.$route.name
        },
        profileMenuClass() {
            return (this.showProfileMenu
            ? "scale-100 opacity-100" 
            : "scale-0 opacity-0")
        }
    },
    mounted() {
        window.addEventListener('click', this.closeProfileMenu)
    },
    unmounted() {
        window.removeEventListener('click', this.closeProfileMenu)
    }
}

</script>

<style scoped>
    .show {
        display: block;
    }
    .gradient-blue {
        background: linear-gradient(125deg, #0D2D7A 0%, #0046be 48%, #0D2D7A 100%);
    }
</style>