import { ref, onUnmounted } from 'vue'
import * as firebase from 'firebase/app';
import { getFirestore } from "firebase/firestore";

// Best Buy
const firebaseConfig = {
    apiKey: "AIzaSyAPK20hxR0ayzDlIoTS40ziSFlow9P-xnU",
    authDomain: "bby-asset-tracker.firebaseapp.com",
    projectId: "bby-asset-tracker",
    storageBucket: "bby-asset-tracker.appspot.com",
    messagingSenderId: "447013944858",
    appId: "1:447013944858:web:3360882ca32c0632249ba3",
    measurementId: "G-58ZGGP1HS3"
};


// Personal/Dev
// const firebaseConfig = {
//     apiKey: "AIzaSyBSCft4pdOvFxlDrlkfOA4NSnHHPOXJT0k",
//     authDomain: "bestbuy-equipment-manager.firebaseapp.com",
//     projectId: "bestbuy-equipment-manager",
//     storageBucket: "bestbuy-equipment-manager.appspot.com",
//     messagingSenderId: "200981985806",
//     appId: "1:200981985806:web:515fbd19a8d8cc33751a5f",
//     measurementId: "G-QE7CX9KC5D"
// };

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp)


export const getFirebaseApp = () => {
    return firebaseApp;
}