<template>
    <section class="max-w-screen-2xl mx-auto px-4">
      <div class="py-4 flex justify-between items-center ">
        <PageTitle :title="'Manage Projects'"></PageTitle>
        <button @click="newProject" class="hidden sm:inline-flex w-auto justify-center items-center rounded-md bg-bestyellow-800 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-bestyellow-900 transition duration-200 ease-in-out select-none">
          <font-awesome-icon class="mr-2" icon="fa-plus" />
          <span class="hidden sm:inline">Add Project</span>
        </button>
        <button @click="newProject" class="sm:hidden w-12 h-12 bg-bestyellow-800 relative rounded-full flex justify-center items-center">
          <font-awesome-icon class="text-black text-2xl absolute" icon="fa-plus" />
        </button>
      </div>
      <!-- HR -->
      <div class="border-b-2 border-gray-300"></div>
      <!-- SEARCH & FILTERS -->
      <div class="py-4 flex gap-4">
        <input type="text" v-model="searchQuery" class="form-control max-w-md block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none" placeholder="Search projects" />
      </div>
      <!-- Projects -->
      <div>
        <table class="w-full table-fixed">
          <caption class="text-left text-lg">SharePoint Projects</caption>
          <thead class="text-xs text-white uppercase bg-bestbuy-800 ">
            <tr>
              <th scope="col" class="px-2 sm:px-6 py-1 text-left font-semibold">Project #</th>
              <th scope="col" class="px-2 sm:px-6 py-1 text-left font-semibold truncate">Title</th>
              <th scope="col" class="px-2 sm:px-6 py-1 text-left font-semibold hidden sm:table-cell">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in filteredProjects" :key="project._fsid" @click="selectProject(project)" class="even:bg-gray-100 cursor-pointer hover:bg-gray-200">
              <td class="px-2 sm:px-6 py-1 ">
                <div class="flex flex-col">
                  {{ project.projectnumber }}
                  <div>
                    <span :class="project.status == 'Active' ? 'bg-green-200 text-green-800' : 'bg-orange-200 text-orange-800'" class="sm:hidden inline-block text-xxs px-3 py-[1px] rounded-full capitalize font-medium">{{ project.status }}</span>
                  </div>
                </div>
              </td>
              <td class="px-2 sm:px-6 py-1 truncate">{{ project.projecttitle }}</td>
              <td class="px-2 sm:px-6 py-1 hidden sm:table-cell">
                <div :class="project.status == 'Active' ? 'bg-green-200 text-green-800' : 'bg-orange-200 text-orange-800'" class="inline-block text-xs px-3 py-[2px] rounded-full capitalize font-medium">{{ project.status }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="filteredProjects.length == 0 && !isLoading" class="px-2 sm:px-4 italic font-light text-gray-500">No projects found.</div>
      <div v-if="isLoading" class="px-2 sm:px-4 italic font-light text-gray-500">Loading...</div>

      <!-- Project Modal -->
      <Modal v-if="showModal" :class="showModal ? 'opacity-100 visible' : 'opacity-0 invisible'" @close="closeModal">
        <template v-slot:header>
            Manage Project
        </template>
        <template v-slot:body>
          <div v-if="activeProject" class="flex flex-col gap-4">
            <div class="w-full">
              <label for="project-number" class="block font-medium text-gray-700">Project Number</label>
              <input :disabled="formMode == 'view'" v-model="activeProject.projectnumber" type="text" name="project-number" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            </div>
            <div class="w-full">
              <label for="project-title" class="block font-medium text-gray-700">Project Title</label>
              <input :disabled="formMode == 'view'" v-model="activeProject.projecttitle" type="text" name="project-title" placeholder="" class="form-control mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
            </div>
            <div class="w-full">
              <label for="project-status" class="block font-medium text-gray-700">Status</label>
              <select :disabled="formMode == 'view'" v-model="activeProject.status" class="form-control mt-1 block px-3 py-1.5 text-base font-normal text-gray-700 bg-grey-100 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none">
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div v-if="activeProject">
            <!-- <button v-if="activeProject.status != 'Inactive' && formMode == 'view'" @click="deactivateProject" type="button" class="inline-flex justify-center rounded-md border border-orange-200 bg-orange-200 text-black px-4 py-2 font-medium shadow-sm hover:bg-orange-300 focus:outline-none ml-3 w-auto text-sm transition duration-200">
              Deactivate
            </button>
            <button v-if="activeProject.status == 'Inactive' && formMode == 'view'" @click="activateProject" type="button" class="inline-flex justify-center rounded-md border border-green-700 bg-green-700 text-white px-4 py-2 font-medium shadow-sm hover:bg-green-800 focus:outline-none ml-3 w-auto text-sm transition duration-200">
              Activate
            </button> -->
            <button @click="deleteProject" v-if="formMode == 'edit' && activeProject.status == 'Inactive'" type="button" class="inline-flex justify-center rounded-md border border-red-500 bg-red-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none ml-3 w-auto text-sm transition duration-200">
              Delete
            </button>
            <button @click="editProject" v-if="formMode == 'view'" type="button" class="inline-flex justify-center rounded-md border border-bestyellow-800 bg-bestyellow-800 px-4 py-2 font-medium text-black shadow-sm hover:bg-bestyellow-900 focus:outline-none ml-3 w-auto text-sm transition duration-200">
              Edit
            </button>
            <button @click="closeModal" type="button" class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ml-3 w-auto text-sm transition duration-200">
              {{ formMode == 'view' ? 'Close' : 'Cancel'}}
            </button>
            <button :disabled="!isFormSavable" @click="saveProject" v-if="formMode != 'view'" type="button" class="inline-flex justify-center rounded-md border border-green-700 bg-green-700 text-white px-4 py-2 font-medium shadow-sm hover:bg-green-800 focus:outline-none ml-3 w-auto text-sm transition duration-200 disabled:opacity-50 disabled:bg-green-700">
              Save
            </button>

          </div>
        </template>
      </Modal>

    </section>
  </template>
  
  <script>
    import { store } from '../store/store.js'
    import PageTitle from '../components/PageTitle.vue';
    import Modal from '../components/Modal.vue';
  
    export default {
      components: {
        PageTitle,
        Modal
      },
      data() {
        return {
            isLoading:true,
            projects: [],
            showModal:false,
            formMode:"view",
            searchQuery: "",
            activeProject: null,
            isUpdating:false
        }
      },
      methods: {
        selectProject(project) {
          this.activeProject = Object.assign({},project);
          this.showModal = true;
        },
        editProject() {
          this.formMode = "edit"
        },
        async deactivateProject() {
          this.isUpdating = true;
          const res = await store.utilities.updateRecord(
            "spprojects",
            this.activeProject._fsid,
            {
              "status":"Inactive"
            }
          );
          this.activeProject.status = "Inactive";
          this.isUpdating = true;
        },
        async activateProject() {
          this.isUpdating = true;
          const res = await store.utilities.updateRecord(
            "spprojects",
            this.activeProject._fsid,
            {
              "status":"Active"
            }
          );
          this.activeProject.status = "Active";
          this.isUpdating = true;
        },
        async saveProject() {
          this.isUpdating = true;
          const obj = {
            "status": this.activeProject.status,
            "projectnumber": this.activeProject.projectnumber,
            "projecttitle": this.activeProject.projecttitle
          };

          if (this.formMode == "new") {
            // Refetch projects
            const res = await store.utilities.addRecord(
              "spprojects",
              obj
            );
            this.fetchAllProjects();
          } else {
            // Update item in place
            const res = await store.utilities.updateRecord(
              "spprojects",
              this.activeProject._fsid,
              obj
            );
            this.isUpdating = false;
            Object.assign(this.projects.find(project => project._fsid == this.activeProject._fsid),obj)
          }
          
          this.formMode = "view";
          this.closeModal();
        },
        async deleteProject() {
          this.isUpdating = true;
          const res = await store.utilities.deleteRecord(
            "spprojects",
            this.activeProject._fsid
          );
          this.projects = this.projects.filter(project => project._fsid != this.activeProject._fsid)
          this.formMode = "view";
          this.isUpdating = false;
          this.closeModal();
        },
        newProject(){
          this.formMode = "new";
          this.activeProject = {
            status:"Active",
            projectnumber:"",
            projecttitle:""
          };
          this.showModal = true;
        },
        closeModal() {
          this.showModal = false;
          this.formMode = 'view'
          this.activeProject = null;
        },
        fetchAllProjects() {
          // Fetch all projects
          store.projects.getProjects(true,true).then(results => {
              console.log(results)
              this.projects = results.map(project => {
                project["status"] = project["status"] ? project["status"] : "Active"
                return project;
              });
              this.isLoading = false;
              this.isUpdating = false;
            })
        },
        // async updateAllProjects() {
        //   for (const project of this.projects) {
        //     const obj = {
        //       "status": "Active",
        //     };
        //     await store.utilities.updateRecord(
        //       "spprojects",
        //       project._fsid,
        //       obj
        //     );
        //     console.log(project._fsid,obj)
        //   }
        // }
      },
      computed: {
        filteredProjects() {
          // Filter projects based on search
          const search = this.searchQuery ? this.searchQuery.toLowerCase() : '';
          return this.projects.filter(function (project) {
            return search == '' ||
              (project.projecttitle && project.projecttitle.toLowerCase().includes(search)) || 
              (project.projectnumber && project.projectnumber.toLowerCase().includes(search))
          })
        },
        isFormSavable() {
          return (this.activeProject && this.activeProject.status && this.activeProject.projectnumber && this.activeProject.projecttitle)
        }
      },
      mounted() {
        this.isLoading = true;

        // Log Event
        if (store.user.user) {
          store.analytics.logAppEvent({
            uid: store.user.user.uid,
            name: store.user.user.displayName,
            email: store.user.user.email,
            action: "View",
            type: "Manage Projects",
            target: "Manage Projects",
            url: window.location.href,
            datetime: new Date()
          });
        }

        // Fetch all projects
        store.projects.getProjects(true,true).then(results => {
          console.log(results)
          this.projects = results.map(project => {
            project["status"] = project["status"] ? project["status"] : "Active"
            return project;
          });
          this.isLoading = false;
        })
  
      },
      unmounted() {
        
      }
    }
  </script>